import { React, useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faUsers } from '@fortawesome/free-solid-svg-icons';
import swal from "sweetalert";
import translations from "../../components/AppsMenu/Candidate/mulitlingue"
import { Link } from "react-router-dom";
import Editable from './Editable';
import { Button, Card, Row, Col, Modal, Form, FormGroup,Badge} from 'react-bootstrap';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { signUp } from '../../../services/AuthService';
import { BsCalendarDate } from "react-icons/bs";
import axios from 'axios';
import { LiaMoneyCheckAltSolid } from "react-icons/lia";

const Company = () => {
	const [adminDash, setAdminDash] = useState([]);
	const token=localStorage.getItem("token");
	const [userConnect, setUserConnect] = useState(null);

	useEffect(() => {
	  const fetchUserPermissions = async () => {
		try {
		  const currentUser = JSON.parse(localStorage.getItem("userDetails"));
		  setUserConnect(currentUser)
		  if (currentUser && currentUser.localId) {
			const userId = currentUser.localId;
			const response = await fetch(
			  `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
			  {
				headers: {
				  Authorization: `Bearer ${token}`,
				},
			  }
			);
			const data = await response.json();
			setAdminDash(
			  data.some(
				(permission) =>
				  permission.title === "view-company-dashoard"
			  )
			);
		  }
		} catch (error) {
		  console.error("Error fetching permissions details:", error);
		}
	  };
  
	  fetchUserPermissions();
	}, []);
	const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 
  
	useEffect(() => {
	  if (!adminDash) {
		setShowAccessDeniedModal(true); 
	  }
	}, [adminDash]);
	const [lang] = useState(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
			const parsedLang = JSON.parse(storedLang);
			if (parsedLang.language === 'Français') {
				return translations.fr;
			}
		}
		return translations.en;
	});
	const [subscription, setSubscription] = useState([]);
	const [paymentControl, setPaymentControl] = useState(null);
	const [userData, setUserData] = useState(null);
	const [personnalityAIRecruiter, setPersonnalityAIRecruiter] = useState(null);
	const [personnalityAICompany, setPersonnalityAICompany] = useState(null);

	useEffect(() => {
	  const fetchSubscription = async () => {
		try {
		  const currentUser = JSON.parse(localStorage.getItem("userDetails"));
		  const userId = currentUser.localId;
		  const userResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
		  if (!userResponse.ok) {
			throw new Error(`Error fetching user details: ${userResponse.status}`);
		  }
		  const userData = await userResponse.json();
		  console.log("setUserData:",userData[0])
		  setUserData(userData[0])
		  if(currentUser.userRole===3 || currentUser.userRole===2){
			const responseSub = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/Subscription/getInvoiceByUserId/${userId}`, 
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  },
				});
			  setSubscription(responseSub.data);
		  }
		
		} catch (error) {
		  console.error("Error fetching subscription data:", error);
		}
	  };
	
	  fetchSubscription();
	}, []);
	useEffect(() => {
		if (userConnect && userConnect.localId) {
			const fetchPaymentControl = async () => {
				try {
					const responseSub = await axios.get(
						`${process.env.REACT_APP_APP_DOMAIN}/api/users/get-payment-control/${userConnect.localId}`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					);
					setPaymentControl(responseSub.data.PaymentControl); 
					console.log('Subscription data:', responseSub.data);
				} catch (error) {
					console.error('Error fetching subscription data:', error);
				}
			};
			const fetchPersonnalityAIComp = async () => {
				try {
					const role=2
					const responseSub = await axios.get(
						`${process.env.REACT_APP_APP_DOMAIN}/api/users/get-PersonnalityAI/${role}`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					);
					setPersonnalityAICompany(responseSub.data.PersonnalityAI)
					//setPaymentControl(responseSub.data.PaymentControl); 
					console.log('get ai:', responseSub.data);
				} catch (error) {
					console.error('Error fetching PersonnalityAI comp data:', error);
				}
			};
			const fetchPersonnalityAIRec = async () => {
				try {
					const role=3
					const responseSub = await axios.get(
						`${process.env.REACT_APP_APP_DOMAIN}/api/users/get-PersonnalityAI/${role}`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					);
					setPersonnalityAIRecruiter(responseSub.data.PersonnalityAI)
					//setPaymentControl(responseSub.data.PaymentControl); 
					console.log('get ai:', responseSub.data);
				} catch (error) {
					console.error('Error fetching PersonnalityAI comp data:', error);
				}
			};
			fetchPaymentControl();
			fetchPersonnalityAIComp()
			fetchPersonnalityAIRec()
		}
	}, [userConnect]); // Only run when userConnect changes
	
	const TableSub = ({ sub }) => {
		const calculatePercentage = (used, max) => {
		  if (max === 0) return 0; 
		  return (used / max) * 100;
		};
	  
		const getColor = (percentage) => {
			console.log(percentage)
		  if (percentage >= 100) {
			return 'red';
		  } else if (percentage >= 80) {
			return 'orange';
		  } else if (percentage >= 50) {
			return 'yellow';
		  } else {
			return 'green';
		  }
		};
	  
		const quotas = [
		  { name: 'Quota Extraction', max: sub.extractionNumber, used: company.quotaExtraction },
		  { name: 'Quota Job Role', max: sub.quotaJobRole, used: company.quotaJobRole },
		  { name: 'Quota Job Profile', max: sub.quotaJobprofile, used: company.quotaJobProfile },
		  { name: 'Quota Parcours job Test', max: sub.quotaParcours, used: company.quotaParcours },
		  { name: 'Quota Profiles', max: sub.user_profiles, used: company.quotaProfiles },
		];
	  
		return (
		  <>
			<div className="card-header pb-0 border-0 flex-wrap">
			  <h4 className="fs-20 mb-3">{lang.menu.subDetails}</h4>
			  <div className="d-flex">
				<Badge variant="success"><LiaMoneyCheckAltSolid size={25} />{" "}
				  {sub.status}
				</Badge>
			  </div>
			</div>
			<div className="card-body">
			  <Row>
				<Col>
				  <strong><BsCalendarDate size={20} /> {lang.menu.closeDate}: </strong>
				  <span>{new Date(sub.period_end).toLocaleDateString()}</span>
				</Col>
			  </Row>
			  <table className="table display mb-3 dataTablesCard job-table table-responsive-s card-table dataTable no-footer">
				<thead>
				  <tr>
					<th></th>
					<th>{lang.menu.quotaMax}</th>
					<th>{lang.menu.quotaUsed}</th>
					<th>{lang.menu.usagePercentage}</th>
				  </tr>
				</thead>
				<tbody>
				  {quotas.map((quota, index) => {
					const percentage = calculatePercentage(quota.used, quota.max);
					const color = getColor(Math.round(percentage));
					return (
					  <tr key={index}>
						<td>{quota.name}</td>
						<td>{quota.max}</td>
						<td>{quota.used}</td>
						<td style={{ color: color }}>
						  {Math.round(percentage)}%
						</td>
					  </tr>
					);
				  })}
				</tbody>
			  </table>
			</div>
		  </>
		);
	  };
	
	const [editFormData, setEditFormData] = useState({
		userId: "",
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		country: "",
		jobTitle: "",
		phoneNumber: "",
		language: "",
		avatar: "",
		cover: "",
		signature: "",
		userRole: "",
		disabled: "",
		company: "",
		emailVerified: "",
		lastSignInTime: "",
		creationTime: "",
	})
	const fieldsToCheck = {
		'All': lang.menu.All,
		'FirstName': lang.menu.FirstName,
		'LastName': lang.menu.LastName,
		'email': lang.menu.Email,
		'disabled': lang.menu.Disabled,
		'companyName': lang.menu.Company,
		'userRole': lang.menu.Role,
		'lastSignInTime': lang.menu.lastSignInTime,
		'creationTime': lang.menu.creationTime,
	};
	const avatarClasses = ['empty-avatar-red', 'empty-avatar-green', 'empty-avatar-pimary', 'empty-avatar-yellow', 'empty-avatar-purple'];
	const [selectedFilterField, setSelectedFilterField] = useState('All');
	const [filterText, setFilterText] = useState("");
	const [addCard, setAddCard] = useState(false);

	const [company, setcompany] = useState({
		companyid: "",
		companyName: "",
		founderID: "",
		founder: "",
		numberOfUsers: "",
		comments: "",
		DurationQuestionQCM:"",
		DurationCoding:"",
		quotaExtraction:"",
		quotaJobRole:"",
		quotaJobProfile:"",
		quotaParcours:"",
		quotaProfiles:""
	});

	const [user, setUser] = useState({
		uid: "",
		company: "",
		firstname: "",
		lastname: "",
		email: "",
		password: "",
		role: ""
	});

	const [users, setUsers] = useState([]);
	const [editContentId, setEditContentId] = useState(null);
	const [userRoles, setuserRoles] = useState([]);
	const [errors, setErrors] = useState({ firstname: '', lastname: '', email: '', role: '', password: '' });
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		const currentUser = JSON.parse(localStorage.getItem('userDetails'));

		if (currentUser && currentUser.localId) {
			const userId = currentUser.localId;
			fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/company/users/${userId}`, 
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  },
				})
				.then(response => response.json())
				.then(data => {
					const convertedData = convertNumericFields(data);
					setUsers(convertedData);
				})
				.catch(error => {
					console.error('Error fetching user data:', error);
				});

			fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/local/${userId}`, 
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  },
				})
				.then((response) => response.json())
				.then((data) => {
					setcompany({
						companyid: data[0].companyid || "",
						companyName: data[0].companyName || "",
						founderID: userId,
						founder: data[0].founder || "",
						numberOfUsers: data[0].numberOfUsers || 0,
						comments: data[0].comments || "",
						DurationQuestionQCM: data[0].DurationQuestionQCM || "",
						DurationCoding: data[0].DurationCoding || "",
						quotaExtraction: data[0].quotaExtraction || 0,
						quotaJobRole: data[0].quotaJobRole || 0,
						quotaJobProfile: data[0].quotaJobProfile || 0,
						quotaParcours: data[0].quotaParcours || 0,
						quotaProfiles: data[0].quotaProfiles || 1

					});

					fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/roles/roles/${data[0].companyid}`, 
						{
						  headers: {
							Authorization: `Bearer ${token}`,
						  },
						})
						.then(response => response.json())
						.then(data => {
							setuserRoles(data);
						})
						.catch(error => {
							console.error('Error fetching user data:', error);
						});

				})
				.catch((error) => {
					console.error("Error fetching company details:", error);
				});
		}
	}, []);

	function convertNumericFields(users) {
		const convertedUsers = users.map((user) => {
			const newUser = { ...user };

			// Convert emailVerified
			if (newUser.emailVerified === 0) {
				newUser.emailVerified = 'False';
			} else if (newUser.emailVerified === 1) {
				newUser.emailVerified = 'True';
			}

			// Convert disabled
			if (newUser.disabled === 0) {
				newUser.disabled = 'False';
			} else if (newUser.disabled === 1) {
				newUser.disabled = 'True';
			}

			return newUser;
		});

		return convertedUsers;
	}

	const handleEditFormChange = async (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute('name');
		const fieldValue = event.target.value;
		const newFormData = { ...editFormData };
		newFormData[fieldName] = fieldValue;
		setEditFormData(newFormData);
	};

	const handleCancelClick = () => {
		setEditContentId(null);
	};

	const handleSubmitClick = async () => {
		try {
			// Send user data to backend for saving
			const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/updatefirebase`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(editFormData),
			});
			console.log("response ;",response)

			if (response.ok) {
				swal("", lang.menu.ProfileUpdate, "success").then(() => {
					window.location.reload();
				});
			}
			else if (response.status===400) {
				swal("Oops",lang.menu.emailExists, "error");
	}
			else {
				swal("Oops", lang.menu.ProfileUpdateError, "error");
			}
		} catch (error) {
			console.log("error ;",error)
			console.error("An error occurred:", error);
		}
	};

	const filteredUsers = users.filter((user) => {
		if (
			selectedFilterField === 'creationTime' ||
			selectedFilterField === 'lastSignInTime'
		) {
			const fieldValue = JSON.parse(user.metadata)[selectedFilterField];
			if (
				fieldValue !== null &&
				fieldValue !== undefined &&
				fieldValue.toLowerCase().includes(filterText.toLowerCase())
			) {
				return true;
			}
			return false;
		}
		else if (selectedFilterField !== 'All') {
			const fieldValue = user[selectedFilterField];
			if (fieldValue !== null && fieldValue !== undefined) {
				if (typeof fieldValue === 'number') {
					if (fieldValue.toString().includes(filterText)) {
						return true;
					}
				} else if (
					typeof fieldValue === 'string' &&
					fieldValue.toLowerCase().includes(filterText.toLowerCase())
				) {
					return true;
				} else if (typeof fieldValue === 'boolean') {
					if (fieldValue.toString() === filterText) {
						return true;
					}
				}
			}
			return false;
		} else {
			const fieldsToCheck = {
				'FirstName': lang.menu.FirstName,
				'LastName': lang.menu.LastName,
				'email': lang.menu.Email,
			
				'disabled': lang.menu.Disabled,
				'companyName': lang.menu.Company,
				'userRole': lang.menu.Role,
				'lastSignInTime': lang.menu.lastSignInTime,
				'creationTime': lang.menu.creationTime,
			};

			for (const field in fieldsToCheck) {
				let fieldValue = user[field];
				if (field === 'lastSignInTime' || field === 'creationTime') {
					fieldValue = JSON.parse(user.metadata)[field];
				}

				if (fieldValue !== null && fieldValue !== undefined) {
					if (typeof fieldValue === 'number') {
						if (fieldValue.toString().includes(filterText)) {
							return true;
						}
					} else if (
						typeof fieldValue === 'string' &&
						fieldValue.toLowerCase().includes(filterText.toLowerCase())
					) {
						return true;
					} else if (typeof fieldValue === 'boolean') {
						if (fieldValue.toString() === filterText) {
							return true;
						}
					}
				}
			}

			return false;
		}
	});

	const handleEditClick = (event, content) => {
		event.preventDefault();
		setEditContentId(content.uid);
		const formValues = {
			userId: content.uid,
			firstName: content.FirstName,
			lastName: content.LastName,
			email: content.email,
			password: content.Password,
			country: content.Country,
			jobTitle: content.JobTitleID,
			phoneNumber: content.PhoneNumber,
			language: content.Language,
			avatar: content.Avatar,
			cover: content.Cover,
			signature: content.EmailSignature,
			userRole: content.userRoleID ? content.userRoleID : 4,
			disabled: content.disabled === "True" ? 1 : 0,
			company: content.companyName,
			emailVerified: content.emailVerified,
			lastSignInTime: content.metadata ? JSON.parse(content.metadata).lastSignInTime : '',
			creationTime: content.metadata ? JSON.parse(content.metadata).creationTime : ''
		}
		setEditFormData(formValues);
	};

	const handleDeleteUser = async (event, content) => {
		event.preventDefault();
	  
		const userId = content.uid;
	  
		fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/deleteFromCompany/${userId}`, 
		  {
			method: 'DELETE',  
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  })
		  .then(response => response.json())
		  .then(data => {
			swal("", lang.menu.DeleteUserSuccess, "success").then(async() => {
				if(company.companyid!=1){
					await axios.put(
						`${process.env.REACT_APP_APP_DOMAIN}/api/companies/updateCompanyField/${company.companyid}`,
						{ fieldName:"quotaProfiles","incrementValue":-1  ,quota_max:subscription.user_profiles,quota_used:company.quotaProfiles}, 
						{
						  headers: {
							Authorization: `Bearer ${token}`,
						  },
						}
					  );
				}
				
			  const convertedData = convertNumericFields(data);
			  setUsers(convertedData);
			  window.location.reload();

			})
		  })
		  .catch(error => {
			console.error('Error deleting user:', error);
			swal("", lang.menu.DeleteUserError, "error");
		  });
	  }
	  

	const handleSave = async () => {
		// Send company data to backend for saving:
		const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/local/savecompanyDetails`, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(company),
		});

		if (response.status === 500) {
			console.error("Server Error: Internal Server Error (500)");
			swal("Oops", lang.menu.CompanyExists, "error");
		} else {
			swal("", lang.menu.CompanyUpdate, "success")
		};
	};

	const handleAddFormSubmit = async () => {
		signUp(user.email, user.password)
			.then((response) => {
				user.uid = response.data.localId;
				user.company = company.companyid;
				fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/saveUserToCompany`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify(user),
				}).then(() => {
					swal("", lang.menu.userAddSucc, "success").then(async() => {
						await axios.put(
							`${process.env.REACT_APP_APP_DOMAIN}/api/companies/updateCompanyField/${company.companyid}`,
							{ fieldName:"quotaProfiles","incrementValue":1  ,quota_max:subscription.user_profiles,quota_used:company.quotaProfiles}, 
							{
							  headers: {
								Authorization: `Bearer ${token}`,
							  },
							}
						  );
						window.location.reload();
					});
				})
			}).catch((error) => {
				if (error.response.status === 400)
					swal("Oops", lang.menu.emailExists, "error");
				else
					swal("Oops", lang.menu.ProfileUpdateError, "error");
			});

	};

	const handleAddUser = () => {
		const newUser = {
			firstname: "",
			lastname: "",
			email: "",
			password: "",
			role: "",
		};
		setUser(newUser);
		setAddCard(true)
	}


	function onSignUp(e) {
		e.preventDefault();
		let error = false;
		const errorObj = { ...errors };
		if (user.firstname === '') {
			errorObj.firstname = lang.menu.firstNameRequired;
			error = true;
		}
		else
			errorObj.firstname = ''

		if (user.lastname === '') {
			errorObj.lastname = lang.menu.lastNameRequired;
			error = true;
		}
		else
			errorObj.lastname = ''

		if (user.email === '') {
			errorObj.email = lang.menu.emailRequired;
			error = true;
		} else if (!validateEmail(user.email)) {
			errorObj.email = lang.menu.InvalidEmailFormat;
			error = true;
		}
		else
			errorObj.email = ''

		if (user.role === '') {
			errorObj.role = lang.menu.RoleRequired;
			error = true;
		}
		else
			errorObj.role = ''

		if (user.password === '') {
			errorObj.password = lang.menu.PasswordRequired;
			error = true;
		} else if (!validatePassword(user.password)) {
			errorObj.password = lang.menu.passWordComplexityReq;
			error = true;
		} else {
			errorObj.password = '';
		}
		setErrors(errorObj);
		if (error) return;
		handleAddFormSubmit()
	}

	const togglePasswordVisibility = () => {
		setShowPassword((prevState) => !prevState);
	};

	const validateEmail = (email) => {
		// Regular expression to validate email format
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const validatePassword = (password) => {
		// Regular expressions for password complexity requirements
		const uppercaseRegex = /[A-Z]/;
		const lowercaseRegex = /[a-z]/;
		const digitRegex = /[0-9]/;
		const specialCharRegex = /[^a-zA-Z0-9]/;

		return (
			password.length >= 8 &&
			uppercaseRegex.test(password) &&
			lowercaseRegex.test(password) &&
			digitRegex.test(password) &&
			specialCharRegex.test(password)
		);
	};

	const getPasswordStrengthColor = () => {
		let complexity = 0;

		if (user.password.length >= 8) {
			complexity++;
		}

		if (/[A-Z]/.test(user.password)) {
			complexity++;
		}

		if (/[a-z]/.test(user.password)) {
			complexity++;
		}

		if (/\d/.test(user.password)) {
			complexity++;
		}

		if (/[^a-zA-Z0-9]/.test(user.password)) {
			complexity++;
		}

		if (complexity === 5) {
			return 'bg-success';
		} else if (complexity >= 3) {
			return 'bg-warning';
		} else {
			return 'bg-danger';
		}
	};

	const getPasswordHintIcon = (condition) => {
		return condition ? <span className="hint-icon">✓</span> : <span className="hint-icon">X</span>;

	};




	const handleChangeControlPayment = async (enable) => {
		const token = localStorage.getItem("token");
		const currentUser = JSON.parse(localStorage.getItem("userDetails"));
	
		const parcour = {
			uid: userConnect.localId,
			enable: enable ? 1 : 0 		};
	console.log("parcour :",parcour)
		try {
			const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/update-payment-control`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(parcour),
			});
	
			const data = await response.json();
	
			if (response.ok) {
				console.log("PaymentControl updated successfully:", data.message);
				
				setPaymentControl(enable);
			} else {
				console.error("Error updating PaymentControl:", data.message);
			
			}
		} catch (error) {
			console.error("An error occurred while updating PaymentControl:", error);
		}
	};
	



	const handleChangPersonnalityAI = async (enable,userRole) => {
		const token = localStorage.getItem("token");
	
		const persoAI = {
			uid: userConnect.localId,
			enable: enable ? 1 : 0 	,
			role:userRole	};
	console.log("persoAI :",persoAI)
		try {
			const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/update-PersonnalityAI`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(persoAI),
			});
	
			const data = await response.json();
	
			if (response.ok) {
				console.log("PersonnalityAI updated successfully:", data.message);
				if(userRole===2){
					setPersonnalityAICompany(enable);

				}else if(userRole===3){
					setPersonnalityAIRecruiter(enable);

				}
			} else {
				console.error("Error updating PersonnalityAI:", data.message);
			
			}
		} catch (error) {
			console.error("An error occurred while updating PersonnalityAI:", error);
		}
	};




	return (
<> {adminDash ? (  

		<>
			<Modal className="modal fade" show={addCard} onHide={setAddCard} >
				<div className="" role="document">
					<div className="">
						<form onSubmit={onSignUp}>
							<div className="modal-header">
								<h4 className="modal-title fs-20">{lang.menu.AddUser}</h4>
								<button type="button" className="btn-close" onClick={() => setAddCard(false)} data-dismiss="modal"><span></span></button>
							</div>
							<div className="modal-body">
								<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
								<div className="add-contact-box">
									<div className="add-contact-content">
										<div className="row">
											<div className="col-6">
												<div className="form-group mb-3">
													<label className="mb-1">
														<strong>{lang.menu.firstName}</strong>
													</label>
													<input
														value={user.firstname}
														onChange={(e) =>
															setUser((prevUser) => ({
																...prevUser,
																firstname: e.target.value,
															}))
														}
														className="form-control"
														placeholder="Firstname"
													/>
													{errors.firstname && <div className='text-danger'>{errors.firstname}</div>}
												</div>
											</div>
											<div className="col-6">
												<div className="form-group mb-3">
													<label className="mb-1">
														<strong>{lang.menu.lastName}</strong>
													</label>
													<input
														value={user.lastname}
														onChange={(e) =>
															setUser((prevUser) => ({
																...prevUser,
																lastname: e.target.value,
															}))
														}
														className="form-control"
														placeholder="Lastname"
													/>
													{errors.lastname && <div className='text-danger'>{errors.lastname}</div>}
												</div>
											</div>
										</div>
										<div className="form-group mb-3">
											<label className="mb-1">
												<strong>{lang.menu.email}</strong>
											</label>
											<input
												value={user.email}
												onChange={(e) =>
													setUser((prevUser) => ({
														...prevUser,
														email: e.target.value,
													}))
												}
												className="form-control"
												placeholder="hello@example.com"
											/>
											{errors.email && <div className='text-danger'>{errors.email}</div>}
										</div>
										<div className="form-group mb-3">
											<label className="mb-1">
												<strong>{lang.menu.role}</strong>
											</label>
											<select
												name="role"
												value={user.role}
												className="form-control"
												onChange={(e) =>
													setUser((prevUser) => ({
														...prevUser,
														role: e.target.value,
													}))
												}
											>
												<option value="" disabled></option>
												{userRoles.map((role) => (
													<option key={role.roleid} value={role.roleid}>
														{role.roleName}
													</option>
												))}
											</select>
											{errors.role && <div className='text-danger'>{errors.role}</div>}
										</div>
										<div className="form-group mb-3">
											<label className="mb-1">
												<strong>{lang.menu.password}</strong>
											</label>
											<div className="password-input-container">
												<input
													value={user.password}
													onChange={(e) => {
														setUser((prevUser) => ({
															...prevUser,
															password: e.target.value,
														}))
														validatePassword(e.target.value);
													}}
													className="form-control"
													type={showPassword ? "text" : "password"}
													placeholder="Password"
												/>
												<span className="password-toggle-icon" onClick={togglePasswordVisibility}>
													{showPassword ? <FiEyeOff /> : <FiEye />}
												</span>
											</div>
											{errors.password && <div className='text-danger'>{errors.password}</div>}
										</div>
										<div className="progress mb-3">
											<div
												className={`progress-bar ${getPasswordStrengthColor()}`}
												role="progressbar"
												style={{ width: `${(user.password.length / 16) * 100}%` }}
												aria-valuenow={user.password.length}
												aria-valuemin="0"
												aria-valuemax="10"
											></div>
										</div>
										<div className={`password-hint ${Object.values(errors).some(error => error !== '') ? 'hint-error' : ''}`}>
											<ul>
												<li className={!user.password ? 'hint-not-typed' : (user.password.length >= 8 ? 'hint-typed' : '')}>
													{getPasswordHintIcon(user.password.length >= 8)} {lang.menu.isPasswordLengthValid}
												</li>
												<li className={!user.password ? 'hint-not-typed' : (/[A-Z]/.test(user.password) ? 'hint-typed' : '')}>
													{getPasswordHintIcon(/[A-Z]/.test(user.password))} {lang.menu.isPasswordContainsUppercase}
												</li>
												<li className={!user.password ? 'hint-not-typed' : (/[a-z]/.test(user.password) ? 'hint-typed' : '')}>
													{getPasswordHintIcon(/[a-z]/.test(user.password))}{lang.menu.isPasswordContainsLowercase}
												</li>
												<li className={!user.password ? 'hint-not-typed' : (/\d/.test(user.password) ? 'hint-typed' : '')}>
													{getPasswordHintIcon(/\d/.test(user.password))} {lang.menu.isPasswordContainsDigit}
												</li>
												<li className={!user.password ? 'hint-not-typed' : (/[^a-zA-Z0-9]/.test(user.password) ? 'hint-typed' : '')}>
													{getPasswordHintIcon(/[^a-zA-Z0-9]/.test(user.password))} {lang.menu.isPasswordContainsSpecialChar}
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="submit" className="btn btn-primary">{lang.menu.Add}</button>
								<button type="button" onClick={() => setAddCard(false)} className="btn btn-danger">
									<i className="flaticon-delete-1"></i> {lang.menu.Discard}</button>
							</div>
						</form>
					</div>
				</div>
			</Modal>
			<div className="col-12">
				<div className="card full-width">
					<div className="card-header">
						<h4 className="card-title"><FontAwesomeIcon icon={faBuilding} className="me-2" />{lang.menu.Company}</h4>
					</div>
					<div className="card-body">
						<div className="basic-form">
							<form onSubmit={(e) => e.preventDefault()} className="p-3">
								<div className="row">
									<div className="form-group mb-3 col-md-6 my-form-padding">
										<label className="my-label" >{lang.menu.companyName}</label>
										<input
											id="companyName"
											type="text"
											className="form-control"
											value={company.companyName}
											onChange={(e) =>
												setcompany((prevcompany) => ({
													...prevcompany,
													companyName: e.target.value,
												}))
											}
										/>
									</div>
									<div className="form-group mb-3 col-md-6 my-form-padding">
										<label className="my-label" >{lang.menu.founder}</label>
										<input
											disabled
											id="founder"
											className="form-control"
											value={company.founder}
											onChange={(e) =>
												setcompany((prevcompany) => ({
													...prevcompany,
													founder: e.target.value,
												}))
											}
										/>
									</div>
									<div className="form-group mb-3 col-md-6 my-form-padding">
										<label className="my-label" >{lang.menu.numberOfUsers}</label>
										<input
											type="number"
											id="numberOfusers"
											min={0}
											disabled
											className="form-control"
											value={company.numberOfUsers}
											onChange={(e) =>
												setcompany((prevcompany) => ({
													...prevcompany,
													numberOfUsers: e.target.value,
												}))
											}
										/>
									</div>
									<div className="form-group mb-3 col-md-6 my-form-padding"></div>
									<div className="form-group mb-3 col-md-12 my-form-padding">
										<label className="my-label">{lang.menu.Comments}</label>
										<textarea
											id="comments"
											style={{ height: "150px" }}
											className="form-control"
											value={company.comments}
											onChange={(e) =>
												setcompany((prevcompany) => ({
													...prevcompany,
													comments: e.target.value,
												}))
											} />
									</div>
									<div className="form-group mb-3 col-md-6 my-form-padding">
										<label className="my-label" >{lang.menu.QCMduration}</label>
										<input
											id="DurationQuestionQCM"
											type="number"
											className="form-control"
											value={company.DurationQuestionQCM}
											onChange={(e) =>
												setcompany((prevcompany) => ({
													...prevcompany,
													DurationQuestionQCM: e.target.value,
												}))
											}
										/>
									</div>
								
									<div className="form-group mb-3 col-md-6 my-form-padding">
										<label className="my-label" >{lang.menu.codingDuration}</label>
										<input
											id="DurationCoding"
											type="number"
											className="form-control"
											value={company.DurationCoding}
											onChange={(e) =>
												setcompany((prevcompany) => ({
													...prevcompany,
													DurationCoding: e.target.value,
												}))
											}
										/>
									</div>
								</div>
							</form>
						</div>
						<div className="form-group mb-3 col-md-12 my-button-padding">
							<button type="submit" className="btn btn-primary my-save-button" onClick={handleSave}>
								{lang.menu.SaveChanges}
							</button>
						</div>
					</div>
				</div>
				<div className="card">

				{subscription && company&&company.companyid!=1  ? (
							<>

							<TableSub sub={subscription} />
							</>
									
									) : (
								<></>
								)}</div>
								<div className="card">

{ userConnect&&userConnect.userRole===1 ? (
		  <>
          <div className="card-header pb-0 border-0 flex-wrap">
            <h4 className="fs-20 mb-3">{lang.menu.controlTitle}</h4>
          </div>
          <div className="card-body">
            <Row>
			<Col>
    <input
        type="radio"
        id="validationAdmin"
        name="paymentControl"
        value="1"
        checked={paymentControl === true} // If true, "Validation par administrateur" is checked
        onChange={() => handleChangeControlPayment(true)} // Update state and backend
    />
    <label htmlFor="validationAdmin">{lang.menu.controlAdmin}</label>
</Col>
<Col>
    <input
        type="radio"
        id="paymentCard"
        name="paymentControl"
        value="0"
        checked={paymentControl === false} // If false, "Paiement par carte" is checked
        onChange={() => handleChangeControlPayment(false)} // Update state and backend
    />
    <label htmlFor="paymentCard">{lang.menu.controlCard}</label>
</Col>

            </Row>
          </div>


	


        </>
					
					) : (
				<></>
				)}
				
				</div>
				
				
				
				
				
				
				
				
				{ userConnect&&userConnect.userRole===1 ? (
		  <Card className="mb-4">
		  <Card.Header className="pb-0 border-0">
			<h4 className="fs-20 mb-3">{lang.menu.aipersoSeetings}</h4>
		  </Card.Header>
		  <Card.Body>
			{/* Company Section */}
			<section className="mb-4">
			  <h5 className="fs-18">{lang.menu.accesCompTitle}</h5>
			  <p className="text-muted">{lang.menu.accesCompDesc}</p>
			  <Form>
				<FormGroup as={Row} className="align-items-center">
				  <Col xs="auto">
					<Form.Check
					  type="radio"
					  id="PersonnalityAIyes"
					  name="PersonnalityAICompany"
					  label={lang.menu.Yes}
					  value="1"
					  checked={personnalityAICompany === true}
					  onChange={() => handleChangPersonnalityAI(true, 2)}
					/>
				  </Col>
				  <Col xs="auto">
					<Form.Check
					  type="radio"
					  id="PersonnalityAINo"
					  name="PersonnalityAICompany"
					  label={lang.menu.No}
					  value="0"
					  checked={personnalityAICompany === false}
					  onChange={() => handleChangPersonnalityAI(false, 2)}
					/>
				  </Col>
				</FormGroup>
			  </Form>
			</section>
	
			{/* Recruiter Section */}
			<section>
			  <h5 className="fs-18">{lang.menu.accesRecTitle}</h5>
			  <p className="text-muted">{lang.menu.accesRecDesc}</p>
			  <Form>
				<FormGroup as={Row} className="align-items-center">
				  <Col xs="auto">
					<Form.Check
					  type="radio"
					  id="PersonnalityAIYes2"
					  name="PersonnalityAIRecruiter"
					  label={lang.menu.Yes}
					  value="1"
					  checked={personnalityAIRecruiter === true}
					  onChange={() => handleChangPersonnalityAI(true, 3)}
					/>
				  </Col>
				  <Col xs="auto">
					<Form.Check
					  type="radio"
					  id="PersonnalityAINo2"
					  name="PersonnalityAIRecruiter"
					  label={lang.menu.No}
					  value="0"
					  checked={personnalityAIRecruiter === false}
					  onChange={() => handleChangPersonnalityAI(false, 3)}
					/>
				  </Col>
				</FormGroup>
			  </Form>
			</section>
		  </Card.Body>
		</Card>
					
					) : (
				<></>
				)}
				
				
				
				
				
				
				
				
				
				
				<div className="card full-width">
					<div className="card-header">
						<h4 className="card-title"><FontAwesomeIcon icon={faUsers} className="me-2" />{lang.menu.Users}</h4>
					</div>
					<div className="card-body">
						<div className="w-100 table-responsive">
							<div id="example_wrapper" className="dataTables_wrapper">
								<div className="d-flex align-items-center">
									<label><h4 className="me-2 mt-3">{lang.menu.SearchT}</h4></label>
									<input
										className="input-search form-control"
										value={filterText || ''}
										onChange={(e) => setFilterText(e.target.value)}
										style={{ width: '20%' }}
									/>
									<select
										className="ml-5 form-select input-search form-control"
										value={selectedFilterField}
										onChange={(e) => setSelectedFilterField(e.target.value)}
										style={{ width: '10%', marginLeft: '10px' }}
									>
										{Object.keys(fieldsToCheck).map((key) => (
											<option key={key} value={key}>
												{fieldsToCheck[key]}
											</option>
										))}
									</select>
									{company.numberOfUsers > filteredUsers.length ? (
										<button
											className="btn btn-primary shadow btn-xs sharp ms-auto"
											onClick={() => handleAddUser()}
										>
											<i className="fa fa-plus"></i>
										</button>
									) : <></>}
								</div>


								<form>
									<table id="example" className="display w-100 dataTable">
										<thead>
											<tr>
												<th>#</th>
												<th>{lang.menu.firstName}</th>
												<th>{lang.menu.lastName}</th>
												<th>{lang.menu.email}</th>
												{/*<th>{lang.menu.EmailVerification}</th>
															<th>{lang.menu.EmailSignature}</th>
															<th>{lang.menu.Password}</th>
															<th>{lang.menu.Country}</th>
															<th>{lang.menu.MyJobtitle}</th>
															<th>{lang.menu.PhoneNumber}</th>
															<th>{lang.menu.language}</th>*/}
												<th>{lang.menu.Disabled}</th>
												<th>{lang.menu.company}</th>
												<th>{lang.menu.role}</th>
												<th>{lang.menu.lastSignInTime}</th>
												<th>{lang.menu.creationTime}</th>
											</tr>
										</thead>
										<tbody>
											{filteredUsers.map((content, index) => (
												<tr key={index}>
													{editContentId === content.uid ?
														(
															<Editable editFormData={editFormData} userRoles={userRoles}
																handleEditFormChange={handleEditFormChange}
																handleSubmitClick={handleSubmitClick}
																handleCancelClick={handleCancelClick} />
														) :
														(
															<>
																<td>
																	{
																		content.Avatar ? (
																			<img className="rounded-circle" width="43" src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/avatars/${content.Avatar}`} alt="" />
																		) :
																			(
																				<div className={`empty-avatar rounded-circle ${['empty-avatar-red', 'empty-avatar-green', 'empty-avatar-pimary', 'empty-avatar-yellow', 'empty-avatar-purple'][Math.floor(Math.random() * avatarClasses.length)]}`}>{content.FirstName.charAt(0)}{content.LastName.charAt(0)}</div>
																			)
																	}
																</td>
																<td>{content.FirstName}</td>
																<td>{content.LastName}</td>
																<td><strong>{content.email}</strong></td>
																{/*<td>
																				{content.emailVerified === "True" ? (
																					<Badge variant="success">{content.emailVerified}</Badge>
																				) : (
																					<Badge variant="danger">{content.emailVerified}</Badge>
																				)}
																			</td>
																			 <td>{content.EmailSignature}</td> 
																			 <td>{content.Password}</td>
																			 <td><strong>{content.Country}</strong></td>
																			<td>{content.JobTitle}</td>
																			<td><strong>{content.PhoneNumber}</strong></td>
																			<td>{content.Language}</td> */}
																<td>
																	{content.disabled === "True" ? (
																		<Badge variant="success">{content.disabled}</Badge>
																	) : (
																		<Badge variant="danger">{content.disabled}</Badge>
																	)}
																</td>
																<td>{content.companyName}</td>
																<td>{content.userRole}</td>
																<td>{content.metadata ? JSON.parse(content.metadata).lastSignInTime : ''}</td>
																<td>{content.metadata ? JSON.parse(content.metadata).creationTime : ''}</td>
																<td>
																	<div className="d-flex">
																		<Link className="btn btn-secondary shadow btn-xs sharp me-2" to=""
																			onClick={(event) => handleEditClick(event, content)}
																		>
																			<i className="fas fa-pen"></i>
																		</Link>
																		{company.founderID !== content.uid ?
																			(
																				<Link className="btn btn-danger shadow btn-xs sharp me-2" to=""
																					onClick={(event) => handleDeleteUser(event, content)}
																				>
																					<i className="fa fa-trash"></i>
																				</Link>
																			) : (
																				<></>
																			)}
																	</div>
																</td>
															</>
														)
													}
												</tr>
											))}
										</tbody>
									</table>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
		) : (
			<Modal show={showAccessDeniedModal} >
			<Modal.Header>
			  <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			{lang.menu.YoudontHavePerm}
			</Modal.Body>
			<Modal.Footer>
		
			</Modal.Footer>
		  </Modal>
		 )}</>
	)
}
export default Company;
