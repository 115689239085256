import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Form,Col,Row,Badge, Navbar, ProgressBar, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { AiOutlineFieldNumber } from "react-icons/ai";
import { useTimer } from "react-timer-hook";
import "./test.css";
import translations from "../AppsMenu/Candidate/mulitlingue";

import logo from "./../../../images/logo-full.png"
import ReactApexChart from "react-apexcharts";

function TestPsychotechniqueCandidat() {
  const token=localStorage.getItem("token");

  const localTime=localStorage.getItem("questionsNbrPsycho")
  const timePerQuestionPsycho= localStorage.getItem("timePerQuestionPsycho");
  const [testDetails, setTestDetails] = useState();
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [result, setResult] = useState({});
  const [score, setScore] = useState(0);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [timerExpired, setTimerExpired] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [categoryCorrectCounts, setCategoryCorrectCounts] = useState();
  const pathname = window.location.pathname;
  const [lang, setLang] = useState(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
		  const parsedLang = JSON.parse(storedLang);
		  if (parsedLang.language === 'Français') {
			return translations.fr;
		  }
		}
		return translations.en;
	  });
    const getUserCompanyID = async () => {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
      const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      const data = await responseC.json();
      const compId = data[0].companyID;
      return compId;
    };
    useEffect(() => {
  
      const selectedLang = JSON.parse(localStorage.getItem('selectedLang'));
      if (selectedLang) {
        setLang(selectedLang);
      }
    }, []);
    const [timeInSeconds, setTimeInSeconds] = useState(localTime*timePerQuestionPsycho);
  const [nbrQuesPerCat, setNbrQuesPerCat] = useState(0);

  useEffect(() => {
    if (testDetails && testDetails.question_globale) {
      setTimeInSeconds(testDetails.question_globale);
      setNbrQuesPerCat(testDetails.test_questionNbre);
    }
  }, [testDetails]);
  const {
    seconds,
    minutes,
    hours,
  } = useTimer({ expiryTimestamp: new Date().getTime() + timeInSeconds * 1000 });

  useEffect(() => {
    if (seconds === 0 && minutes === 0 && hours === 0) {
      handleSubmitTest(new Event("timerExpired"));
      setTimerExpired(true);
    }
  }, [seconds, minutes, hours]);
  const idtest = pathname.split("/").pop();
  const history = useHistory();
  const isImage = (url) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    const extension = url.substring(url.lastIndexOf(".")).toLowerCase();
    return imageExtensions.includes(extension);
  };

  const getImageUrlFromServer = (imageName, categoryName, id) => {
    return `${process.env.REACT_APP_APP_DOMAIN}/api/question1/images/${categoryName}/${id}/${imageName}`;
  };

  const getImageUrl = (imageRef, id, categorieName) => {
    if (isImage(imageRef)) {
      const imageUrl = getImageUrlFromServer(imageRef, categorieName, id);
      return imageUrl;
    }
    return null;
  };

  const renderContent = (content, id, categoryName) => {
    const imageUrl = getImageUrl(content, id, categoryName);

    if (imageUrl) {
      return (
        <div className="frame">
          <img src={imageUrl} alt=" " width={150} height={100} />
        </div>
      );
    } else {
      return <strong style={{ fontSize: "14px" }}>{content}</strong>;
    }
  };
  const renderContentRef = (content, id, categoryName) => {
    const imageUrl = getImageUrl(content, id, categoryName);

    if (imageUrl) {
      return (
        <div className="frame">
          <img src={imageUrl} alt=" " width={300} height={200} />
        </div>
      );
    } else {
      return <strong style={{ fontSize: "14px" }}>{content}</strong>;
    }
  };
  const handleAnswerChange = (questionId, answer) => {
    setSelectedAnswers((prevSelectedAnswers) => ({
      ...prevSelectedAnswers,
      [questionId]: answer,
    }));
  };

  const handleSubmitTest = (e) => {
    //e.preventDefault();

    const newResult = {};
    let newScore = 0;
    const catList = [];
    const catListEr = [];
    testDetails.categories.forEach((category) => {
      category.questions.forEach((question) => {
        const questionId = question.id;
        const selectedAnswer = selectedAnswers[questionId];
        const correctAnswer = question.BonneReponse;

        newResult[questionId] = {
          selectedAnswer,
          correctAnswer,
        };

        if (selectedAnswer === correctAnswer) {
          newScore++;
          catList.push(category.category_name)
          
        }
        if (selectedAnswer !== correctAnswer) {
          catListEr.push(category.category_name)
          
        }
      });
    });
    const comptages = {};

    for (const mot of catList) {
      if (comptages[mot]) {
        comptages[mot]++;
      } else {
        comptages[mot] = 1;
      }
    }
    const comptagesEr = {};

    for (const mot of catListEr) {
        comptagesEr[mot] = 0;
      
    }
    const liste = {};

    for (const mot in comptages) {
      if (comptagesEr[mot] !== undefined) {
        liste[mot] = comptages[mot] + comptagesEr[mot];
      } else {
        liste[mot] = comptages[mot];
      }
    }
    
    for (const mot in comptagesEr) {
      if (!liste[mot]) {
        liste[mot] = comptagesEr[mot];
      }
    }
  
    setResult(newResult);
    setScore(newScore);
    setSubmitClicked(true);
    setIsButtonDisabled(true);
    updateScoreInDatabase(newScore,liste,timeInSeconds,nbrQuesPerCat);
    updateScore(newScore,timeInSeconds,nbrQuesPerCat);
    //redirectToRapport(timeInSeconds,liste,nbrQuesPerCat);
    setCategoryCorrectCounts(liste)
  };

  const redirectToRapport = (nbreQuest,categoryCorrectCounts,nbr) => {
    const userDetailsString = localStorage.getItem("userDetails")
    const userDetails = JSON.parse(userDetailsString);
    const user_id = userDetails ? userDetails.localId : null;
    const jobRoleId = localStorage.getItem("jobRoleId");

    history.push("/rapport", { user_id, jobRoleId, nbreQuest,categoryCorrectCounts,nbr });
  };

  const updateScoreInDatabase = async (score,categoryCorrectCounts,timeInSeconds,nbrQuesPerCat) => {
    const userDetailsString = localStorage.getItem("userDetails")
    const userDetails = JSON.parse(userDetailsString);
    const selectedCandidate = userDetails ? userDetails.localId : null;
    const jobRoleId = localStorage.getItem("jobRoleId");

    try {
      const radarPsychoJSON = JSON.stringify(categoryCorrectCounts); 
      const score1=nbrQuesPerCat*score;
      //const scorePerTime = `${score1}/${timeInSeconds}`; 
      const scorePerTime = score1/timeInSeconds;
      const scoreFin10=(10*scorePerTime)/nbrQuesPerCat;
      const compId= await getUserCompanyID();
      console.log("compId: ",compId)
      await axios.put(
        `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/candidatesjobtest/${selectedCandidate}/${jobRoleId}/${idtest}`,
        {
          note: scoreFin10,
          radarPsycho:radarPsychoJSON,
          compId:compId,
        }, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
    } catch (error) {
      console.error(error);
    }
  };
  const updateScore = async (score, timeInSeconds, nbrQuesPerCat) => {
    const userDetailsString = localStorage.getItem("userDetails")
    const userDetails = JSON.parse(userDetailsString);
    const selectedCandidate = userDetails ? userDetails.localId : null;
        const jobRoleId = localStorage.getItem("jobRoleId");
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getCandidateJobRole/${selectedCandidate}/${jobRoleId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      const Ptscore = response.data.candidateJobRole.Ptscore; 
      console.log("Ptscore: ", Ptscore);
      const score1 = nbrQuesPerCat * score;
      const scorePerTime = score1 / timeInSeconds;
      const scoreFin10 = Ptscore+((10 * scorePerTime) / nbrQuesPerCat);
  
      console.log("score tt: ", scoreFin10);
  
      // Envoi du scoreFin10 à l'API
      await axios.post(
        `${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/updatePtscore`,
        {
          userId: selectedCandidate,
          newPtscore: scoreFin10,
          jobId: jobRoleId,
        }, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
    } catch (error) {
      console.error(error);
    }
  };
  
  const [categories, setCategories] = useState();
  const [currentIndexCategory, setCurrentIndexCategory] = useState(0);

  useEffect(() => {
    const fetchTestDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/testPsycho/getTestWithQuestionsById/${idtest}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        setCategories(response.data.categories.map(category => category.category_name));
        setTestDetails(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTestDetails();
  }, []);
  

  //show submit button
const [submit, setSubmit]= useState(false);
//progressBar length
const [testProgress, setTestProgress]= useState(0);
//show Previous button
const [previous, setPrevious]= useState(false);

 const getTheNextValue = () => {
  try {
    if(categories){
      setTestProgress(testProgress + 1);
      
      if((categories.length === 1) ||(currentIndexCategory == (categories.length -2)) ){
        setSubmit(true);
      }  
      if (currentIndexCategory < categories.length - 1) {
       setCurrentIndexCategory(currentIndexCategory + 1);
     } }
    
  } catch (error) {
    console.log(error);
  }
 };

 const getPreviousValue = () => {
  try {
    if(categories){
      setTestProgress(testProgress - 1);
	    setSubmit(false)
        
      if (currentIndexCategory > 0) {
        if(currentIndexCategory - 1 === 0){
          setPrevious(false);
        }
       setCurrentIndexCategory(currentIndexCategory - 1);
     } else{
      setPrevious(false);
     }
    }
    
  } catch (error) {
    console.log(error);
  }
 };



  return (
    <div style={{  width:"100vw", height:"100vh",margin: "0",padding: "0"}}>

    <Navbar  >
      
      <Navbar.Brand ><img className="brand-title" src={logo}/></Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <div className="timer">
          <span id="hours">{hours}</span>:<span id="minutes">{minutes}</span>:<span id="seconds">{seconds}</span>
        </div>
      </Navbar.Collapse>
     
   

</Navbar> 

<div style={{backgroundColor:"#D9D9D9",display: "flex", justifyContent: "center", alignItems:"center", flexDirection: "column",  overflowY: "scroll", height:"88%" }}>
{testDetails && (
        <>
        <Card style={{backgroundColor:"white", border: "2px solid white", textAlign:"center", width: "96%", marginTop: "1%", marginBottom:"1%" }}>
          
          <Card.Header>
            <h2 style={{textAlign: "left", color: "#8CD6C8"}}>{testDetails.categories[currentIndexCategory].category_name}</h2>
            <h2 style={{ color: "purple"}}>{testDetails.test_name}</h2>
            
          </Card.Header>
        
        <Card.Body style={{backgroundColor:"white"}}>
        <Row style={{width: "92%", justifyContent: "between", height:"100%", marginBottom:"2%"}}>
        {Object.keys(result).length === 0 && 
        <Col className="col-8" style={{textAlign: "left", position: "relative"}}>
        
            {testDetails.categories[currentIndexCategory].questions.map((currentQuestion, index) => 
            <div key={currentQuestion.id} style={{marginBottom:"2%"}}> <h5><strong>{index+1 + "." + currentQuestion.question}</strong>
            </h5>

            <div className="center">
                        {currentQuestion.imageRef &&
                          renderContentRef(
                            currentQuestion.imageRef,
                            currentQuestion.id,
                            testDetails.categories[currentIndexCategory].category_name
                          )}
                      </div>
                      
     <Form.Group style={{textAlign:"center", display: "flex",flexDirection: "row",  marginTop: "5%"}}>
           
         <Form.Check
                           // type="radio"
                            id={`answer1_${currentQuestion.id}`}
                            name={`answer_${currentQuestion.id}`}
                            className="choice"
                          
                           style={{ marginRight: '10px', marginLeft:'0' }}
                          >
                            <Form.Check.Input 
                            type="radio" 
                            isValid 
                            checked={selectedAnswers[currentQuestion.id] === "choix1"}
                            onChange={() =>{
                              console.log("id", currentQuestion.id);
                              handleAnswerChange(currentQuestion.id, "choix1");
                              }
                            }/>
                           <Form.Check.Label><span style={{ fontSize: '14px', color: 'black' }}>A. {renderContent(
                            currentQuestion.choix1,
                            currentQuestion.id,
                            categories[currentIndexCategory]
                          )}
                          </span>
                            </Form.Check.Label>
                          
                          </Form.Check>
                          <Form.Check
                            type="radio"
                            id={`answer2_${currentQuestion.id}`}
                            name={`answer_${currentQuestion.id}`}
                            className="choice"
                           
                           style={{  marginRight: '10px', marginLeft:'0' }}
                          >
                            <Form.Check.Input 
                            type="radio" 
                            isValid
                            checked={selectedAnswers[currentQuestion.id] === "choix2"}
                            onChange={() =>
                              handleAnswerChange(currentQuestion.id, "choix2")
                            }/>
                           <Form.Check.Label><span style={{ fontSize: '14px', color: 'black' }}>
                            B. {renderContent(
                            currentQuestion.choix2,
                            currentQuestion.id,
                            categories[currentIndexCategory]
                          )}
                          
                          </span>
                          </Form.Check.Label>
                          </Form.Check>
                          <Form.Check
                            type="radio"
                            id={`answer3_${currentQuestion.id}`}
                            name={`answer_${currentQuestion.id}`}
                            className="choice"
                           
                           style={{ marginRight: '10px', marginLeft:'0' }}
                          >
                            <Form.Check.Input 
                            type="radio" 
                            isValid
                            checked={selectedAnswers[currentQuestion.id] === "choix3"}
                            onChange={() =>
                              handleAnswerChange(currentQuestion.id, "choix3")
                            }/>
                           <Form.Check.Label><span style={{ fontSize: '14px', color: 'black' }}>
                           C. {renderContent(
                            currentQuestion.choix3,
                            currentQuestion.id,
                            categories[currentIndexCategory]
                          )}
                          </span>
                          </Form.Check.Label>
                          </Form.Check>
                          {currentQuestion.choix4 && (
                            <>
                              <Form.Check
                                type="radio"
                                id={`answer4_${currentQuestion.id}`}
                                name={`answer_${currentQuestion.id}`}
                                className="choice"
                                
                            
                                style={{ marginRight: '10px', marginLeft:'0' }}
                              >
                                <Form.Check.Input 
                                type="radio" 
                                isValid
                                checked={
                                  selectedAnswers[currentQuestion.id] === "choix4"
                                }
                                onChange={() =>
                                  handleAnswerChange(currentQuestion.id, "choix4")
                                }/>
                           <Form.Check.Label><span style={{ fontSize: '14px', color: 'black' }}>
                            D. {renderContent(
                                currentQuestion.choix4,
                                currentQuestion.id,
                                categories[currentIndexCategory]
                              )}
                             </span>
                              </Form.Check.Label>
                              </Form.Check>
                            </>
                          )}
                        </Form.Group> 
                      </div>)}
          
          <div style={{position: "absolute", bottom:"0", right: "0"}}> 
           
          {previous && <Button 
              variant="success"
            onClick={getPreviousValue}
            disabled={isButtonDisabled || timerExpired}
            >{lang.menu.previous}
            </Button>}
            
          {!submit ? 
          <>
          

          <Button 
              
            onClick={()=>{
              getTheNextValue();
              setPrevious(true);
            
            }}
            disabled={isButtonDisabled || timerExpired}
            >{lang.menu.next}</Button>

            
            </>
        : <button
        type="submit"
        className="btn btn-outline-primary btn-rounded"
        disabled={isButtonDisabled || timerExpired}
        onClick={()=>{
          setPrevious(false);
          handleSubmitTest();
          getTheNextValue();}}
        variant="success"


      >
      {lang.menu.submit}
      </button>}
      </div>
         
        </Col>
}
        <div>

        {Object.keys(result).length !== 0 &&  <div>
        <div className="row mt-4 align-items-center">
                      <div id="currentScore">
												<ReactApexChart 
                        options={{
                          dataLabels:{
                            enabled: false
                          },
                          stroke: {
                            width: 0,
                          },
                          colors:['#8cd6c8', '#74188D' ],
                          labels: ['Correct Answers', 'Wrong Answers']
                        }}
                        
                        series={[score, localTime - score]}
                        type="donut"
                        height={250}
                        />
											</div>

                      {testDetails.categories.map(category =>
                        <div>
                        <div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
												<span className="fs-14 font-w500">{category.category_name}</span>
												<span className="fs-16"><span className="text-black pe-2"></span>{categoryCorrectCounts[category.category_name] +"/" + category.questions.length}</span>
											</div>
											
                      </div>
                      )}
                      </div>
                      <Button variant="dark light" size="sm" style={{textAlign:"center"}} onClick={()=>redirectToRapport(timeInSeconds,categoryCorrectCounts,nbrQuesPerCat)}>Return</Button>
									
                  </div>}

        
        </div>
        </Row>
        </Card.Body>
        <Card.Footer style={{backgroundColor:"white"}}>
        <ProgressBar style={{height:"80%", border: "2px solid #8CD6C8"}} now={(testProgress <= categories.length ? testProgress : categories.length) * 100 / categories.length} variant="success" label={(testProgress <= categories.length ? testProgress : categories.length) + "/" + categories.length}></ProgressBar>

        </Card.Footer>

        </Card>
       
       
        </>
                              )}   


</div>




   
    </div>
  );
}

export default TestPsychotechniqueCandidat;
