import { React, useState, useEffect,Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import Editable from "./Editable";
import CompanyEditable from "./CompanyEditable";
import translations from "../../components/AppsMenu/Candidate/mulitlingue";
import { Badge } from "react-bootstrap";
import { Card, Tab, Nav, Modal ,Form,Button,Row,Col} from "react-bootstrap";
import axios from 'axios';
import { Icon } from "@iconify/react";
import { FaArrowRight } from 'react-icons/fa';
import { LuLoader } from "react-icons/lu";


const Admin_dashboard = () => {
  const [adminDash, setAdminDash] = useState([]);
  const token=localStorage.getItem("token");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          setAdminDash(
            data.some(
              (permission) =>
                permission.title === "view-admin-dashoard"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!adminDash) {
      setShowAccessDeniedModal(true); 
    }
  }, [adminDash]);
  const avatarClasses = [
    "empty-avatar-red",
    "empty-avatar-green",
    "empty-avatar-pimary",
    "empty-avatar-yellow",
    "empty-avatar-purple",
  ];
  const [lang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });

  const [users, setUsers] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [userPend, setUserPend] = useState(null);

  const { activeTab } = useParams();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/companies`, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.json())
      .then((data) => {
        setCompanies(data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  // Fetch role data from the API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/roles/roles`, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.json())
      .then((data) => {
        setUserRoles(data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  // Fetch user data from the API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/users`, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.json())
      .then((data) => {
        const convertedData = convertNumericFields(data);
        setUsers(convertedData);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);
  const handlesynchro = async () => {
    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/synchro`)
      .then((response) => response.json())
      .then((data) => {
        swal("", lang.menu.SynchronizeUsers, "success").then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  const handleDeleteUser = async (event, content) => {
    event.preventDefault();

    const userId = content.uid;

    const response = await fetch(
      `${process.env.REACT_APP_APP_DOMAIN}/api/users/delete/${userId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    );

    const data = await response.json();

    if (response.status === 500) {
      if (data.error.code === "ER_ROW_IS_REFERENCED_2")
        swal("", lang.menu.DeleteUserWithCompanyError, "error");
      else swal("", lang.menu.DeleteUserError, "error");
    } else {
      swal("", lang.menu.DeleteUserSuccess, "success").then(() => {
        const convertedData = convertNumericFields(data);
        setUsers(convertedData);
      });
    }
  };

  const handleRejectUser = async (event, content) => {
    event.preventDefault();

    const userId = content.uid;

    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/reject/${userId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }, 
  )
      .then(
        swal("", lang.menu.RejectedusersSuccess, "success").then(() => {
          window.location.reload();
        })
      )
      .catch((error) => {
        console.error("Error rejected user:", error);
        swal("", lang.menu.RejectedusersError, "error");
      });
  };
  const handleAcceptUserBefore = (event,content) => {
    console.log("content:",content)
    event.preventDefault();
    if(content.userRoleID!==2 && content.userRoleID!==3 )  {
      handleAcceptUser(event,content)
    }else{

      
      setUserPend(content)
    setShowModal(true);  

    }
      
  };
  const handleAcceptUser = async (event, content) => {
    event.preventDefault();

    const userId = content.uid;
    const storedLang = localStorage.getItem("selectedLang");
    const parsedLang = JSON.parse(storedLang);

    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/accept/${userId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        comment: "",  
        lang: (parsedLang && parsedLang.language) ? parsedLang.language : "Français" 

      }),
    })
      .then(
        swal("", lang.menu.AcceptedusersSuccess, "success").then(() => {
          window.location.reload();
        })
      )
      .catch((error) => {
        console.error("Error accepted user:", error);
        swal("", lang.menu.AccepedusersError, "error");
      });
  };

  function convertNumericFields(users) {
    const convertedUsers = users.map((user) => {
      const newUser = { ...user };

      // Convert emailVerified
      if (newUser.emailVerified === 0) {
        newUser.emailVerified = "False";
      } else if (newUser.emailVerified === 1) {
        newUser.emailVerified = "True";
      }

      // Convert disabled
      if (newUser.disabled === 0) {
        newUser.disabled = "False";
      } else if (newUser.disabled === 1) {
        newUser.disabled = "True";
      }

      return newUser;
    });

    return convertedUsers;
  }
  const fieldsToCheck = {
    All: lang.menu.All,
    FirstName: lang.menu.FirstName,
    LastName: lang.menu.LastName,
    email: lang.menu.Email,
    /*'emailVerified': lang.menu.EmailVerification,
					'Country': lang.menu.Country,
				'JobTitle': lang.menu.MyJobtitle,
				'PhoneNumber': lang.menu.PhoneNumber,
				'Language': lang.menu.Language, */
    disabled: lang.menu.Disabled,
    companyName: lang.menu.Company,
    userRole: lang.menu.Role,
    lastSignInTime: lang.menu.lastSignInTime,
    creationTime: lang.menu.creationTime,
  };

  const fieldsToCheckPending = {
    All: lang.menu.All,
    FirstName: lang.menu.FirstName,
    LastName: lang.menu.LastName,
    email: lang.menu.Email,
    disabled: lang.menu.Disabled,
    companyName: lang.menu.Company,
    userRole: lang.menu.Role,
  };

  const fieldsToCheckRejected = {
    All: lang.menu.All,
    FirstName: lang.menu.FirstName,
    LastName: lang.menu.LastName,
    email: lang.menu.Email,
    disabled: lang.menu.Disabled,
    companyName: lang.menu.Company,
    userRole: lang.menu.Role,
  };

  const fieldsToCheckCreated = {
    All: lang.menu.All,
    FirstName: lang.menu.FirstName,
    LastName: lang.menu.LastName,
    email: lang.menu.Email,
    emailVerified: lang.menu.EmailVerification,
    disabled: lang.menu.Disabled,
  };

  const fieldsToCheckCompany = {
    All: lang.menu.All,
    companyName: lang.menu.companyName,
    founder: lang.menu.founder,
    numberOfUsers: lang.menu.numberOfUsers,
    comments: lang.menu.Comments,
  };

  const [selectedFilterField, setSelectedFilterField] = useState("All");
  const [selectedFilterFieldPending, setSelectedFilterFieldPending] =
    useState("All");
  const [selectedFilterFieldRejected, setSelectedFilterFieldRejected] =
    useState("All");
  const [selectedFilterFieldCreated, setSelectedFilterFieldCreated] =
    useState("All");
  const [selectedFilterFieldCompany, setSelectedFilterFieldCompany] =
    useState("All");

  const [filterText, setFilterText] = useState("");
  const [filterTextPending, setFilterTextPending] = useState("");
  const [filterTextRejected, setFilterTextRejected] = useState("");
  const [filterTextCreated, setFilterTextCreated] = useState("");
  const [filterTextCompany, setFilterTextCompany] = useState("");

  const filteredUsers = users
    .filter((user) => user.adminVerified === 1)
    .filter((user) => {
      if (
        selectedFilterField === "creationTime" ||
        selectedFilterField === "lastSignInTime"
      ) {
        const fieldValue = JSON.parse(user.metadata)[selectedFilterField];
        if (
          fieldValue !== null &&
          fieldValue !== undefined &&
          fieldValue.toLowerCase().includes(filterText.toLowerCase())
        ) {
          return true;
        }
        return false;
      } else if (selectedFilterField !== "All") {
        const fieldValue = user[selectedFilterField];
        if (fieldValue !== null && fieldValue !== undefined) {
          if (typeof fieldValue === "number") {
            if (fieldValue.toString().includes(filterText)) {
              return true;
            }
          } else if (
            typeof fieldValue === "string" &&
            fieldValue.toLowerCase().includes(filterText.toLowerCase())
          ) {
            return true;
          } else if (typeof fieldValue === "boolean") {
            if (fieldValue.toString() === filterText) {
              return true;
            }
          }
        }
        return false;
      } else {
        const fieldsToCheck = {
          FirstName: lang.menu.FirstName,
          LastName: lang.menu.LastName,
          email: lang.menu.Email,
          /*'emailVerified': lang.menu.EmailVerification,
									'Country': lang.menu.Country,
								'JobTitle': lang.menu.MyJobtitle,
								'PhoneNumber': lang.menu.PhoneNumber,
								'Language': lang.menu.Language, */
          disabled: lang.menu.Disabled,
          companyName: lang.menu.Company,
          userRole: lang.menu.Role,
          lastSignInTime: lang.menu.lastSignInTime,
          creationTime: lang.menu.creationTime,
        };

        for (const field in fieldsToCheck) {
          let fieldValue = user[field];
          if (field === "lastSignInTime" || field === "creationTime") {
            fieldValue = JSON.parse(user.metadata)[field];
          }

          if (fieldValue !== null && fieldValue !== undefined) {
            if (typeof fieldValue === "number") {
              if (fieldValue.toString().includes(filterText)) {
                return true;
              }
            } else if (
              typeof fieldValue === "string" &&
              fieldValue.toLowerCase().includes(filterText.toLowerCase())
            ) {
              return true;
            } else if (typeof fieldValue === "boolean") {
              if (fieldValue.toString() === filterText) {
                return true;
              }
            }
          }
        }

        return false;
      }
    });

  const filteredUsersPending = users
    .filter((user) => user.adminVerified === 0 && user.userRole)
    .filter((user) => {
      if (selectedFilterFieldPending !== "All") {
        const fieldValue = user[selectedFilterFieldPending];
        if (fieldValue !== null && fieldValue !== undefined) {
          if (typeof fieldValue === "number") {
            if (fieldValue.toString().includes(filterTextPending)) {
              return true;
            }
          } else if (
            typeof fieldValue === "string" &&
            fieldValue.toLowerCase().includes(filterTextPending.toLowerCase())
          ) {
            return true;
          } else if (typeof fieldValue === "boolean") {
            if (fieldValue.toString() === filterTextPending) {
              return true;
            }
          }
        }
        return false;
      } else {
        const fieldsToCheck = {
          FirstName: lang.menu.FirstName,
          LastName: lang.menu.LastName,
          email: lang.menu.Email,
          disabled: lang.menu.Disabled,
          companyName: lang.menu.Company,
          userRole: lang.menu.Role,
        };

        for (const field in fieldsToCheck) {
          let fieldValue = user[field];
          if (fieldValue !== null && fieldValue !== undefined) {
            if (typeof fieldValue === "number") {
              if (fieldValue.toString().includes(filterTextPending)) {
                return true;
              }
            } else if (
              typeof fieldValue === "string" &&
              fieldValue.toLowerCase().includes(filterTextPending.toLowerCase())
            ) {
              return true;
            } else if (typeof fieldValue === "boolean") {
              if (fieldValue.toString() === filterTextPending) {
                return true;
              }
            }
          }
        }

        return false;
      }
    });

  const filteredUsersRejected = users
    .filter((user) => user.adminVerified === 2)
    .filter((user) => {
      if (selectedFilterFieldRejected !== "All") {
        const fieldValue = user[selectedFilterFieldRejected];
        if (fieldValue !== null && fieldValue !== undefined) {
          if (typeof fieldValue === "number") {
            if (fieldValue.toString().includes(filterTextRejected)) {
              return true;
            }
          } else if (
            typeof fieldValue === "string" &&
            fieldValue.toLowerCase().includes(filterTextRejected.toLowerCase())
          ) {
            return true;
          } else if (typeof fieldValue === "boolean") {
            if (fieldValue.toString() === filterTextRejected) {
              return true;
            }
          }
        }
        return false;
      } else {
        const fieldsToCheck = {
          FirstName: lang.menu.FirstName,
          LastName: lang.menu.LastName,
          email: lang.menu.Email,
          disabled: lang.menu.Disabled,
          companyName: lang.menu.Company,
          userRole: lang.menu.Role,
        };

        for (const field in fieldsToCheck) {
          let fieldValue = user[field];
          if (fieldValue !== null && fieldValue !== undefined) {
            if (typeof fieldValue === "number") {
              if (fieldValue.toString().includes(filterTextRejected)) {
                return true;
              }
            } else if (
              typeof fieldValue === "string" &&
              fieldValue
                .toLowerCase()
                .includes(filterTextRejected.toLowerCase())
            ) {
              return true;
            } else if (typeof fieldValue === "boolean") {
              if (fieldValue.toString() === filterTextRejected) {
                return true;
              }
            }
          }
        }

        return false;
      }
    });

  const filteredUsersCreated = users
    .filter((user) => !user.userRole)
    .filter((user) => {
      if (selectedFilterFieldCreated !== "All") {
        const fieldValue = user[selectedFilterFieldCreated];
        if (fieldValue !== null && fieldValue !== undefined) {
          if (typeof fieldValue === "number") {
            if (fieldValue.toString().includes(filterTextCreated)) {
              return true;
            }
          } else if (
            typeof fieldValue === "string" &&
            fieldValue.toLowerCase().includes(filterTextCreated.toLowerCase())
          ) {
            return true;
          } else if (typeof fieldValue === "boolean") {
            if (fieldValue.toString() === filterTextCreated) {
              return true;
            }
          }
        }
        return false;
      } else {
        const fieldsToCheck = {
          FirstName: lang.menu.FirstName,
          LastName: lang.menu.LastName,
          email: lang.menu.Email,
          emailVerified: lang.menu.EmailVerification,
          disabled: lang.menu.Disabled,
        };

        for (const field in fieldsToCheck) {
          let fieldValue = user[field];
          if (fieldValue !== null && fieldValue !== undefined) {
            if (typeof fieldValue === "number") {
              if (fieldValue.toString().includes(filterTextCreated)) {
                return true;
              }
            } else if (
              typeof fieldValue === "string" &&
              fieldValue.toLowerCase().includes(filterTextCreated.toLowerCase())
            ) {
              return true;
            } else if (typeof fieldValue === "boolean") {
              if (fieldValue.toString() === filterTextCreated) {
                return true;
              }
            }
          }
        }

        return false;
      }
    });

  const filteredUsersCompany = companies.filter((user) => {
    if (selectedFilterFieldCompany !== "All") {
      const fieldValue = user[selectedFilterFieldCompany];
      if (fieldValue !== null && fieldValue !== undefined) {
        if (typeof fieldValue === "number") {
          if (fieldValue.toString().includes(filterTextCompany)) {
            return true;
          }
        } else if (
          typeof fieldValue === "string" &&
          fieldValue.toLowerCase().includes(filterTextCompany.toLowerCase())
        ) {
          return true;
        } else if (typeof fieldValue === "boolean") {
          if (fieldValue.toString() === filterTextCompany) {
            return true;
          }
        }
      }
      return false;
    } else {
      const fieldsToCheck = {
        companyName: lang.menu.companyName,
        founder: lang.menu.founder,
        numberOfUsers: lang.menu.numberOfUsers,
        comments: lang.menu.Comments,
      };

      for (const field in fieldsToCheck) {
        let fieldValue = user[field];
        if (fieldValue !== null && fieldValue !== undefined) {
          if (typeof fieldValue === "number") {
            if (fieldValue.toString().includes(filterTextCompany)) {
              return true;
            }
          } else if (
            typeof fieldValue === "string" &&
            fieldValue.toLowerCase().includes(filterTextCompany.toLowerCase())
          ) {
            return true;
          } else if (typeof fieldValue === "boolean") {
            if (fieldValue.toString() === filterTextCompany) {
              return true;
            }
          }
        }
      }

      return false;
    }
  });
  const [editContentId, setEditContentId] = useState(null);
  const [editContentCompanyId, setEditContentCompanyId] = useState(null);

  const handleEditCompanyClick = (event, content) => {
    event.preventDefault();
    setEditContentCompanyId(content.companyid);
    const formValues = {
      companyid: content.companyid,
      companyName: content.companyName,
      numberOfUsers: content.numberOfUsers,
      comments: content.comments,
      founder: content.founder,
    };
    setEditCompanyData(formValues);
  };

  const handleEditClick = (event, content) => {
    event.preventDefault();
    setEditContentId(content.uid);
    const formValues = {
      userId: content.uid,
      firstName: content.FirstName,
      lastName: content.LastName,
      email: content.email,
      password: content.Password,
      country: content.Country,
      jobTitle: content.JobTitleID,
      phoneNumber: content.PhoneNumber,
      language: content.Language,
      avatar: content.Avatar,
      cover: content.Cover,
      signature: content.EmailSignature,
      userRole: content.userRoleID ? content.userRoleID : 4,
      disabled: content.disabled === "True" ? 1 : 0,
      company: content.companyName,
      emailVerified: content.emailVerified,
      lastSignInTime: content.metadata
        ? JSON.parse(content.metadata).lastSignInTime
        : "",
      creationTime: content.metadata
        ? JSON.parse(content.metadata).creationTime
        : "",
    };
    setEditFormData(formValues);
  };

  const [editCompanyData, setEditCompanyData] = useState({
    companyid: "",
    companyName: "",
    numberOfUsers: "",
    comments: "",
    founder: "",
  });

  const [editFormData, setEditFormData] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    country: "",
    jobTitle: "",
    phoneNumber: "",
    language: "",
    avatar: "",
    cover: "",
    signature: "",
    userRole: "",
    disabled: "",
    company: "",
    emailVerified: "",
    lastSignInTime: "",
    creationTime: "",
  });

  //update data function
  const handleEditFormChange = async (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  const handleEditFormCompanyChange = async (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editCompanyData };
    newFormData[fieldName] = fieldValue;
    setEditCompanyData(newFormData);
  };

  //Cencel button to same data
  const handleCancelClick = () => {
    setEditContentId(null);
  };

  const handleCancelCompanyClick = () => {
    setEditContentCompanyId(null);
  };

  const handleSubmitClick = async () => {
    try {
      // Send user data to backend for saving
      const response = await fetch(
        `${process.env.REACT_APP_APP_DOMAIN}/api/users/updatefirebase`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editFormData),
        }
      );

      if (response.ok) {
        swal("", lang.menu.ProfileUpdate, "success").then(() => {
          window.location.reload();
        });
      } else {
        swal("Oops", lang.menu.ProfileUpdateError, "error");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleDeleteCompany = async (event, content) => {
    event.preventDefault();

    const companyid = content.companyid;

    const response = await fetch(
      `${process.env.REACT_APP_APP_DOMAIN}/api/companies/local/delete/${companyid}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    );

    const data = await response.json();

    if (response.status === 500) {
      if (data.error.code === "ER_ROW_IS_REFERENCED_2")
        swal("", lang.menu.CompShoudlBeEmpty, "error");
      else swal("", lang.menu.DeleteCompanyError, "error");
    } else {
      swal("", lang.menu.DeleteCompanySuccess, "success").then(() => {
        setCompanies(data);
      });
    }
  };

  const handleSubmitCompanyClick = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_DOMAIN}/api/companies/local/savecompanyDetailsDET`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editCompanyData),
        }
      );

      if (response.status === 500) {
        console.error("Server Error: Internal Server Error (500)");
        swal("Oops", lang.menu.CompanyExists, "error");
      } else {
        const data = await response.json();
        swal("", lang.menu.CompanyUpdate, "success").then(() => {
          setCompanies(data);
          setEditContentCompanyId(null);
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  //Modal box
  const [showCompany, setShowCompany] = useState(false);

  const [companyDetails, setcompanyDetails] = useState({
    companyName: "",
    founderID: "",
    numberOfUsers: "",
    comments: "",
  });

  const handleShowCompany = async (event, content) => {
    event.preventDefault();
    setShowCompany(true);
    const companyDetails = {
      companyName: content.companyName,
      founderID: content.founderID,
      numberOfUsers: content.numberOfUsers,
      comments: content.comments,
    };
    setcompanyDetails(companyDetails);
  };





  const [selectedCurrency, setSelectedCurrency] = useState("EUR");
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [enterpriseSubscriptions, setEnterpriseSubscriptions] = useState([]);
  const [soloSubscriptions, setSoloSubscriptions] = useState([]);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [featureselected, setFeatureselected] = useState({});
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    const showFeatures = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionFeature/getAll`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const featureData = response.data;
  
        setFeatures(featureData);
  
        const response2 = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionfeaturespertypes/findAllWithTypeSub`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const sub = response2.data;
        setSubscriptionTypes(sub);
  
        const featureOrder = [
          'Candidate Profiles',
          'Candidate extraction',
          'Create job profile',
          'Create job role',
          'Parcours',
        ];
  
        const soloSubs = {};
        const enterpriseSubs = {};
  
        for (const [key, value] of Object.entries(sub)) {
          // Create a copy of the value array and sort it
          const sortedValue = [...value].sort((a, b) => {
            return featureOrder.indexOf(a.feature_name) - featureOrder.indexOf(b.feature_name);
          });
  
          if (key.startsWith("Solo") || key === "Free" || key === "Pay as you go") {
            soloSubs[key] = sortedValue;
          }
          if (key.startsWith("Entreprise") || key === "Free" || key === "Pay as you go") {
            enterpriseSubs[key] = sortedValue;
          }
        }
  
        setSoloSubscriptions(soloSubs);
        setEnterpriseSubscriptions(enterpriseSubs);
        const allSubscriptions = [];
        console.log('userPend1:', userPend);
        if(userPend!=null)
{
  let SUB=null
  if(userPend.userRoleID===2){

    SUB=enterpriseSubs
  }else{
    SUB=soloSubs

  }
      
        Object.entries(SUB).forEach(([typeName, features]) => {
          let priceType = "";
          let idProduct = "";
          features.forEach((feature) => {

            priceType = feature.type_monthly_price;
            idProduct = feature.type_id;
          });
  
          allSubscriptions.push({ typeName, features, priceType, idProduct });
        });
  
        setAllSubscriptions(allSubscriptions);
  
        const featureSelectedInitial = featureData.reduce((acc, feat) => {
          acc[feat.name] = "";
          return acc;
        }, {});
  
        setFeatureselected(featureSelectedInitial);}
      } catch (error) {
        console.error("Error fetching features:", error);
      }
    };
  
    showFeatures();
  }, [userPend]);


  const [subType, setSubType] = useState(null);

  const handlePurchaseType = (sub) => {
    setShowPurchaseForm(true)
    setSubType(sub)

  }
  const [showPurchaseForm, setShowPurchaseForm] = useState(false);
const [subscriptionPrice, setSubscriptionPrice] = useState('');
const [comment, setComment] = useState('');
const [isLoading, setIsLoading] = useState(false);

const handlePurchaseSubmit = async() => {

  const subscriptionData = {
    userId: userPend.uid,
    amount: subscriptionPrice,
    note: subType.idProduct,
  };
  setIsLoading(true); 
    const subscriptionDone = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/Subscription/createSubscription`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(subscriptionData),
    });

    if (!subscriptionDone.ok) {
      throw new Error('Failed to create subscription');
    }

    const dataSub = await subscriptionDone.json();
    const storedLang = localStorage.getItem("selectedLang");
    const parsedLang = JSON.parse(storedLang);

    const invoiceData = {
      sub_id: dataSub.sub_id,
      customer_id: dataSub.customer_id,
      email: userPend.email,
      received_amount: subscriptionPrice,
      amount: subscriptionPrice, 
      payment_status:true,
      firstname: userPend.FirstName,
      currency:selectedCurrency,
      lang: (parsedLang && parsedLang.language) ? parsedLang.language : "Français" 
    };

    const invoiceDone = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/invoice/createinvoice`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(invoiceData),
    });

    if (!invoiceDone.ok) {
      throw new Error('Failed to create invoice');
    }
    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/accept/${userPend.uid}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        comment: comment,
        lang: (parsedLang && parsedLang.language) ? parsedLang.language : "Français" 
      }),
    })
      .then(
        
        swal("", lang.menu.AcceptedusersSuccess, "success").then(() => {
          window.location.reload();
        })
      )
      .catch((error) => {
        setIsLoading(false)
        console.error("Error accepted user:", error);
        swal("", lang.menu.AccepedusersError, "error");
      });
  setShowPurchaseForm(false); 
  setShowModal(false)
};

const handlePriceChange = (e) => {
  let value = e.target.value;

  const regex = /^\d*\.?\d{0,2}$/;

  if (regex.test(value)) {
    if (value !== '') {
      value = parseFloat(value).toFixed(2);
    }
    console.log("value .",value)

    setSubscriptionPrice(value);
  }
};
  return (


    <> {adminDash ? (  

    <>
      <Modal className="modal fade" show={showCompany} onHide={setShowCompany}>
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">{lang.menu.company}</h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowCompany(false)}
                  data-dismiss="modal"
                >
                  <span></span>
                </button>
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        {lang.menu.companyName}
                      </label>
                      <input
                        type="text"
                        id="companyName"
                        maxLength={16}
                        className="form-control"
                        value={companyDetails.companyName}
                        disabled
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        {lang.menu.numberOfUsers}
                      </label>
                      <input
                        type="number" 
                        id="numberOfUsers"
                        className="form-control"
                        value={companyDetails.numberOfUsers}
                        min={0}
                        disabled
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        {lang.menu.Comments}
                      </label>
                      <textarea
                        id="comments"
                        maxLength={255}
                        style={{ height: "150px" }}
                        className="form-control"
                        value={companyDetails.comments}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Card>
        <Card.Body>
          <div className="custom-tab-1 my-tab-size">
            <Tab.Container defaultActiveKey={activeTab ? activeTab : "users"}>
              <Nav as="ul" className="nav-tabs">
                <Nav.Item as="li">
                  <Nav.Link eventKey="users">
                    <i className={`la la-users me-2`} />
                    {lang.menu.Users}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="Pendingusers">
                    <i className={`la la-user me-2`} />
                    {lang.menu.Pendingusers}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="Rejectedusers">
                    <i className={`la la-user-times me-2`} />
                    {lang.menu.Rejectedusers}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="Createdusers">
                    <i className={`la la-plus-circle me-2`} />
                    {lang.menu.Createdusers}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="companies">
                    <i className={`la la-building me-2`} />
                    {lang.menu.companies}
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content className="pt-4">
                <Tab.Pane eventKey="users">
                  <div className="w-100 table-responsive">
                    <div id="example_wrapper" className="dataTables_wrapper">
                      <div className="d-flex align-items-center">
                        <label>
                          <h4 className="me-2 mt-3">{lang.menu.SearchT}</h4>
                        </label>
                        <input
                          className="input-search form-control"
                          value={filterText || ""}
                          onChange={(e) => setFilterText(e.target.value)}
                          style={{ width: "20%" }}
                        />
                        <select
                          className="ml-5 form-select input-search form-control"
                          value={selectedFilterField}
                          onChange={(e) =>
                            setSelectedFilterField(e.target.value)
                          }
                          style={{ width: "10%", marginLeft: "10px" }}
                        >
                          {Object.keys(fieldsToCheck).map((key) => (
                            <option key={key} value={key}>
                              {fieldsToCheck[key]}
                            </option>
                          ))}
                        </select>
                        <button
                          type="submit"
                          className="btn btn-primary my-synchro-button ms-auto"
                          onClick={handlesynchro}
                        >
                          {lang.menu.Synchronize}
                        </button>
                      </div>

                      <form>
                        <table id="example" className="display w-100 dataTable">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{lang.menu.firstName}</th>
                              <th>{lang.menu.lastName}</th>
                              <th>{lang.menu.email}</th>
                              {/*<th>{lang.menu.EmailVerification}</th>
															<th>{lang.menu.EmailSignature}</th>
															<th>{lang.menu.Password}</th>
															<th>{lang.menu.Country}</th>
															<th>{lang.menu.MyJobtitle}</th>
															<th>{lang.menu.PhoneNumber}</th>
															<th>{lang.menu.language}</th>*/}
                              <th>{lang.menu.Disabled}</th>
                              <th>{lang.menu.company}</th>
                              <th>{lang.menu.role}</th>
                              <th>{lang.menu.lastSignInTime}</th>
                              <th>{lang.menu.creationTime}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredUsers.map((content, index) => (
                              <tr key={index}>
                                {editContentId === content.uid ? (
                                  <Editable
                                    editFormData={editFormData}
                                    userRoles={userRoles}
                                    handleEditFormChange={handleEditFormChange}
                                    handleSubmitClick={handleSubmitClick}
                                    handleCancelClick={handleCancelClick}
                                  />
                                ) : (
                                  <>
                                    <td>
                                      {content.Avatar ? (
                                        <img
                                          className="rounded-circle"
                                          width="43"
                                          src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/avatars/${content.Avatar}`}
                                          alt=""
                                        />
                                      ) : (
                                        <div
                                          className={`empty-avatar rounded-circle ${
                                            [
                                              "empty-avatar-red",
                                              "empty-avatar-green",
                                              "empty-avatar-pimary",
                                              "empty-avatar-yellow",
                                              "empty-avatar-purple",
                                            ][
                                              Math.floor(
                                                Math.random() *
                                                  avatarClasses.length
                                              )
                                            ]
                                          }`}
                                        >
                                          {content.FirstName.charAt(0)}
                                          {content.LastName.charAt(0)}
                                        </div>
                                      )}
                                    </td>
                                    <td>{content.FirstName}</td>
                                    <td>{content.LastName}</td>
                                    <td>
                                      <strong>{content.email}</strong>
                                    </td>
                                    {/*<td>
																				{content.emailVerified === "True" ? (
																					<Badge variant="success">{content.emailVerified}</Badge>
																				) : (
																					<Badge variant="danger">{content.emailVerified}</Badge>
																				)}
																			</td>
																			 <td>{content.EmailSignature}</td> 
																			 <td>{content.Password}</td>
																			 <td><strong>{content.Country}</strong></td>
																			<td>{content.JobTitle}</td>
																			<td><strong>{content.PhoneNumber}</strong></td>
																			<td>{content.Language}</td> */}
                                    <td>
                                      {content.disabled === "True" ? (
                                        <Badge variant="success">
                                          {content.disabled}
                                        </Badge>
                                      ) : (
                                        <Badge variant="danger">
                                          {content.disabled}
                                        </Badge>
                                      )}
                                    </td>
                                    <td>{content.companyName}</td>
                                    <td>{content.userRole}</td>
                                    <td>
                                      {content.metadata
                                        ? JSON.parse(content.metadata)
                                            .lastSignInTime
                                        : ""}
                                    </td>
                                    <td>
                                      {content.metadata
                                        ? JSON.parse(content.metadata)
                                            .creationTime
                                        : ""}
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          className="btn btn-secondary shadow btn-xs sharp me-2"
                                          to=""
                                          onClick={(event) =>
                                            handleEditClick(event, content)
                                          }
                                        >
                                          <i className="fas fa-pen"></i>
                                        </Link>
                                        <Link
                                          className="btn btn-danger shadow btn-xs sharp me-2"
                                          to=""
                                          onClick={(event) =>
                                            handleDeleteUser(event, content)
                                          }
                                        >
                                          <i className="fa fa-trash"></i>
                                        </Link>
                                        {content.companyName ? (
                                          <Link
                                            className="btn btn-primary shadow btn-xs sharp me-2"
                                            to=""
                                            onClick={(event) =>
                                              handleShowCompany(event, content)
                                            }
                                          >
                                            <i className="fa fa-eye"></i>
                                          </Link>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </form>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="Pendingusers">
                  <div className="w-100 table-responsive">
                    <div id="example_wrapper" className="dataTables_wrapper">
                      <div className="d-flex align-items-center">
                        <label>
                          <h4 className="me-2 mt-3">{lang.menu.SearchT}</h4>
                        </label>
                        <input
                          className="input-search form-control"
                          value={filterTextPending || ""}
                          onChange={(e) => setFilterTextPending(e.target.value)}
                          style={{ width: "20%" }}
                        />
                        <select
                          className="ml-5 form-select input-search form-control"
                          value={selectedFilterFieldPending}
                          onChange={(e) =>
                            setSelectedFilterFieldPending(e.target.value)
                          }
                          style={{ width: "10%", marginLeft: "10px" }}
                        >
                          {Object.keys(fieldsToCheckPending).map((key) => (
                            <option key={key} value={key}>
                              {fieldsToCheckPending[key]}
                            </option>
                          ))}
                        </select>
                      </div>

                      <form>
                        <table id="example" className="display w-100 dataTable">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{lang.menu.firstName}</th>
                              <th>{lang.menu.lastName}</th>
                              <th>{lang.menu.email}</th>
                              <th>{lang.menu.Disabled}</th>
                              <th>{lang.menu.company}</th>
                              <th>{lang.menu.role}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredUsersPending.map((content, index) => (
                              <tr key={index}>
                                {editContentId === content.uid ? (
                                  <Editable
                                    editFormData={editFormData}
                                    roles={userRoles}
                                    handleEditFormChange={handleEditFormChange}
                                    handleSubmitClick={handleSubmitClick}
                                    handleCancelClick={handleCancelClick}
                                  />
                                ) : (
                                  <>
                                    <td>
                                      {content.Avatar ? (
                                        <img
                                          className="rounded-circle"
                                          width="43"
                                          src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/avatars/${content.Avatar}`}
                                          alt=""
                                        />
                                      ) : (
                                        <div
                                          className={`empty-avatar rounded-circle ${
                                            [
                                              "empty-avatar-red",
                                              "empty-avatar-green",
                                              "empty-avatar-pimary",
                                              "empty-avatar-yellow",
                                              "empty-avatar-purple",
                                            ][
                                              Math.floor(
                                                Math.random() *
                                                  avatarClasses.length
                                              )
                                            ]
                                          }`}
                                        >
                                          {content.FirstName.charAt(0)}
                                          {content.LastName.charAt(0)}
                                        </div>
                                      )}
                                    </td>
                                    <td>{content.FirstName}</td>
                                    <td>{content.LastName}</td>
                                    <td>
                                      <strong>{content.email}</strong>
                                    </td>
                                    <td>
                                      {content.disabled === "True" ? (
                                        <Badge variant="success">
                                          {content.disabled}
                                        </Badge>
                                      ) : (
                                        <Badge variant="danger">
                                          {content.disabled}
                                        </Badge>
                                      )}
                                    </td>
                                    <td>{content.companyName}</td>
                                    <td>{content.userRole}</td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          className="btn btn-primary shadow btn-xs sharp me-2"
                                          to=""
                                          onClick={(event) =>
                                            handleAcceptUserBefore(event, content)
                                          }
                                        >
                                          <i className="fa fa-plus"></i>
                                        </Link>
                                        <Link
                                          className="btn btn-danger shadow btn-xs sharp me-2"
                                          to=""
                                          onClick={(event) =>
                                            handleRejectUser(event, content)
                                          }
                                        >
                                          <i className="fa fa-times"></i>
                                        </Link>
                                        {content.companyName ? (
                                          <Link
                                            className="btn btn-primary shadow btn-xs sharp me-2"
                                            to=""
                                            onClick={(event) =>
                                              handleShowCompany(event, content)
                                            }
                                          >
                                            <i className="fa fa-eye"></i>
                                          </Link>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </form>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="Rejectedusers">
                  <div className="w-100 table-responsive">
                    <div id="example_wrapper" className="dataTables_wrapper">
                      <div className="d-flex align-items-center">
                        <label>
                          <h4 className="me-2 mt-3">{lang.menu.SearchT}</h4>
                        </label>
                        <input
                          className="input-search form-control"
                          value={filterTextRejected || ""}
                          onChange={(e) =>
                            setFilterTextRejected(e.target.value)
                          }
                          style={{ width: "20%" }}
                        />
                        <select
                          className="ml-5 form-select input-search form-control"
                          value={selectedFilterFieldRejected}
                          onChange={(e) =>
                            setSelectedFilterFieldRejected(e.target.value)
                          }
                          style={{ width: "10%", marginLeft: "10px" }}
                        >
                          {Object.keys(fieldsToCheckRejected).map((key) => (
                            <option key={key} value={key}>
                              {fieldsToCheckRejected[key]}
                            </option>
                          ))}
                        </select>
                      </div>

                      <form>
                        <table id="example" className="display w-100 dataTable">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{lang.menu.firstName}</th>
                              <th>{lang.menu.lastName}</th>
                              <th>{lang.menu.email}</th>
                              <th>{lang.menu.Disabled}</th>
                              <th>{lang.menu.company}</th>
                              <th>{lang.menu.role}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredUsersRejected.map((content, index) => (
                              <tr key={index}>
                                {editContentId === content.uid ? (
                                  <Editable
                                    editFormData={editFormData}
                                    userRoles={userRoles}
                                    handleEditFormChange={handleEditFormChange}
                                    handleSubmitClick={handleSubmitClick}
                                    handleCancelClick={handleCancelClick}
                                  />
                                ) : (
                                  <>
                                    <td>
                                      {content.Avatar ? (
                                        <img
                                          className="rounded-circle"
                                          width="43"
                                          src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/avatars/${content.Avatar}`}
                                          alt=""
                                        />
                                      ) : (
                                        <div
                                          className={`empty-avatar rounded-circle ${
                                            [
                                              "empty-avatar-red",
                                              "empty-avatar-green",
                                              "empty-avatar-pimary",
                                              "empty-avatar-yellow",
                                              "empty-avatar-purple",
                                            ][
                                              Math.floor(
                                                Math.random() *
                                                  avatarClasses.length
                                              )
                                            ]
                                          }`}
                                        >
                                          {content.FirstName.charAt(0)}
                                          {content.LastName.charAt(0)}
                                        </div>
                                      )}
                                    </td>{" "}
                                    <td>{content.FirstName}</td>
                                    <td>{content.LastName}</td>
                                    <td>
                                      <strong>{content.email}</strong>
                                    </td>
                                    <td>
                                      {content.disabled === "True" ? (
                                        <Badge variant="success">
                                          {content.disabled}
                                        </Badge>
                                      ) : (
                                        <Badge variant="danger">
                                          {content.disabled}
                                        </Badge>
                                      )}
                                    </td>
                                    <td>{content.companyName}</td>
                                    <td>{content.userRole}</td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          className="btn btn-primary shadow btn-xs sharp me-2"
                                          to=""
                                          onClick={(event) =>
                                            handleAcceptUserBefore(event, content)
                                          }
                                        >
                                          <i className="fa fa-plus"></i>
                                        </Link>
                                        {content.companyName ? (
                                          <Link
                                            className="btn btn-primary shadow btn-xs sharp me-2"
                                            to=""
                                            onClick={(event) =>
                                              handleShowCompany(event, content)
                                            }
                                          >
                                            <i className="fa fa-eye"></i>
                                          </Link>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </form>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="Createdusers">
                  <div className="w-100 table-responsive">
                    <div id="example_wrapper" className="dataTables_wrapper">
                      <div className="d-flex align-items-center">
                        <label>
                          <h4 className="me-2 mt-3">{lang.menu.SearchT}</h4>
                        </label>
                        <input
                          className="input-search form-control"
                          value={filterTextCreated || ""}
                          onChange={(e) => setFilterTextCreated(e.target.value)}
                          style={{ width: "20%" }}
                        />
                        <select
                          className="ml-5 form-select input-search form-control"
                          value={selectedFilterFieldCreated}
                          onChange={(e) =>
                            setSelectedFilterFieldCreated(e.target.value)
                          }
                          style={{ width: "10%", marginLeft: "10px" }}
                        >
                          {Object.keys(fieldsToCheckCreated).map((key) => (
                            <option key={key} value={key}>
                              {fieldsToCheckCreated[key]}
                            </option>
                          ))}
                        </select>
                      </div>

                      <form>
                        <table id="example" className="display w-100 dataTable">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{lang.menu.firstName}</th>
                              <th>{lang.menu.lastName}</th>
                              <th>{lang.menu.email}</th>
                              <th>{lang.menu.EmailVerification}</th>
                              <th>{lang.menu.Disabled}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredUsersCreated.map((content, index) => (
                              <tr key={index}>
                                {editContentId === content.uid ? (
                                  <Editable
                                    editFormData={editFormData}
                                    userRoles={userRoles}
                                    handleEditFormChange={handleEditFormChange}
                                    handleSubmitClick={handleSubmitClick}
                                    handleCancelClick={handleCancelClick}
                                  />
                                ) : (
                                  <>
                                    <td>
                                      {content.Avatar ? (
                                        <img
                                          className="rounded-circle"
                                          width="43"
                                          src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/avatars/${content.Avatar}`}
                                          alt=""
                                        />
                                      ) : (
                                        <div
                                          className={`empty-avatar rounded-circle ${
                                            [
                                              "empty-avatar-red",
                                              "empty-avatar-green",
                                              "empty-avatar-pimary",
                                              "empty-avatar-yellow",
                                              "empty-avatar-purple",
                                            ][
                                              Math.floor(
                                                Math.random() *
                                                  avatarClasses.length
                                              )
                                            ]
                                          }`}
                                        >
                                          {content.FirstName.charAt(0)}
                                          {content.LastName.charAt(0)}
                                        </div>
                                      )}
                                    </td>
                                    <td>{content.FirstName}</td>
                                    <td>{content.LastName}</td>
                                    <td>
                                      <strong>{content.email}</strong>
                                    </td>
                                    <td>
                                      {content.emailVerified === "True" ? (
                                        <Badge variant="success">
                                          {content.emailVerified}
                                        </Badge>
                                      ) : (
                                        <Badge variant="danger">
                                          {content.emailVerified}
                                        </Badge>
                                      )}
                                    </td>
                                    <td>
                                      {content.disabled === "True" ? (
                                        <Badge variant="success">
                                          {content.disabled}
                                        </Badge>
                                      ) : (
                                        <Badge variant="danger">
                                          {content.disabled}
                                        </Badge>
                                      )}
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          className="btn btn-danger shadow btn-xs sharp me-2"
                                          to=""
                                          onClick={(event) =>
                                            handleDeleteUser(event, content)
                                          }
                                        >
                                          <i className="fa fa-trash"></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </form>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="companies">
                  <div className="w-100 table-responsive">
                    <div id="example_wrapper" className="dataTables_wrapper">
                      <div className="d-flex align-items-center">
                        <label>
                          <h4 className="me-2 mt-3">{lang.menu.SearchT}</h4>
                        </label>
                        <input
                          className="input-search form-control"
                          value={filterTextCompany || ""}
                          onChange={(e) => setFilterTextCompany(e.target.value)}
                          style={{ width: "20%" }}
                        />
                        <select
                          className="ml-5 form-select input-search form-control"
                          value={selectedFilterFieldCompany}
                          onChange={(e) =>
                            setSelectedFilterFieldCompany(e.target.value)
                          }
                          style={{ width: "10%", marginLeft: "10px" }}
                        >
                          {Object.keys(fieldsToCheckCompany).map((key) => (
                            <option key={key} value={key}>
                              {fieldsToCheckCompany[key]}
                            </option>
                          ))}
                        </select>
                      </div>

                      <form>
                        <table id="example" className="display w-100 dataTable">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{lang.menu.companyName}</th>
                              <th>{lang.menu.founder}</th>
                              <th>{lang.menu.numberOfUsers}</th>
                              <th>{lang.menu.Comments}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredUsersCompany.map((content, index) => (
                              <tr key={index}>
                                {editContentCompanyId === content.companyid ? (
                                  <CompanyEditable
                                    editCompanyData={editCompanyData}
                                    handleEditFormChange={
                                      handleEditFormCompanyChange
                                    }
                                    handleSubmitClick={handleSubmitCompanyClick}
                                    handleCancelClick={handleCancelCompanyClick}
                                  />
                                ) : (
                                  <>
                                    <td>{content.companyid}</td>
                                    <td>{content.companyName}</td>
                                    <td>{content.founder}</td>
                                    <td>
                                      <strong>{content.numberOfUsers}</strong>
                                    </td>
                                    <td>{content.comments}</td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          className="btn btn-secondary shadow btn-xs sharp me-2"
                                          to=""
                                          onClick={(event) =>
                                            handleEditCompanyClick(
                                              event,
                                              content
                                            )
                                          }
                                        >
                                          <i className="fas fa-pen"></i>
                                        </Link>
                                        <Link
                                          className="btn btn-danger shadow btn-xs sharp me-2"
                                          to=""
                                          onClick={(event) =>
                                            handleDeleteCompany(event, content)
                                          }
                                        >
                                          <i className="fa fa-trash"></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </form>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
      <Modal show={showModal} onHide={() => setShowModal(false)} dialogClassName="modal-100w">
  <Modal.Header>
    <Modal.Title>{lang.menu.Offress}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
     
        <Row>
          {allSubscriptions.map((subscription) => {
            const cardClass = 'pricing-box h-100 shadow-sm';
            return (
              <Col key={subscription.typeName} md={4}>
                <Card className={cardClass}>
                  <Card.Body className="p-4 px-lg-5">
                    <div className="pricing-name mt-4 pt-2 text-center">
                      <h4 className="fs-18 text-primary">
                        <div className="pricing-icon rounded-circle icons-md mx-auto mb-4">
                          <Icon icon="uim-telegram-alt" className="text-primary" />
                          {subscription.typeName}
                        </div>
                      </h4>
                    
                    </div>
                 
                    <ul className="list-unstyled mt-3 mb-4">
                      {subscription.features.map((feature) => (
                        <li key={feature.feature_id} className="my-2">
                          <strong>{feature.feature_name}:</strong> {feature.feature_quota_max}
                        </li>
                      ))}
                    </ul>
                    <Button
  className="btn btn-primary btn-block"
  onClick={() => handlePurchaseType(subscription)}
>
  {lang.menu.purchaseNow} <FaArrowRight />
</Button>


                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModal(false)}>
      {lang.menu.retour}
    </Button>
  </Modal.Footer>
</Modal>
<Modal show={showPurchaseForm} onHide={() => setShowPurchaseForm(false)}>
  <Modal.Header>
    <Modal.Title>{lang.menu.purchaseNow}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
      <Form>
      <Form.Label>{lang.menu.amount}</Form.Label>
      <Row>
          <Col xs={9}>
            {/* Largeur plus grande pour l'entrée de prix */}
            <Form.Group controlId="subscriptionPrice">
              <Form.Control
                type="text"
                placeholder="00.00"
                value={subscriptionPrice}
                onChange={handlePriceChange}
              />
            </Form.Group>
          </Col>
          <Col xs={3}>
            {/* Largeur plus petite pour la sélection de la devise */}
            <Form.Group controlId="currency">
              <Form.Control
                as="select"
                value={selectedCurrency}
                onChange={(e) => setSelectedCurrency(e.target.value)}
              >
                <option value="GBP">GBP</option>
                <option value="TND">TND</option>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>


 
        <Form.Group controlId="comment">
          <Form.Label>      {lang.menu.comment}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Form.Group>

      </Form>
    </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowPurchaseForm(false)}>
      {lang.menu.cancel}
    </Button>
    <Button variant="primary" onClick={handlePurchaseSubmit}>
    {isLoading ? <LuLoader /> : lang.menu.submit}


    </Button>
  </Modal.Footer>
</Modal>

    </>) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
  );
};
export default Admin_dashboard;
