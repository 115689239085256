import React, { useEffect, useState } from "react";
import axios from "axios";
import { Radar } from "react-chartjs-2";
import translations from "../../components/AppsMenu/Candidate/mulitlingue";
import "./personalityTestResult.css";
import Switch from "../../layouts/nav/Switch";

function TestView() {
  const token=localStorage.getItem("token");
  const [isSelected, setIsSelected] = useState(false);
  const [userConnect, setuserConnect] = useState("");

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  useEffect(async() => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
        const data = await responseC.json();
        console.log("data :",data)
        setuserConnect(data[0])
        const aiPer= data[0].PersonnalityAI
        if (aiPer === 0) {
          setIsSelected(false);
        } else if (aiPer === 1) {
          setIsSelected(true);
        }
      
  }, []);
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState("");
  const [traitScores, setTraitScores] = useState({});
  const [resultDescription, setResultDescription] = useState("");
  const [testName, setTestName] = useState("");
  const [teststId, setTeststId] = useState([]);
  const [selectedTest, setSelectedTest] = useState("");
  const [tests, setTests] = useState([]);
  const [jobs, setjobs] = useState([]);
  const [selectedjobId, setSelectedjobId] = useState("");



  useEffect(() => {
    console.log("userConnect :",userConnect)
    //super admin
    if (userConnect.userRole===1) {

      axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/getCandidatesEmail`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
      .then((response) => {
        console.log("response.data :",response.data)
        setCandidates(response.data);
      })
      .catch((error) => {
        console.error("Error fetching candidate names:", error);
      });
    }
    //Recruiter solo
else if(userConnect.userRole===3 && userConnect.companyID===1){

  axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/getCandidatesEmailForRecruiterSolo/${userConnect.uid}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
      .then((response) => {
        console.log("response.data :",response.data)
        setCandidates(response.data);
      })
      .catch((error) => {
        console.error("Error fetching candidate names:", error);
      });

}
//company
else if(userConnect.companyID!=1){
  axios
  .get(`${process.env.REACT_APP_APP_DOMAIN}/api/getCandidatesEmailForCompany/${userConnect.companyID}`, 
    {
      headers: {
      Authorization: `Bearer ${token}`,
      }})
  .then((response) => {
    console.log("response.data :",response.data)
    setCandidates(response.data);
  })
  .catch((error) => {
    console.error("Error fetching candidate names:", error);
  });
}
  
  }, [userConnect]);
  const handleCandidateChange = (event) => {
    setSelectedTest(""); // Reset the test selection
    setSelectedjobId(""); // Reset the job selection
    setSelectedCandidate(event.target.value);
    setTraitScores({});
    setResultDescription("");
    setTestName("");
  };
  
  const handleTestChange = (event) => {
    const selectedTestId = event.target.value;
    console.log('selectedTestId:', selectedTestId); 
    const selectedTest = teststId.find(test => test.test_id === parseInt(selectedTestId));
    console.log('teststId:', teststId); 
    console.log('selectedTest:', selectedTest);
  
    if (selectedTest) {
      setSelectedTest(selectedTest.test_id);
      setSelectedjobId(selectedTest.jobRoleId);
    } else {
      console.warn('Test non trouvé pour test_id:', selectedTestId);
    }
  
    setTraitScores({});
    setResultDescription("");
    setTestName("");
  };

  useEffect(() => {
    if (selectedCandidate && selectedTest) {
      
      axios
        .get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/test-view/${selectedCandidate}/${selectedTest}/${selectedjobId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        )
        .then(async(response) => {
          console.log("response.data :",response.data)
          const { scores, testName } = response.data;
          const onlyScores = scores.map(scoreObject => {
            switch (scoreObject.score) {
                case 1:
                    return -2;
                case 2:
                    return -1;
                case 3:
                    return 0;
                case 4:
                    return 1;
                case 5:
                    return 2;
                default:
                    return scoreObject.score; 
            }
        });
console.log(onlyScores);
          const updatedTraitScores = {};
          if(isSelected){

            console.log("with AI")
              if(selectedTest===1){
                console.log("16P")
                const response = await axios.post(
                  `${process.env.REACT_APP_APP_DOMAIN}/flask/personalities/predict/16p`, 
                  {
                      features: onlyScores
                  },
                  {
                      headers: {
                          Authorization: `Bearer ${token}`,
                      }
                  }
              );
              console.log("response.data :",response.data)
              
              const resultDescription = (
              <div className="result-description">
                <h3> {selectedTest === 1 ? "16 Personnalities" : selectedTest === 2 ? "BIG 5" : "UKNOWN"}</h3>
                  <p className="personality-type">
                    <strong>{lang.menu.persoType}:</strong>
                    {` (${response.data.personality_type.code})`}
                    {` ${response.data.personality_type.overview}`}
                    </p>
        
                    
                    <p className="acronym">
  <strong>{lang.menu.Strengths} :</strong>
  <ul>
    {response.data.strengths.map((item, index) => (
      <li key={index}>
        {item.strength}: {item.description}
      </li>
    ))}
  </ul>
</p>

<p className="acronym">
  <strong>{lang.menu.Weaknesses} :</strong>
  <ul>
    {response.data.weaknesses.map((item, index) => (
      <li key={index}>
        {item.weakness}: {item.description}
      </li>
    ))}
  </ul>
</p>
     
                </div>
              )
              setResultDescription(resultDescription)

              }else if(selectedTest===2){
                console.log("BIG5")
                const response = await axios.post(
                  `${process.env.REACT_APP_APP_DOMAIN}/flask/personalities/predict/ocean`, 
                  {
                      features: onlyScores
                  },
                  {
                      headers: {
                          Authorization: `Bearer ${token}`,
                      }
                  }
              );
              const dataAI = {
                labels: [
                  lang.menu.Neuroticism, 
                  lang.menu.Agreeableness, 
                  lang.menu.Extraversion, 
                  lang.menu.Conscientiousness, 
                  lang.menu.Openness
                ],
                datasets: [{
                  label: "BIG 5",
                  data: [
                    response.data.Neuroticism, 
                    response.data.Agreeableness, 
                    response.data.Extraversion, 
                    response.data.Conscientiousness, 
                    response.data.Openness
                  ],
                  backgroundColor: 'rgba(34, 202, 236, 0.2)',
                  borderColor: 'rgba(34, 202, 236, 1)',
                  borderWidth: 2,
                  pointBackgroundColor: 'rgba(34, 202, 236, 1)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(34, 202, 236, 1)'
                }]
              };
            
              const optionsAI = {
                scale: {
                  ticks: { beginAtZero: true, min: 0, max: 100 },
                  pointLabels: { fontSize: 14 }
                },
                responsive: true,
                maintainAspectRatio: false
              };
              console.log("response.data :",response.data)
const resultDescription = (
  <div className="result-description">
                <h3> {selectedTest === 1 ? "16 Personnalities" : selectedTest === 2 ? "BIG 5" : "UKNOWN"}</h3>
                <p className="acronym"> 
    <strong>{lang.menu.Neuroticism} :</strong> {`${response.data.Neuroticism}`}
    <br />
    {response.data.Neuroticism < 40 
      ? <em>{lang.menu.Neuroticism40}</em>
      : response.data.Neuroticism > 60 
        ? <em>{lang.menu.Neuroticism60}</em>
        : <em>{lang.menu.Neuroticism50}</em>}
  </p>

  <p className="acronym"> 
    <strong>{lang.menu.Agreeableness} :</strong> {`${response.data.Agreeableness}`}
    <br />
    {response.data.Agreeableness < 40 
      ? <em>{lang.menu.Agreeableness40}</em>
      : response.data.Agreeableness > 60 
        ? <em>{lang.menu.Agreeableness60}</em>
        : <em>{lang.menu.Agreeableness50}</em>}
  </p>

  <p className="acronym"> 
    <strong>{lang.menu.Extraversion} :</strong> {`${response.data.Extraversion}`}
    <br />
    {response.data.Extraversion < 40 
      ? <em>{lang.menu.Extraversion40}</em>
      : response.data.Extraversion > 60 
        ? <em>{lang.menu.Extraversion60}</em>
        : <em>{lang.menu.Extraversion50}</em>}
  </p>

  <p className="acronym"> 
    <strong>{lang.menu.Conscientiousness} :</strong> {`${response.data.Conscientiousness}`}
    <br />
    {response.data.Conscientiousness < 40 
      ? <em>{lang.menu.Conscientiousness40}</em>
      : response.data.Conscientiousness > 60 
        ? <em>{lang.menu.Conscientiousness60}</em>
        : <em>{lang.menu.Conscientiousness50}</em>}
  </p>

  <p className="acronym"> 
    <strong>{lang.menu.Openness} :</strong> {`${response.data.Openness}`}
    <br />
    {response.data.Openness < 40 
      ? <em>{lang.menu.Conscientiousness40}</em>
      : response.data.Openness > 60 
        ? <em>{lang.menu.Conscientiousness60}</em>
        : <em>{lang.menu.Conscientiousness50}</em>}
  </p>
  <div style={{ width: '500px', height: '500px', margin: '0 auto' }}>
        <Radar data={dataAI} options={optionsAI} />
      </div>
</div>

              )
              setResultDescription(resultDescription)
              }

          }
          else{
          let maxScore = 0;
          scores.forEach((s) => {
            const { trait, score } = s;
            // Update the maximum score if needed
            if (score > maxScore) {
              maxScore = score;
            }
            // Cap the score at a maximum of 10 and scale it
            updatedTraitScores[trait] = Math.min(score, 10);
          });

          // Calculate the scaling factor based on the maximum score
          const scalingFactor = maxScore > 10 ? 10 / maxScore : 1;

          // Apply the scaling factor to all scores
          for (const trait in updatedTraitScores) {
            updatedTraitScores[trait] *= scalingFactor;
          }

          setTestName(testName);
          const traits = Object.keys(updatedTraitScores);

          traits.sort((a, b) => updatedTraitScores[b] - updatedTraitScores[a]);

          const highestSensingIntuition =
            updatedTraitScores["Sensing"] > updatedTraitScores["Intuition"]
              ? "Sensing"
              : "Intuition";
          const highestThinkingFeeling =
            updatedTraitScores["Thinking"] > updatedTraitScores["Feeling"]
              ? "Thinking"
              : "Feeling";
          const highestJudgingPerceiving =
            updatedTraitScores["Judging"] > updatedTraitScores["Perceiving"]
              ? "Judging"
              : "Perceiving";
          const highestExtraversionIntroversion =
            updatedTraitScores["Extraversion"] >
            updatedTraitScores["Introversion"]
              ? "Extraversion"
              : "Introversion";

          const acronym16P = [
            highestSensingIntuition,
            highestThinkingFeeling,
            highestJudgingPerceiving,
            highestExtraversionIntroversion,
          ]
            .sort((a, b) => updatedTraitScores[b] - updatedTraitScores[a])
            .map((trait) => trait[0].toUpperCase())
            .join("");

          const personalityType16P = [
            highestSensingIntuition,
            highestThinkingFeeling,
            highestJudgingPerceiving,
            highestExtraversionIntroversion,
          ]
            .sort((a, b) => updatedTraitScores[b] - updatedTraitScores[a])
            .join(", ");

          const scoreCandidateDescription = traits
            .sort((a, b) => updatedTraitScores[b] - updatedTraitScores[a])
            .map((trait) => `${trait} (${updatedTraitScores[trait]})`)
            .join(", ");

          const isBigFiveTest =
            traits.includes("Openness") &&
            traits.includes("Neuroticism") &&
            traits.includes("Conscientiousness") &&
            traits.includes("Agreeableness") &&
            traits.includes("Extraversion");

          const is16P =
            traits.includes("Sensing") &&
            traits.includes("Intuition") &&
            traits.includes("Thinking") &&
            traits.includes("Feeling") &&
            traits.includes("Judging") &&
            traits.includes("Perceiving") &&
            traits.includes("Extraversion") &&
            traits.includes("Introversion");

          const description = traits
            .map((trait) => `${trait} (${updatedTraitScores[trait]})`)
            .join(", ");
          const acronym = traits
            .map((trait) => trait[0].toUpperCase())
            .join(" ");
          const description2 = traits.join(", ");

          let selectedTraitScores = {};

          if (is16P) {
            [
              highestSensingIntuition,
              highestThinkingFeeling,
              highestJudgingPerceiving,
              highestExtraversionIntroversion,
            ].forEach((trait) => {
              selectedTraitScores[trait] = updatedTraitScores[trait];
            });
          }

          const resultDescription = is16P ? (
            <div className="result-description">
                <h3> {selectedTest === 1 ? "16 Personnalities" : selectedTest === 2 ? "BIG 5" : "UKNOWN"}</h3>
                <p className="personality-type">{`Score Candidate  : ${scoreCandidateDescription}.`}</p>
              <p className="personality-type">{`Personality Type: ${personalityType16P}`}</p>
              <p className="acronym">{`${acronym16P}`}</p>
            </div>
          ) : (
            <div className="result-description">
       <h3> {selectedTest === 1 ? "16 Personnalities" : selectedTest === 2 ? "BIG 5" : "UKNOWN"}</h3>

              <p>{`Score Candidate : ${description}.`}</p>
              <p className="personality-type">{`Personality Type: ${description2}`}</p>
              {isBigFiveTest && <p className="acronym">{` ( ${acronym} ).`}</p>}
              <p className="dominant-trait">{`The most dominant trait for this candidate is ${
                traits[0]
              } (${updatedTraitScores[traits[0]]}).`}</p>
            </div>
          );
  if (is16P) {
            setTraitScores(selectedTraitScores);
          } else setTraitScores(updatedTraitScores);

          setResultDescription(resultDescription); 
       
            console.log("without AI")

          }
 })
        .catch((error) => {
          console.error("Error fetching candidate scores:", error);
        });
      }
  }, [selectedCandidate,selectedTest,isSelected]);
  useEffect(() => {
  
    axios
    .get(
      `${process.env.REACT_APP_APP_DOMAIN}/api/test-list`, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }}
    )
    .then((response) => {
      console.log("response.data :",response.data)
      setTests(response.data)

    })
    .catch((error) => {
      console.error("Error fetching candidate scores:", error);
    });

  }, []);
  useEffect(() => {
  
    axios
    .get(
      `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }}
    )
    .then((response) => {
      console.log("jobs :",response.data)
      setjobs(response.data)

    })
    .catch((error) => {
      console.error("Error fetching candidate scores:", error);
    });

  }, []);
  useEffect(() => {
    if (selectedCandidate) {
      axios
        .get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/getCandidatesTests/${selectedCandidate}`, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        )
        .then((response) => {
          const candidateTests = response.data; // Les IDs des tests pour le candidat
          console.log("response.data (candidateTests):", candidateTests);
          
          // Associer les noms des tests à partir de `tests`
          const updatedTests = candidateTests.map(test => {
            console.log("test :",candidateTests)

            const matchingTest = jobs.find(t => t.id === test.jobRoleId);
            return {
              ...test,
              name: matchingTest ? matchingTest.JobTitle : "Job inconnu"
            };
          });
          console.log("updatedTests :",updatedTests)

          setTeststId(updatedTests);
        })
        .catch((error) => {
          console.error("Error fetching candidate tests:", error);
        });
    }
  }, [selectedCandidate, tests]); 
  
  
  const data = {
    labels: Object.keys(traitScores),
    datasets: [
      {
        label: testName,
        data: Object.values(traitScores),
        backgroundColor: "rgba(33, 158, 188, 0.2)",
        borderColor: "rgba(33, 158, 188, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      r: {
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },
        max: 10,
      },
    },
  };
  const toggler=()=>{
    setIsSelected(!isSelected);

  }

  return (
    <div className="candidate-interface-container">
      <h1>{lang.menu.personalityTestRes}</h1>
      <br />
      <br />
      <div className="cardResult candidate-card">
      {userConnect && userConnect.userRole===1
          ?( <Switch 
            onChange={toggler}
            isSelected={isSelected} 
            style={{ flex: 1 }} >
{isSelected
 ? lang.menu.TestPerWIthAI 
 : lang.menu.TestPerWIthNotAI}                  
      </Switch> ):(

<Switch 
isSelected={isSelected} 
style={{ flex: 1 }} >
{isSelected
? lang.menu.TestPerWIthAI 
: lang.menu.TestPerWIthNotAI}                  
</Switch> 

      )}
     
        <select
          value={selectedCandidate}
          onChange={handleCandidateChange}
          className="select"
        >
          <option value="">{lang.menu.SelectCandidate}</option>
          {candidates.map((candidate) => (
  <option key={candidate.candidate_id} value={candidate.candidate_id}>
    {candidate.candidate_id}
  </option>
))}

        </select>
      </div>
      <div className="cardResult candidate-card">
        <select
          value={selectedTest}
          onChange={handleTestChange}
          className="select"
        >
          <option value="">{lang.menu.selectJob}</option>
          {teststId.map((test) => (
      <option key={test.test_id} value={test.test_id}>
        {test.name} 
      </option>
    ))}

        </select>
      </div>
      <div className="cardResult result-card">
        {resultDescription && (
          <div className="result-description">
            <h4 className="title">{selectedCandidate}</h4>
            {resultDescription}
          </div>
        )}
        {Object.keys(traitScores).length > 0 && isSelected===false ? (
          <div className="radar-chart-container">
            <div className="radar-chart">
              <Radar data={data} options={options} />
            </div>
          </div>
        ) : (
          <p className="please-select-candidate">
          </p>
        )}
      </div>
    </div>
  );
}

export default TestView;
