import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Card,ListGroup,Modal ,Form ,Row ,Col} from "react-bootstrap";
import axios from "axios";
import { RiArrowGoBackFill } from "react-icons/ri";
import translations from "../../AppsMenu/Candidate/mulitlingue";
import { FaCheckCircle } from "react-icons/fa";

const QuestionOfSkill = () => {
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [Name, setSkillName] = useState("");
  const history = useHistory();
  const token=localStorage.getItem("token");
  const [showModal, setShowModal] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState(null); 
  const openModal = (imageUrl) => {
    setModalImageUrl(imageUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const pathname = window.location.pathname;
  const idCat = pathname.split("/").pop();
  const getImageUrlFromServer = (imageName, Name, id) => {
    return `${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/images/${Name}/${id}/${imageName}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/skill/getSkillNameById/${idCat}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        setSkillName(response.data.name);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [idCat]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/FindBySkill/${idCat}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        setQuestions(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [idCat]);

  const isImage = (url) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    const extension = url.substring(url.lastIndexOf(".")).toLowerCase();
    return imageExtensions.includes(extension);
  };

  const getImageUrl = (imageRef, id) => {
    if (isImage(imageRef)) {
      const imageUrl = getImageUrlFromServer(imageRef, Name, id);
      return imageUrl;
    }
    return null;
  };

  const renderContent = (content, id) => {
    const imageUrl = getImageUrl(content, id);
    if (imageUrl) {
      return (
        <div className="frame">
          <img src={imageUrl} alt=" " width={150} height={100}/>
        </div>
      );
    } else {
      return <span style={{ fontSize: "12px" }}>{content}</span>;
    }
  };

  const renderContentRef = (content, id) => {
    const imageUrl = getImageUrl(content, id);
  
    const handleImageLoad = (e) => {
      const img = e.target;
      const { naturalWidth, naturalHeight } = img;
  
      // Si l'image est plus grande que 100x90, on redimensionne
      if (naturalWidth >100 || naturalHeight > 90) {
        const aspectRatio = naturalWidth / naturalHeight;
  
        // Ajuster la taille tout en conservant les proportions
        if (aspectRatio > 1) {
          // Image plus large que haute
          img.style.width = "500px";
          img.style.height = `${500 / aspectRatio}px`;
        } else {
          // Image plus haute que large
          img.style.width = `${400 * aspectRatio}px`;
          img.style.height = "400px";
        }
      }
    };
  
    if (imageUrl) {
      return (
        <>
        <div className="frame center">
          {/* On utilise onLoad pour contrôler la taille après le chargement */}
          <img
            src={imageUrl}
            alt=" "
            onLoad={handleImageLoad}
            style={{ cursor: "pointer",maxWidth: "100%", maxHeight: "100%" }}
            onClick={() => openModal(imageUrl)}          />
        </div>
        <Modal show={showModal} onHide={closeModal} centered size="l">
          <Modal.Body className="p-0">
            <img
              src={modalImageUrl}
              alt=" "
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Modal.Body>
        </Modal>
        </>
      );
    } else {
      return <span style={{ fontSize: "14px" }}>{content}</span>;
    }
  };
  
  const handleBonneReponseClick = (question) => {
    setSelectedQuestion(question);
  };
  const getBonneReponseLabel = (question) => {
    switch (question.BonneReponse) {
      case "choix1":
        return `${lang.menu.choix1}`;
      case "choix2":
        return `${lang.menu.choix2}`;
      case "choix3":
        return `${lang.menu.choix3}`;
      case "choix4":
        return `${lang.menu.choix4}`;
      default:
        return ""; 
    }
  };
  const [searchTerm, setSearchTerm] = useState('');
  const filterTestByTitle = () => {
    return questions.filter(test => test.question.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  return (
    <div style={{ fontFamily: "Poppins" }}>
      <Row>
       
        <h2 className="fs-20 font-w800  me-auto">
          {Name}
        </h2>
</Row>
<Row>
<Form.Group controlId="formBasicSearch">
        <Form.Control
          type="text"
          placeholder={lang.menu.serachQuestion}
          value={searchTerm}
          onChange={handleSearch}
        />
      </Form.Group>
      </Row>
    
<br/>
      <div>
      {filterTestByTitle().map(question => (
          <Card key={question.id} className="mb-4 shadow-sm border-0">
          {/* Header with level */}
          <Card.Header 
            className="text-white" 
            style={{ 
              backgroundColor: "#74188D", 
              fontWeight: "bold", 
              fontSize: "1.2rem",
              textTransform: "uppercase" 
            }}>
            {question.level}
          </Card.Header>
    
          <Card.Body style={{ backgroundColor: "#f8f9fa", padding: "1.5rem" }}>
            {/* Question Title */}
            <Card.Title
              className="mb-4"
              style={{
                color: "#333",
                textAlign: "left",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              {question.question}
            </Card.Title>
    
            {/* Image Reference (if exists) */}
            {question.imageRef && renderContentRef(question.imageRef, question.id)}
    
            {/* List of Choices */}
            <ListGroup variant="flush" className="mb-4">
              <ListGroup.Item style={{ border: "none", paddingLeft: 0 }}>
                1. {renderContent(question.choix1, question.id)}
              </ListGroup.Item>
              <ListGroup.Item style={{ border: "none", paddingLeft: 0 }}>
                2. {renderContent(question.choix2, question.id)}
              </ListGroup.Item>
              <ListGroup.Item style={{ border: "none", paddingLeft: 0 }}>
                3. {renderContent(question.choix3, question.id)}
              </ListGroup.Item>
              {question.choix4 && (
                <ListGroup.Item style={{ border: "none", paddingLeft: 0 }}>
                  4. {renderContent(question.choix4, question.id)}
                </ListGroup.Item>
              )}
            </ListGroup>
    
            {/* Action Button and Response */}
            <div className="d-flex align-items-center">
              <Button
                variant="success"
                onClick={() => handleBonneReponseClick(question)}
                className="me-3"
              >
                <FaCheckCircle className="me-2" /> {lang.menu.BonneReponse}
              </Button>
              {selectedQuestion === question && (
            <strong style={{ color: "#74188D", fontSize: "1.3rem" }}>
              {getBonneReponseLabel(question)}
            </strong>
          )}
            </div>
          </Card.Body>
        </Card>
        ))}
      </div>
    </div>
  );
};

export default QuestionOfSkill;
