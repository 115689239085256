import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import {
   Badge,
    Card,
    Modal,
   
  } from "react-bootstrap";
  import {AiOutlineSend}  from "react-icons/ai";
  import translations from"./mulitlingue";
  import PageTitle from "../../../layouts/PageTitle"
  import swal from "sweetalert";

const AllListCandidates = () => {
  const [candidateListRef, setCandidateListRef] = useState([]);
  const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          setCandidateListRef(
            data.some((permission) => permission.title === "list-ref")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!candidateListRef) {
      setShowAccessDeniedModal(true); 
    }
  }, [candidateListRef]);
  const [candidates, setCandidates] = useState([]);const [selectedLang, setSelectedLang] = useState(null);
	const [lang, setLang] = useState(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
		  const parsedLang = JSON.parse(storedLang);
		  if (parsedLang.language === 'Français') {
			return translations.fr;
		  }
		}
		return translations.en;
	  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/flask/cv_user_with_refs`,{
          headers: {
            Authorization: `Bearer ${token}`, // Assurez-vous que `token` est correctement défini
          }});
        setCandidates(response.data);
        
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  const handleButtonClick = (idUser) => {
    axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/flask/cvWithEmailRefbyfilename?idUser=${idUser}`,{
        headers: {
          Authorization: `Bearer ${token}`, // Assurez-vous que `token` est correctement défini
        }})
      .then((response) => {
        console.log(response.data);
        toast.success(
          <div>
           <img src="/email.png" alt="Recommendation Image" width="100" height="50"/>
            <p>{lang.menu.msgRecommandationSucc}</p>
          </div>
        );
    
      })
      .catch((error) => {
        console.log(error);
        swal(lang.menu.msgRecommandationfailed, {
          icon: "error",
        });
      });
  };
  return (
    <> {candidateListRef ? (
    <div >
       <PageTitle
        activeMenu={lang.menu.listRef}
        motherMenu={lang.menu.dashboard}
        pageContent="Candidate"
      />
      <Card>
        {/* <Card.Header>
          <Card.Title>{lang.menu.pageTitle}</Card.Title>
        </Card.Header> */}
        <Card.Body>
        <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
            <thead>
              <tr>
              
                <th style={{fontSize: '14px', fontWeight: 'bold'}}>
                  {lang.menu.Candidate}
                </th>
                <th style={{fontSize: '14px', fontWeight: 'bold'}}>
                  {lang.menu.Reference} 
                </th>
                <th style={{fontSize: '14px', fontWeight: 'bold'}}>
                  {lang.menu.mailref}
                </th>
                <th style={{fontSize: '14px', fontWeight: 'bold'}}>
                  {lang.menu.filename}
                </th>
                <th style={{fontSize: '14px', fontWeight: 'bold'}}>
                  {lang.menu.comments}
                </th><th>
                  Notes
                </th>
                <th style={{fontSize: '14px', fontWeight: 'bold'}}>
                                    {lang.menu.status}
                </th>
              </tr>
            </thead>
            <tbody>
              {candidates.map((data) => {
                return (
                  <tr key={data.filename}>
              
                     <td style={{fontSize: '14px'}}>
                      {data.fullname}
                    </td>
                     <td style={{fontSize: '14px'}}>{data.nameRef}</td>
                     <td style={{fontSize: '14px'}}>{data.emailRef}</td>
                     <td style={{fontSize: '14px'}}>
                      <div className="d-flex align-items-center">
                        <i className="fa fa-circle text-success me-1"></i>{' '}
                        {data.filename}
                      </div>
                    </td>
                     <td style={{fontSize: '14px'}}>{data.comment}</td>
                     <td style={{fontSize: '14px'}}>{data.note}</td>


                     <td style={{fontSize: '14px'}}>
                    {data.status === 1 ? (
                      <Badge variant="success light">{lang.menu.statusmanaged}</Badge>
                    ) : (
                      <Badge variant="danger light">{lang.menu.statusunmanaged}</Badge>
                    )}
                  </td>


                  
                     <td style={{fontSize: '14px'}}>
                      <div className="d-flex">
           <button
      title={`Send email to ${data.nameRef}`}
      className="btn btn-primary shadow btn-xs sharp me-1"
      onClick={() => handleButtonClick(data.idUser)}
      disabled={data.status === 1}
    >
    <AiOutlineSend size={16}/>
  
</button>

                    
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
         </table>
         </div>
         </div>
         </div>
        </Card.Body>
      </Card>
      <ToastContainer />
    </div>   ) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
  );
};

export default AllListCandidates;
