import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button ,OverlayTrigger, Tooltip} from "react-bootstrap";
import { useParams } from "react-router-dom";
import Select from "react-select";
import data from "../data";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import translations from "../../components/AppsMenu/Candidate/mulitlingue";
import "./PostJob.css";
function UpdateJob() {
  const [profileId, setProfileId] = useState([]);
  const [selectedProfileOptions, setSelectedProfileOptions] = useState([]);
  const [allProfiles, setAllProfiles] = useState([]);
  const [availableProfiles, setAvailableProfiles] = useState([]);
  const [skillsPro, setSkillsPro] = useState([]);
  const [companyID, setcompanyID] = useState("");
  const token=localStorage.getItem("token");

  const pathname = window.location.pathname;
  const idJob = pathname.split("/").pop();
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const [selectedOptionOC, setSelectedOptionOC] = useState({ value: lang.menu.toc, label: lang.menu.toc });
	const [maxscore, setMaxscore] = useState(lang.menu.toc);
  useEffect(async () => {
    
    const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobRole/findJobProfile/${idJob}`, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }});
    const profileIds = response.data.map(profile => profile.job_profile_id);
    const uniqueProfileIds = new Set(profileIds);
    const uniqueProfileIdsArray = Array.from(uniqueProfileIds);
    setProfileId(uniqueProfileIdsArray);
  }, []);
  
  const history = useHistory();
  const [height, setHeight] = useState("150px");
  const [jobData, setJobData] = useState({
    jobTitle: "",
    description: "",
    jobType: "",
    score: "",
    offeredSalary: "",
    experience: "",
    typeOfQualification: "",
    country: "",
    closeDate: "",
    profiles: profileId,
    skills: "",
    selectedProfiles: [],
    companyid:""
  });

  //Style Select
  const selectStyles = {
    control: (base, state) => ({
      ...base,
      borderRadius: "15px",
      boxShadow:  "0 0 0 1px #000000" , // Ajoute une bordure bleue au focus
      border: "1px 0 #000000" , // Bordure au focus
    }),
    option: (base) => ({
      ...base,
      backgroundColor: "#fff",
      color: "#333",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "15px",
      marginTop: "-5px",
      boxShadow: "none",
      border: "none",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#555",
    }),
  };
  
  const { id } = useParams();
  const [selectedOption, setSelectedOption] = useState({
    value: "",
    label: ""
  });
  
  const [selectedOption2, setSelectedOption2] = useState({ value: "", label: ""});
  const optionsPt1 = [];
  


  const optionsPt2 = [];
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/job-list/read/` + id, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
      .then((res) => {
        setJobData({
          ...jobData,
          jobTitle: res.data[0].JobTitle,
          description: res.data[0].JobDescription,
          jobType: res.data[0].JobType,
          offeredSalary: res.data[0].OfferedSalary,
          experience: res.data[0].Experience,
          typeOfQualification: res.data[0].TypeOfQualification,
          country: res.data[0].Country,
          closeDate: res.data[0].CloseDate,
          profiles: profileId,
          skills:res.data[0].skills,
          PT1: res.data[0].PT1,
          PT2: res.data[0].PT2,
          maxOccurence: res.data[0].maxOccurence,

          companyid:res.data[0].companyid
        });
        setcompanyID(res.data[0].companyid)
        setSelectedOption({
          value: res.data[0].PT1,
          label: `${res.data[0].PT1 * 100}%`
        })
        setSelectedOption2({
          value: res.data[0].PT2,
          label: `${res.data[0].PT2 * 100}%`
        })
        setSelectedOptionOC({
          value: res.data[0].maxOccurence,
          label: `${res.data[0].maxOccurence}`
        })
        for (let i = (res.data[0].PT2 * 100+1); i <= 100; i++) {
          const value = (i / 100).toFixed(2);
          const label = `${i} %`;
          optionsPt1.push({ value: value, label: label });
        }
        for (let i = 0; i < res.data[0].PT1 * 100; i++) {
          const value = (i / 100).toFixed(2); 
          const label = `${i} %`;
          optionsPt2.push({ value: value, label: label });
        }
      })

      .catch((err) => console.log(err));
  }, [profileId]);
  useEffect(() => {
    console.log("jobData :",jobData)
    if(jobData.PT1){
   
    for (let i = (jobData.PT2 * 100+1); i <= 100; i++) {
      const value = (i / 100).toFixed(2);
      const label = `${i} %`;
      optionsPt1.push({ value: value, label: label });
    }
    for (let i = 0; i < jobData.PT1 * 100; i++) {
      const value = (i / 100).toFixed(2); 
      const label = `${i} %`;
      optionsPt2.push({ value: value, label: label });
    }
    console.log("optionsPt1 :",optionsPt1)

    console.log("optionsPt2 :",optionsPt2)
  }
  }, [jobData,optionsPt1,optionsPt2]);
  const [pt1Options, setPt1Options] = useState(optionsPt1);
  const [pt2Options, setPt2Options] = useState(optionsPt2);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/post-job/getAllProfiles`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
      .then((res) => {

        const uniqueProfiles = res.data.map((item) => ({
          value: item.id,
          label: item.title,
          skills:item.requiredQualifications, 
          companyid:item.companyid
        }));
        let filteredJobsProf = uniqueProfiles;
        if (companyID!=1) {
          filteredJobsProf = uniqueProfiles.filter((job) => job.companyid === companyID);
       }
       else{
          filteredJobsProf = uniqueProfiles; 
       }

        setJobData((prevJobData) => ({
          ...prevJobData,
          profiles: filteredJobsProf,
        }));
  setAllProfiles(filteredJobsProf)
 
      })
      .catch((err) => console.log(err));
  }, [companyID]);
  
  
  const profileOptions = jobData.profiles.map((profile) => ({
    value: profile.value, 
    label: profile.label, 
    skills:profile.requiredQualifications, 
  }));

  useEffect(() => {
    const updatedAvailableProfiles = allProfiles.filter(
      (profile) => !jobData.selectedProfiles.includes(profile.value)
    );

    setAvailableProfiles(updatedAvailableProfiles);
  }, [jobData.selectedProfiles, allProfiles]);

  useEffect(() => {
    const selectedProfiles = allProfiles.filter((profile) =>
      profileId.includes(profile.value)
    );
    setSelectedProfileOptions(selectedProfiles);
  }, [profileId, allProfiles]);

  const handleProfileChange = (selectedOptions) => {
    const selectedProfileIds = selectedOptions.map((option) => option.value);
    setJobData({
      ...jobData,
      selectedProfiles: selectedProfileIds,
    });
  
    const selectedProfileIdsSkills = selectedOptions.map((option) => ({
      id: option.value,
      skills: option.skills,
    }));
  
    const skillsProfiles = selectedProfileIdsSkills.reduce((acc, profile) => {
      try {
        const profileSkills = parseSkills(profile.skills);
        return acc.concat(profileSkills);
      } catch (error) {
        console.error(`Error parsing skills for profile ${profile.id}:`, error);
        return acc;
      }
    }, []);
  
    const uniqueSkillsMap = getUniqueSkillsMap(skillsProfiles);
    const uniqueSkillsArray = Array.from(uniqueSkillsMap.values());
  
    const skillsProArray = uniqueSkillsArray.map((skill) => `("${skill.name}",${skill.count})`);

  const skillsProString = `[${skillsProArray.join(',')}]`;

    setSkillsPro(skillsProString); 
       setSelectedProfileOptions(selectedOptions);
  };
  
  // Helper function to parse skills
  const parseSkills = (skillsString) => {
    const skillsArray = skillsString.match(/\('[^']+', \d+\)/g);
  
    if (!skillsArray) {
      return [];
    }
  
    return skillsArray.map((skill) => {
      const [, name, count] = skill.match(/\('([^']+)'\s*,\s*(\d+)\)/);
      return { name, count: parseInt(count, 10) };
    });
  };
  
  // Helper function to get unique skills with maximum count
  const getUniqueSkillsMap = (skillsArray) => {
    const skillsMap = new Map();
  
    skillsArray.forEach((skill) => {
      const existingSkill = skillsMap.get(skill.name);
  
      if (!existingSkill || skill.count > existingSkill.count) {
        skillsMap.set(skill.name, { name: skill.name, count: skill.count });
      }
    });
  
    return skillsMap;
  };
  
  
  const handleUpdate = async (event) => {
    event.preventDefault();
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
  
    try {
      var  updatedJobData = {
        ...jobData
      };
      if(skillsPro.length!=0){
       updatedJobData = {
          ...jobData,
          skills: skillsPro,
        };
      }
      // 1. Mise à jour du job
      

      setJobData(updatedJobData);  
      const jobUpdateResponse = await axios.put(
        `${process.env.REACT_APP_APP_DOMAIN}/api/job-list/update/` + id + `/${userId}`,
        updatedJobData, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
  
      // 2. Mise à jour des profils d'emploi associés au job_role
      const updatedProfiles = updatedJobData.selectedProfiles.length
      ? updatedJobData.selectedProfiles
      : [profileId]; 


    const profilesUpdateResponse = await axios.put(
      `${process.env.REACT_APP_APP_DOMAIN}/api/jobRole/updateJobProfilesForJobRole/${idJob}`,
      { updatedProfiles }, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }}
    );
  
  
      swal(lang.menu.jobRoleMsgUpdateSuccess, {
        icon: "success",
      });
  
      history.push("/job-list");
    } catch (error) {
      swal(lang.menu.deleteWrong, "error");
      console.error(error);
    }
  };
  const handleDropdownSelect2 = (value) => {
    if (value !== lang.menu.pt2) {
      // Update PT1 options based on the selected value of PT2
      const filteredOptions = optionsPt1.filter(option => parseFloat(option.value) > parseFloat(value));
      setPt1Options(filteredOptions);
      setJobData({
        ...jobData,
        PT2: parseFloat(value),
      });
    }
  };
  const handleDropdownSelect1 = (value) => {
      // Update PT1 options based on the selected value of PT2
      const filteredOptions = optionsPt2.filter(option => parseFloat(option.value) < parseFloat(value));
      setPt2Options(filteredOptions);
      setJobData({
        ...jobData,
        PT1: parseFloat(value),
      });
    
  };
  const handleSelectChange2 = (selectedOption2) => {
    setSelectedOption2(selectedOption2);
    handleDropdownSelect2(selectedOption2.value);
  };
  const handleSelectChange1 = (selectedOption1) => {
    setSelectedOption(selectedOption1);
    handleDropdownSelect1(selectedOption1.value);
  };
  const optionsOc = [
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" }
  ];
  const handleDropdownSelect4 = (value) => {
		setMaxscore(value);
    setJobData({
      ...jobData,
      maxOccurence: value,
    });
	};
  const handleSelectChangeOC = (selectedOptionOC) => {
    setSelectedOptionOC(selectedOptionOC);
    handleDropdownSelect4(selectedOptionOC.value);
  };
  return (
    <>
      <h1>{lang.menu.UpdateJobRole} </h1>
      <br />
      <form onSubmit={handleUpdate}>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header d-block">
              <label><strong>{lang.menu.JobRoleName} <span style={{ color: 'red' }}>*</span> :</strong> </label>


                <div className="input-group ">
                  <input
                    type="text"
                    className={`form-control `}
                    placeholder=" Title"
                    value={jobData.jobTitle}
                    onChange={(e) =>
                      setJobData({ ...jobData, jobTitle: e.target.value })
                    }
                  />
                </div>
                <br />
                <label><strong>{lang.menu.Responsibility} <span style={{ color: 'red' }}>*</span> :</strong> </label>

                <div className="input-group">
                  <textarea
                    value={jobData.description}
                    style={{ height: height }}
                    onChange={(e) =>
                      setJobData({ ...jobData, description: e.target.value })
                    }
                    type="text"
                    className={`form-control`}
                    placeholder=" Job Description"
                  />
                </div>
                <br />

                <div className="select-container">
                 
                  <div className="select-wrapper-left">
                  <label><strong>{lang.menu.closeDate} <span style={{ color: 'red' }}>*</span> :</strong> </label>             
                  <input
                      type="date"
                      value={jobData.closeDate.split("T")[0]}
                      className={`form-control`}
                      onChange={(e) =>
                        setJobData({
                          ...jobData,
                          closeDate: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <br />
                <div className="select-container">
                  <div className="select-wrapper-right">
                  <label><strong>{lang.menu.jobType} <span style={{ color: 'red' }}>*</span> :</strong> </label>
                  <Select
                      options={data.JobTypeOptions}
                      value={{
                        value: jobData.jobType,
                        label: jobData.jobType,
                      }}
                      styles={selectStyles}
                      onChange={(e) =>
                        setJobData({ ...jobData, jobType: e.value })
                      }
                    />
                  </div>
                 
                  <div className="select-wrapper-right">
                  <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-pt1">{lang.menu.msgOc}</Tooltip>}
    >
                    <label><strong>{lang.menu.toc} :</strong> </label>
                    </OverlayTrigger>
                    <br/>
            <Select
      value={selectedOptionOC}
      onChange={handleSelectChangeOC}
      options={optionsOc}
      styles={selectStyles}

    />
    </div>
               
                </div>
                <br />
                <div className="select-container">
                  <div className="select-wrapper-right">
                  <label><strong>{lang.menu.OfferedSalary} <span style={{ color: 'red' }}>*</span> :</strong> </label>                   
                    <textarea
                      value={jobData.offeredSalary}
                      onChange={(e) =>
                        setJobData({
                          ...jobData,
                          offeredSalary: e.target.value,
                        })
                      }
                      type="text"
                      className={`form-control`}
                      placeholder="Offred Salary"
                    />
                  </div>
                  <div className="select-wrapper-right">
                  <label><strong>{lang.menu.country} <span style={{ color: 'red' }}>*</span> :</strong> </label>             
                  <textarea
                      value={jobData.country}
                      type="text"
                      className={`form-control`}
                      placeholder="Country"
                      onChange={(e) =>
                        setJobData({ ...jobData, country: e.target.value })
                      }
                    />
                  </div>
                </div>
                <br />
                <div className="select-container">
                  <div className="select-wrapper-right">
                  <label><strong>{lang.menu.TypeOfQualification} <span style={{ color: 'red' }}>*</span> :</strong> </label>             

                    <Select
                      options={data.TypeQualificationOptions}
                      value={{
                        value: jobData.typeOfQualification,
                        label: jobData.typeOfQualification,
                      }}
                      styles={selectStyles}
                      onChange={(e) =>
                        setJobData({
                          ...jobData,
                          typeOfQualification: e.value,
                        })
                      }
                    />
                  </div>
                  <div className="select-wrapper-right">
                  <label><strong>{lang.menu.Experience} <span style={{ color: 'red' }}>*</span> :</strong> </label>             
                  <Select
                      options={data.ExperienceOptions}
                      value={{
                        value: jobData.experience,
                        label: jobData.experience,
                      }}
                      styles={selectStyles}
                      onChange={(e) =>
                        setJobData({ ...jobData, experience: e.value })
                      }
                    />
                  </div>
           
                </div>
                <div className="select-container">
                  <div className="select-wrapper-right">
                  <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-pt1">{lang.menu.msgpt1}</Tooltip>}
          >
            <label><strong>{lang.menu.pt1} <span style={{ color: 'red' }}>*</span> :</strong> </label>
          </OverlayTrigger>
      <Select
      value={selectedOption}
      onChange={handleSelectChange1}
      options={pt1Options}
      styles={selectStyles}

    />
    </div>
                    <div className="select-wrapper-right">
                    <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-pt1">{lang.menu.msgpt2}</Tooltip>}
          >
            <label><strong>{lang.menu.pt2} <span style={{ color: 'red' }}>*</span> :</strong> </label>
          </OverlayTrigger>      <Select
            value={selectedOption2}
            onChange={handleSelectChange2}
            options={pt2Options} 
            styles={selectStyles}

          />
    </div>
                  </div>
                <br/>
                <div className="select-wrapper-right">
                <label><strong>{lang.menu.AddProfiles} <span style={{ color: 'red' }}>*</span> :</strong> </label>             
                <Select
          
          isMulti
          options={availableProfiles}
          value={selectedProfileOptions}  
          styles={selectStyles}
          onChange={handleProfileChange}
        />
      </div>
     
              </div>
       
              <div className="card-footer border-0">
                <div className="d-flex justify-content-end">
                  <Button
                    type="submit"
                    disabled={selectedProfileOptions.length==0}
                    className="btn btn-primary btn sweet-confirm"
                  >
                    {lang.menu.Update}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default UpdateJob;
