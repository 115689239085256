import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import translations from "../AppsMenu/Candidate/mulitlingue";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import swal from "sweetalert";


function CreateTestForm() {
    const history = useHistory();
  const [formData, setFormData] = useState({
    name: "",
    type: "Coding",
    codingTests: [],
    qcmTests: [],
    level:"Beginner"
  });
  const token=localStorage.getItem("token");

  const [codingTests, setCodingTests] = useState([]);
  const [qcmTests, setQcmTests] = useState([]);
  const [uniqueQcmTestIds, setUniqueQcmTestIds] = useState(new Set());

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  
  useEffect(() => {
    const getUserCompanyID = async () => {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
      const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      const data = await responseC.json();
      const compId = data[0].companyID;
      return compId;
    };
    const fetchCodingTests = async () => {
      try {
        const compId = await getUserCompanyID();

        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/topic/getAllTopics`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        if (compId!=1) {
          const filteredTests = response.data.filter(test => test.companyID === compId || test.companyID === null );
          setCodingTests(filteredTests); 
        } else {
          setCodingTests(response.data);
        }      } catch (error) {
        console.error(error);
      }
    };

    const fetchQcmTests = async () => {
      try {
        const compId = await getUserCompanyID();
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/techQCM/getAllTestsByComp/${compId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
    
            setQcmTests(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des tests:', error);
      }
    };
    fetchCodingTests();
    fetchQcmTests();
  }, []);

  const handleInputChange = (e) => {
    
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleCodingTestsChange = (selectedOptions) => {
    if (formData.type === "Coding" && selectedOptions.length > 5) {
      return; 
    }
    if (formData.type === "Both" && (selectedOptions.length + formData.qcmTests.length) > 5) {
      return; 
    }
  
    const selectedTestNames = selectedOptions.map((option) => option.value);
    setFormData({
      ...formData,
      codingTests: selectedTestNames,
    });
  };
  
  const handleQcmTestsChange = (selectedOptions) => {
    const selectedTests = selectedOptions.map((option) => parseInt(option.value));
    if (formData.type === "QCM" && selectedTests.length > 5) {
      return; 
    }
    if (formData.type === "Both" && (selectedTests.length + formData.codingTests.length) > 5) {
      return; 
    }
  
    const combinedTests = [...formData.qcmTests, ...selectedTests];
    const uniqueSelectedTests = Array.from(new Set(combinedTests)).slice(0, 5);
    
    setFormData({
      ...formData,
      qcmTests: uniqueSelectedTests,
    });
  };
  
  

  const codingTestOptions = codingTests.map((test) => ({ value: test.id, label: test.name }));
  const qcmTestOptions = qcmTests
    .reduce((uniqueTests, test) => {
      const existingTest = uniqueTests.find((uniqueTest) => uniqueTest.value === test.test_id);

      if (!existingTest) {
        uniqueTests.push({ value: test.test_id, label: test.test_name });
      }

      return uniqueTests;
    }, []);
    const getUserCompanyID = async () => {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
      const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      const data = await responseC.json();
      const compId = data[0].companyID;
      return compId;
    };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const companyID = await getUserCompanyID();
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    console.log("companyID :",companyID)
    try {
      const requestData = {
        name: formData.name,
        type: formData.type,
        description: formData.description,
        coefQCM: formData.coefQCM,
        coefCoding: formData.coefCoding,
        companyID: companyID,
        topicIDs: formData.codingTests,
        techQCMIDs: formData.qcmTests,
        allowCopyPaste:formData.allowCopyPaste === null ? false : formData.allowCopyPaste,
        takeSnapshots:formData.takeSnapshots === null ? false : formData.takeSnapshots,
        takeScreenshots:formData.takeScreenshots === null ? false : formData.takeScreenshots,
        numberOfScreenShots: parseInt(formData.numberOfScreenShots),
        numberOfSnapShots: parseInt(formData.numberOfSnapShots),
        level: formData.level,
        userId: userId,
      };
      console.log("requestData :",requestData)
      
      const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/api/testTech/createTestTech`, requestData, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      swal("Test submitted successfully.", {
        icon: "success",
      });
      history.push("/TechList");

    } catch (error) {
      swal('Failed to submit the form.', {
        icon: "error",
      });
    }
  };

  //get permissions of the test from the user (default value)
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        const userid = currentUser.localId;

        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/technical/permissions/getuser/${userid}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        
          const permissions= response.data.permissionsTestTech;
          
        formData.allowCopyPaste= permissions.allowCopyPaste;
        formData.takeSnapshots = permissions.takeSnapShots;
        formData.takeScreenshots = permissions.takeScreenShots;
        formData.numberOfScreenShots= permissions.numberOfScreenShots;
        formData.numberOfSnapShots = permissions.numberOfSnapShots;

      

      } catch (error) {
        console.error(error);
      }
    };
 
    fetchPermissions();
  }, []);

  return (
    <div style={{ display: "flex", justifyContent:"center"}}>
    <Card  style={{border: "2px solid white", backgroundColor: "white", width:"60%"}}>
      <h2 style={{textAlign: "center", justifyContent:"center"}}>{lang.menu.NewAssessement}</h2>
      <Form onSubmit={handleSubmit} style={{margin: "5%"}}>
        <Form.Group controlId="name">
          <Form.Label><h3 className="left-align-container smallscreen">{lang.menu.name}</h3></Form.Label>
          <Form.Control
            required
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            
          />
        </Form.Group>
        <Form.Group controlId="description">
          <Form.Label><h3 className="left-align-container smallscreen">
                    {lang.menu.description}
                  </h3></Form.Label>
          
          <textarea
                      required
                      style={{ height: "100px" }}
                      onChange={handleInputChange}
                      type="text"
                      name="description"
                      value={formData.description}
                      className={`form-control`}
                      placeholder="Description"
                      maxLength={1000}
                    />
        </Form.Group>
        <Row>
            <Col className="col-6">
        <Form.Group controlId="type">
          <Form.Label><h3 className="left-align-container smallscreen">Type </h3> </Form.Label>
          <Form.Control
            required
            as="select"
            name="type"
            value={formData.type}
            onChange={handleInputChange}
            
          >
           
            <option value="Coding">{lang.menu.coding}</option>
            <option value="QCM">{lang.menu.QCM}</option>
            <option value="Both">{lang.menu.Both}</option>
          </Form.Control>
        </Form.Group>
        </Col>

        <Col className="col-6">
        <Form.Group controlId="level">
          <Form.Label><h3 className="left-align-container smallscreen">{lang.menu.level} </h3> </Form.Label>
          <Form.Control
            required
            as="select"
            name="level"
            value={formData.level}
            onChange={handleInputChange}
            
          >
            
            <option value="Beginner">{lang.menu.Beginner}</option>
            <option value="Intermediate">{lang.menu.Intermediate}</option>
            <option value="Advanced">{lang.menu.Advanced}</option>
          </Form.Control>
        </Form.Group>
        </Col>
        </Row>
        {formData.type === "Coding" || formData.type === "Both" ? (
          <>
          <br/>
          <Row>
            <Col className="col-6">
              <Form.Group controlId="codingTests">
              <div className="row">
      <div className="col-md-9">
        <Form.Label>
          <h3 className="left-align-container smallscreen">{lang.menu.codTest}</h3>
        </Form.Label>
      </div>
      <div className="col-md-3">
        <Link to="/TopicsByCandidate" className="buttonSkills">
      <i className="fas fa-plus"></i>
    </Link>
      </div>
    </div>                <Select
                  options={codingTestOptions}
                  isMulti
                  onChange={handleCodingTestsChange}
                  required
                  isOptionDisabled={() => formData.codingTests.length >= 5 || (formData.type === "Both" && (formData.codingTests.length + formData.qcmTests.length) >= 5)}
                  />
              </Form.Group> 
            </Col>
            <Col className="col-6">
              <Form.Group controlId="coefCoding">
                <Form.Label><h3 className="left-align-container smallscreen">{lang.menu.coefCoding}</h3></Form.Label>
                <Form.Control
                  type="number"
                  name="coefCoding"
                  value={formData.coefCoding}
                  onChange={handleInputChange}
                  placeholder="Please enter the coefficient"
                  required
                  min="1"
                />
              </Form.Group>
            </Col>
          </Row>
          
          

        
          </>
        ) : null}
        {formData.type === "QCM" || formData.type === "Both" ? (
          <>
                    <br/>

          <Row>
            <Col className="col-6">
              <Form.Group controlId="qcmTests">
              <div className="row">
      <div className="col-md-9">
        <Form.Label>
          <h3 className="left-align-container smallscreen">{lang.menu.testTechniqueMCQ}</h3>
        </Form.Label>
      </div>
      <div className="col-md-3">
      <Link to="/TechQCM" className="buttonSkills">
      <i className="fas fa-plus"></i>
    </Link>
      </div>
    </div>                      <Select
                  options={qcmTestOptions}
                  isMulti
                  onChange={handleQcmTestsChange}
                  required
                  isOptionDisabled={() => formData.qcmTests.length >= 5 || (formData.type === "Both" && (formData.codingTests.length + formData.qcmTests.length) >= 5)}

                />
              </Form.Group>
            </Col>
            <Col className="col-6">
              <Form.Group controlId="coefQCM">
                <Form.Label><h3 className="left-align-container smallscreen">{lang.menu.coefQCM}</h3></Form.Label>
                <Form.Control
                  type="number"
                  name="coefQCM"
                  value={formData.coefQCM}
                  onChange={handleInputChange}
                  placeholder={lang.menu.plzCoef}
                  required
                  min="1"
                />
              </Form.Group>
            </Col>
            </Row>
          </>
        ) : null}

        {formData.type && 
          <>
          <Row style={{marginTop: "2%"}}>
              <Col className="col-4">
          <Form.Group controlId="allowCopyPaste">
            
              <Form.Check
                name="allowCopyPaste"
                type="checkbox"
                id="allowCopyPasteCheckbox"
                label={lang.menu.AllowCopyPaste}
                checked={formData.allowCopyPaste === true}
                onChange={() => handleInputChange({ target: { name: "allowCopyPaste", value: !formData.allowCopyPaste  } })}
                
              />
              
          </Form.Group>
          </Col>

          <Col className="col-4">
        <Form.Group controlId="takeSnapshots">
              <Form.Check
                name="takeSnapshots"
                type="checkbox"
                id="takeSnapshotsCheckbox"
                label={lang.menu.takeSnapshots}
                checked={formData.takeSnapshots === true}
                onChange={() => handleInputChange({ target: { name: "takeSnapshots", value: !formData.takeSnapshots } })}
                
              />
              {formData.takeSnapshots === true && 
              <Form.Group controlId="numberOfSnapShots">
              <Form.Label>{lang.menu.numberOfSnapShots} </Form.Label>
              <Form.Control
                          type="number"
                          name="numberOfSnapShots"
                          value={formData.numberOfSnapShots}
                          onChange={handleInputChange}
                          min="0"
                          required
                        />
            </Form.Group>}

            
        </Form.Group>
        </Col>
        <Col className="col-4">
        <Form.Group controlId="takeScreenshots">
          
              <Form.Check
                name="takeScreenshots"
                type="checkbox"
                id="takeScreenshotsCheckbox"
                label={lang.menu.takeScreenshots}
                checked={formData.takeScreenshots === true}
                onChange={() => handleInputChange({ target: { name: "takeScreenshots", value: !formData.takeScreenshots } })}
                
              />
              {formData.takeScreenshots === true && 
              <Form.Group controlId="numberOfScreenShots">
              <Form.Label>{lang.menu.numberOfScreenShots} </Form.Label>
              <Form.Control
                          type="number"
                          name="numberOfScreenShots"
                          value={formData.numberOfScreenShots}
                          onChange={handleInputChange}
                          min="0"
                          required
                          
                        />
            </Form.Group>}
              
        </Form.Group>
        </Col>
        </Row>
        </>
        }

        <div>
        <Button variant="primary" type="submit" style={{marginLeft:"80%", marginTop:"5%"}}>
          {lang.menu.CreateTest}
        </Button>
        </div>
        
      </Form>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />

    </Card>
    </div>
  );
}

export default CreateTestForm;
