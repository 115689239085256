const translations = {
  en: {
    language: 'English',
    menu: {
      pageTitle: 'List of Candidates',
      candidates:"Candidates",
      allApplicants: 'All Applicants',
      chooseStatus: 'Choose Status',
      accepted: 'Accepted',
      pending: 'Pending',
      refused: 'Refused',
      jobRole: 'Job Role',
      search: 'Search candidate ...',
      name: 'Name',
      phone:"Phone Number",
      language:"Language",
      country:"Country",
      speciality:"Speciality",
      skills:"Skills",
      education:"Education",
      status:"Status",
      certificats:"Certificats",
      experience:"Experience",
      next:"Next",
      previous:"Previous",
      pt1:"Preselection Thresholds 1",
      pt2:"Preselection Thresholds 2",
      toc:"Threshold of occurrence",
      upload : "Upload",
      cvEx:"CVs are currently being extracted",
      plz:"Please wait...",
      cvDex:"CVs data extraction",
      totCan:"Total Candidates",
      selCan:"Selected Candidates",
      rejCan:"Rejected Candidates",
      complete:"Complete",
      uploadCv: "Send us your CV",
      errorMess:"An error occurred while uploading the file.",
      successMes:"File uploaded successfully!",
      Search:"Search ...",
      profile:"Profile",
      inbox:"Inbox",
      dashboard:"Dashboard",
      dashbordL:"Dashboard Light",
      dashbordD:"Dashboard Dark",
      candidateAssessment:"Candidate Assessment",
      JobRolEval:"Evaluations",
      jobs:"Jobs",
      Mprofile:"My Profile",
      statistics:"Statistics",
      companies:"Companies",
      task:"Task",
      candidateList:"Candidate List",
      applyJob:"Apply Job",
      jobList:"Job List",
      upTest:"Upload Test",
      jobView:"Job View",
      jobApp:"Job Application",
      perTest:"Personality",
      newJob:"New Job",
      userProfile:"User Profile",
      postDetails:"Post Details",
      postJob:"Post a New Job!",
      jobTitle:"Job Title",
      jobDesc:"Job Description",
      addJob:"Add New Job",
      applicant:"Applicant",
      postDate: "Date created",
      closeDate:"Deadline",
      pagin:"Showing 1 to 7 of 7 entries",
      overview:"Overview",
      vacancy:"Vacancy",
      jobType:"Job Type",
      lastDate:"Last Apply Date",
      applyNow:"Apply Now",
      contact:"Contact Us",
      jobDetails:"Job Details",
      role:"Role",
      minS:"Min Salary",
      maxS:"Max Salary",
      jobTags:"Job Tags",
      jobExp:"Job Experience",
      locality:"Locality",
      eligibility:"Eligibility",
      company:"Company",
      jobID:"Job ID",
      postedBy:"Posted By",
      share:"Share Job",
      print:"Print",
      companyName:"Company Name",
      jobCat:"Job Category",
      choose:"Choose...",
      nbrVac:"No. of Vancancy",
      select:"Select",
      msgRecommandationSucc:"Recommendation request sent successfully.",
      msgRecommandationfailed:"Failed to send recommendation request.",
      fullname:"Full Name",
      ref:"Name of proposed reference",
      mailref:" Reference’s Email",
      filename:"Filename",
      comment:"Comment",
      comments:"Comments",

      statusmanaged:"Managed state",
      statusunmanaged:"Unmanaged state",
      welcomeBack: "Welcome back!",
      userExperience: "User Experience & Interface Design",
      strategy: "Strategy SaaS Solutions",
      signIn: "Sign in to your account",
      email: "Email",
      password: "Password",
      typeEmail: "Type Your Email Address",
      rememberMe: "Remember me",
      forgotPassword: "Forgot Password?",
      signInButton: "Sign In",
      newAccount: "Don't have an account?",
      signUp: "Sign up",
      Logout: "Logout",
      testPsychoTechnique:"PsychoTechnical Test",
      Question:"Question",
      BonneReponse:"Correct answer",
      imageRef:"Image Reference",
      Categorie:"Category",
      choix1:"Choice 1",
      choix2:"Choice 2",
      choix3:"Choice 3",
      choix4:"Choice 4",
      questions:"Questions",
      testTechnique:"Assessment",
      NB:"The name of your CV must be in this form FirstName LastName.pdf",
      categorieAvailable:"Categories available",
      newCategorie:"New Category",
      createNewCat:"Create New Category",
      createCat:"Create Category",
      questionAvailable:"Question Available",
      typeQuestion:"Type of questions ",
      retour:"Return",
      downloadTemplate:"Download Template",
      importQuestion:"Import questions",
      deleteQuestion:"Delete all questions",
      listQuestion:"List Of Questions",
      selectCandidate:'Select Candidate',
      selectJobRole:'Select JobRole',
      details:"Details",
      delete:"Delete",
      newTest:"New Test",
      nbrQuestion:"Number of questions in each category",
      nbrQuestionS:"Number of questions in each Skill",
      nbCat:"NB: Maximum number of questions per category :",
      nbSkill:"NB: Maximum number of questions per Skill :",
      nbrQuestiontot:"Number of questions ",
      submit:"Submit",
      expectedOutPut:"Expected Output",
      input:"Input",
      squelette:"skeleton",
      codeExten:"Code Extension",
      NewTopic:"New Topic",
      skillAvailable:"Skills available",
      newSkill:"New Skill",
      createNewSkill:"Create New Skill",
      msgImport:"If you have columns with images, make sure to define 'img' as the value in the Excel file for those columns. This will allow the images to be added after the import.",
      passTest:"Pass a Test",
      questionTable:"Question Table",
      choix:"Choices",
      selectedAnswer:" Selected Answer",
      createSkill:" Create Skill",
      skill:"Skill",
      NewTest:"Create a New Test!",
      TestName:"Test Name",
      TestDescription:"Test Description",
      PostNow:"Post Now!",
      TestList:"Test List",
      AddNewTest:"Add New Test",
      UpdateQuestion:"Update Question",
      Add:"Add",
      Discard:"Discard",
      SelectCandidate:"Select a candidate",
      Selectedcandidate:"Selected Candidate:",
      TestResult:"Test Result:",
      Pleaseselectcandidate:"Please select candidate",
      UpdateJob:"Update Job!",
      JobTitle:"Job Title",
      JobDescription:"Job Description",
      Specialisms:"Specialisms",
      CloseDate:"Close Date",
      JobType:"Job Type",
      OfferedSalary:"Salary",
      Experience:"Experience",
      Country:"Country",
      Update:"Update",
      DeleteAll:"Delete All",
      Coefficient:"Coefficient",
      personalityTestRes:" Personaliy Test Result",
      newPerTest:"New Personality Test",
      listRef:"Refrences",
      listCat:"List of categories",
      libraryPsychoMCQQuestions:"Library of Psychotechnical Questions MCQ",
      libraryTechMCQQuestions:"Library of Technical Questions MCQ",
      jobroleList:"Job Role List",
      testPsychoTechniqueList:"List of psychotechnical tests",
      listTopic:"List of Topics",
      listSkill:"List of Skills",
      testTechniqueList:"List of assessments",
      testTechniqueMCQList:"List of technical MCQ tests",
      description:"Description",
      mySettings:"My Profile",
      UploadCover:"Upload Cover",
      UploadAvatar:"Upload Avatar",
      Profile:"Profile",
      FirstName:"First Name",
      LastName:"Last Name",
      Email:"Email",
      Password:"Password",
      Jobtitle:"Job title",
      Phonenumber:"Phone number",
      Language:"Language",
      Emailsignature:"Email signature",
      SaveChanges:"Save Changes",
      Choose:"Choose...",
      AdminDashboard: "Admin dashboard",
      Users:"Users",
      Synchronize:"Synchronize users",
      AvatarUpdate:"Avatar uploaded successfully!",
      AvatarUpdateError:"An error occurred while uploading the avatar!",
      CoverUpdate:"Cover uploaded successfully!",
      CoverUpdateError:"An error occurred while uploading the cover!",
      ProfileUpdate:"Your profile have been updated successfully!",
      ProfileUpdateError:"There is an error occured!",
      SynchronizeUsers:"Users synchronized successfully!",
      SearchT:"Search : ",
      DeleteUserSuccess:"User deleted successfully!",
      DeleteUserError:"An error occurred while deleting the user!",
      firstName: "First name",
      lastName: "Last name",
      EmailVerification: "Email verification",
      EmailSignature: "Email signature",
      MyJobtitle: "Job title",
      PhoneNumber: "Phone number",
      Disabled: "Disabled",
      Company: "Company",
      Role: "Role",
      lastSignInTime: "Last sign in time",
      creationTime: "Creation time",
      All:"All",
      RolesManagment:"Roles managment",
      PersonalitymsgImport:"Please follow the template downloaded and change the current questions by your questions. You can change Traits, Evaluation notes and Options.",
      clickdownloadTemplate: "Click to download the template",
      closeExcelFile:"Pls make sure your excel file is closed when you want to upload it.",
      AddRole:"Add Role",
      Matrix:"Matrix",
      fillName:"Please fill name!",
      addRole:"Role Successfully Added!",
      goodJob:"Good job!",
      addRoleError:"An error occurred while adding the role!",
      roleExists:"This role name already exists",
      DeleteRoleSuccess:"Role deleted successfully!",
      DeleteRoleError:"An error occurred while deleting the role!",
      EditRole:"Edit role",
      permissions:"Permissions",
      Viewjobslist:"View jobs list",
      Addajob:"Add a job",
      Editajob:"Edit a job",
      Deleteajob:"Delete a job",
      msgSel:"Choose a candidate and a jobrole, then click on the button.",
      msgRes:"Waiting for radar data...",
      value:"Value",
      selJobCandidat:"Select a candidate and a jobrole :",
      JobProfileTitle:"Job Role Title",
      sendPersonalityTest:" Send Test",
      EmailAddress:"Email Address",
      NeededQualifications:"Required Qualifications",
      TypeOfQualification:"Type Of Qualification",
      JobProfile:"Job Profile",
      RequiredQualifications:"Required Qualifications",
      Responsibility:"Description",
      AddProfiles:"Add Profile",
      RelatedSkills:"Related Skills(coefficient)",
      JobRoleName:"Job Role Name",
      PickRole:"Please pick a role",
      Solo:"Solo",
      Candidate:"Candidate",
      Recruiter:"Recruiter",
      ThankxForRegistration:"Thank you for registering on our Application!",
      ThankxForRegistrationBody:"Your registration has been received and is currently being reviewed by our administrators for validation. This process may take some time. You will receive an email notification once your account is approved.",
      ThankxForRegistrationBody1:"If you have any urgent inquiries or need further assistance, please feel free to contact our support team at",
      ThankxForRegistrationBody2:"We appreciate your patience and look forward to having you as a part of our community.",
      Comments:"Description of the company",
      numberOfUsers:"Number of Users",
      companyNamereq:"Company Name is required",
      minnumberOfUsers:"The minimum number of users is 1.",
      DurationQCMReq:"Duration Question QCM is required",
      DurationCodingReq:"Problem-solving time is needed",
      Pendingusers:"Pending users",
      Rejectedusers:"Rejected users",
      RejectedusersSuccess:"User rejected successfully!",
      RejectedusersError:"An error occurred while rejecting the user!",
      AcceptedusersSuccess:"User accepted successfully!",
      AccepedusersError:"An error occurred while accepting the user!",
      CompanyDashboard:"Company dashboard",
      founder:"Founder",
      DeleteCompanySuccess:"Company deleted successfully!",
      DeleteCompanyError:"An error occurred while deleting the company!",
      Createdusers:"Created users",
      tests:"Add Tests",
      UpdateCoefficients:"Update Coefficients",
      TestsTypes:"Tests Types",
      PersonalityTests:"Personality Tests",
      JobProfileList:"Job Profile List",
      NoNotifications:"No notifications",
      newUser:"New user ",
      PendengApproval:" is pending approval",
      CompanyUpdate:"Your company have been updated successfully!",
      CompanyExists:"This company name already exists",
      AddUser: "Add a user",
      firstNameRequired:"First name is required",
      lastNameRequired:"Last name is required",
      emailRequired:"Email is required",
      PasswordRequired:"Password is required",
      RoleRequired:"Password is required",
      InvalidEmailFormat:"Invalid email format!",
      passWordComplexityReq:"Password does not meet the complexity requirements!",
      isPasswordLengthValid:"At least 8 characters long",
      isPasswordContainsUppercase:"At least one uppercase letter",
      isPasswordContainsLowercase:"At least one lowercase letter",
      isPasswordContainsDigit:"At least one digit",
      isPasswordContainsSpecialChar:"At least one special character",
      emailExists:"This email already exists!!",
      userAddSucc:"User added successfully!",
      linkedTests:"Linked Tests",
      AddNeededQualification:"Add Qualification",
      YourPsychoTechnicaltests:"Your Psycho-Technical tests",
      YourTechnicaltests:"Your Technical tests",
      testResult:"The result of  ",
      restReady:" Technical test is ready",
      testFResult:"The result of  ",
      restFReady:" Tests is ready",
      testPResult:"The result of  ",
      restPReady:" Psycho-technical test is ready",
      RequiredQualifications2:"Required Qualifications(Coeff)",
      CompShoudlBeEmpty:"The company cannot be deleted as it contains users.",
      DeleteUserWithCompanyError:"The user cannot be deleted if they have a company. Delete Company first",
      timePerQuestion:"Time Per Question",
      cvUpload:"CVs are currently being uploaded",
      msgpt1:"This field contains Preselection Thresholds 1, which represents a percentage of the preselection score.\n If the candidate's score is higher than this percentage, he/she will automatically be preselected.",
      msgpt2:"This field corresponds to preselection threshold 2, representing a percentage of the score. If a candidate's score lies between Pre-Selection Threshold 1 and Pre-Selection Threshold 2, he or she will be put on hold. On the other hand, if the score is below this percentage, the candidate will be automatically rejected.",
      msgOc:"The Occurrence Threshold determines the maximum frequency allowed for a skill, and if this frequency is exceeded, the skill will be set to the default maximum allowed value.",
      msgDays:"Indicate the number of days you wish to keep candidates' CVs after they have been added. This allows you to manage the retention period of resumes on our platform.",
      SkillSelected:"Skills for Selected Job :",
      step0:"Before you start :",
      step1: "Select the Preselection Thresholds 1 and Preselection Thresholds 2 options (PT1 > PT2).",
      step2: "Choose the corresponding job role.",
      step3: 'Click on "Select Files" to choose the files for extraction.',
      step4: 'Once the files are selected, click on "Upload files".',
      step5: 'After following these steps, you can click on "Extract" to start extracting CVs based on your criteria.',
      guide:"User guide",
      UploadFilesButton:"Upload files",
      ExtractButton:"Extract",
      chooseFile:"Choose Files To Extract ",
      NoCVs:"No CVs selected",
      FileSeleted:"File(s) selected :",
      Files:"File(s)",
      jobAssessment:"Job Assessment",
      noJobRolesForCandidate:"No job roles are available for this candidate",
      noCandidate:"No candidates are available",
      lastActivity:"Last Activity",
      codTest:"Problem-solving test",
      note:"Score : ",
      pendingUser:"Invited to tests",
      passUser:"Tests Passed ",
      appliedUser:"Application Submitted",
      candPerApp:" Candidates Per Application List",
      viewCandidates:"View candidates",
      viewPsycho:"View PsychoTechnical Test",
      viewTech:"View Technical Test",
      viewCoding:"View Coding Test",
      viewTechQCM:"View Technical QCM Test",
      Prequalified:"Pre-selection score",
      semaine:"Week",
      jour:"Day",
      mois:"Month",
      noJobs:"No jobs currently available.",
      userLogs:"User Logs",
      nbreDays:"Number of days ",
      hiringStage:"Hiring stage",
      rating:"Overal rating ",
      assessment:"Assessment",
      edit:"Edit",
      psychoResult:"Pschotechnical test results",
      techResult:"Technical test results",
      ratingRec:"Based on your impressions and interactions with this candidate, give your personal overall rating.",
      urrating:"Your rating",
      anticheetingMon:"Anti-cheating monitor",
      deviceused:"Device used",
      location:"Location",
      ipAdress:"Filled out only once from IP address?",
      webcamEnable:"Webcam enabled?",
      fullScreenEnable:"Full-screen mode always active?",
      mouseEnable:"Mouse always in assessment window?",
      NewTechnicalTest:"New Technical Test",
      Newassessment:"New assessment",

      SelectTypeOfTest: "Select type of test",
      QCM:"MCQ",
      CreateTest: "Create test",
      numberOfScreenShots: "Number Of ScreenShots",
      numberOfSnapShots: "Number Of SnapShots",
      skillsForCandidates:"Skills most available for candidates",
      skillsForJobs:"Skills most available for Jobs",

      testType: "Test Type",

      technicalQCM: "Technical MCQ Library",
      CreatePersonalityTest: "Create a New Personality Test",
      CreateNewJobRole: "Create New Job Role",
      UpdateJobProfile: "Update Job Profile",
      UpdateJobRole: "Update Job Role",
      CreateJobProfile: "Create New Job Profile",
      CreateNewTopic: "Create New Topic",
      UpdateTopic: "Update Topic",
      PsychoTechnicalQuestionTable: "Psycho-Technical Questions",
      QCMTechnical: "Technical MCQ",
      TestPerRelatedToJob:"This test is associated with a job profile and cannot be deleted.",
      testTechniqueMCQ:"MCQ technical test",
      updateError:'The PsychoTechnical test is already linked to a candidate.',
      serachJob:"Search by Title",
      serachQuestion:"Search by Question",

      listJob:"List of jobs",
      showTest:"Show test",
      level: "Level",
      actions: "Actions",
      Remove: "Remove",

      selectLevel: "Select level of test",
      PersonalityTest:"Personality Test",
      questionPerCat:"Question per Category",
      overallQuestion:"No. Overall Question",
      seconds:"Seconds",
      testPassed:"Test Passed",
      coding:"Coding",
      questionPerSkill:"Question per Skill",
      noQCMavailable:"No MCQ tests available",
      timer:"Timer",
      noCodingavailable:"No Coding tests available",
      previousInter:"Previous Challenge",
      nextInter:"Next Challenge",
      accessDenied:"Access Denied",
      YoudontHavePerm:"  You don't have permission to view this page.",
      codingRes:"Coding test results",
      OffreClosest:"Discover Our Nearest Offer",
      codingDuration:"Coding test duration (seconds)",
      QCMduration:"MCQ test duration (seconds)",
      findPlan:"Find Plan",
      purchaseNow:"Subscribe Now",
      subSelection:"Subscription Selection",
      companyInfo:"Company Information",
      InvoicePaid:"Proceed to the next step as you have already paid your invoice.",
      scoringMeth:"Scoring method",
      Percentile:"Percentile, All candidates",
      Testscores:"TESTS INCLUDED IN SCORING",
      notyet:"This Candidate has not started their assessment yet.",
      noTestRes:"Therefore there are no test results to show",
      accessdisabled:"We don't have access to the candidate location.",
      resendTest:"Resend Test",
      PreselectedOffers:"Preselected Offers",
      CompletedTestOffers:"Completed Test Offers",
      PendingTestOffers:"Pending Test Offers",
      overallscore:'Overall Score',
      techScore:'Technical Score',
      ptechScore:'Psycho-Technical Score',
      noScorePre:"No pre-selection score",
      Reasoning:"Reasoning",
      Excellent:"Excellent",
      VeryGood:"Very Good",
      Good:"Good",
      Insufficient:"Poor",
      Undefinedscore:"Very poor",
      verypoordesc:"The candidate failed to answer the majority of the questions correctly. This suggests that they may not have the necessary skills or knowledge to be successful in this role.",
      poordesc:"The candidate answered 20% of the questions or exercises on the test correctly. They have insufficient experience and technical skills.",
      gooddesc:"The candidate answered 60% of the questions or exercises on the test correctly. They have developing experience and technical skills.",
      verygooddesc:" The candidate answered 80% of the questions or exercises on the test correctly. They have strong experience and technical skills.",
      excellentdesc:"The candidate answered all of the questions or exercises on the test correctly. They have very high experience and technical skills",
      Beginner:"Beginner",
      Intermediate:"Intermediate",
      Advanced:"Advanced",
      verypoordesc1:"The candidate failed to answer the majority of the questions correctly. This suggests that they may not have the necessary skills or knowledge to be successful in this role.",
      poordesc1: "The candidate does not have the required skills for the position. They have no experience or skills in the field of the position.",
      gooddesc1:"The candidate has the required skills for the position at a developing level. They have experience and skills in the field of the position but may need additional training or development to be fully qualified.",
      verygooddesc1:"The candidate has the required skills for the position at a high level. They have experience and skills in the field of the position.",
      excellentdesc1:"The candidate has the required skills for the position at a very high level. They have strong experience and skills in the field of the position.",
      suppImpo:"This test cannot be deleted because it has been used",
      cancledApp:"Your application has been successfully cancelled. Thank you.",
      cancledAppFailed:"There was an issue cancelling your application. Please try again later or contact support.",

      confirmCancelTitle: "Are you sure?",
      confirmCancelText: "Do you really want to cancel your application? This process cannot be undone.",
      confirmButton: "Yes, cancel it!",
      cancelButton: "No, keep it",
      jobDeleteError:"Job cannot be deleted as it is in use",
      jobDeleteted:"Job has been successfully deleted",
      jobArchivedSucc:"Job has been successfully archived",
      CreateNewAssignment: "Create New Assignment",
      UpdateAssignment: "Update Assignment",
      AssignmentName: "Assignment Name",
      Repository: "Repository",
      Difficulty: "Level of Difficulty",
      AddAssignments: "Add Assignments",
      AssignmentList: "Assignment List",
      Assignments: "Assignments",
      Assignment : "Assignment",
      AssignmentResult: "Assignment Result",
      assignmentDuration : "Duration of the Assignment",


      pfydbs:"Please fill your data before submitting.",
      pcydbs:"Please check your data before submitting.",


      aeowsyiptl:"An error occurred while submitting your informations, please try again!",
      hukym:"Help us know you more",
      nfl:"Note for later",
      aysywtl:"Are you sure you want to logout ?",

      sownsyp:"Skipping onboarding will not save your progress.",
      tyfys:"Thank you  for your submission!",
      Skip:"Skip",
      Confirm:"Confirm",
      Cancel:"Cancel",
      aoe:"Area of Expertise",
      pp:"Preferred Posts",
      wkoodyltsotp:"What kind of offers do you like to see on the platform?",
      stpyp:"Select the posts you prefer:",
      ConP:"Contract Preferences",
      wctyp:"Which Contract Types you prefer ?",
      wayse:"What is your Salary Expectation ($/Year) ?",
      eyse:"Enter your salary expectation",
      Education:"Education",
      EdIn:"Educational Institution",
      sac:"Select a Country",
      Degree:"Degree",
      Started:"Started",
      Ended:"Ended",
      ComN:"Company Name",
      ComL:"Company location",
      ypd:"Your post description",
      fins:"format is not supported.",
      ssblt:"Size should be less than 500 KB",
      wadwalotrnptal:"We are dealing with alot of trafic right now. Plrase try again later.",
      suibtrptal:"Something unexpected is blocking the request. Please try again later.",
      ftufpta:"Failed to upload file, please try again.",
      nfs:"No file selected",
      dyfho:"Drop your file here or",
      Browse:"Browse",
      opfas:"Only PDF files are supported",
      eedptal:"Error extracting data. Please try again later.",
      ydhbes:"Your data has been extracted successfully!",
      pribys:"Please recheck it before you submit.",
      peavlu:"Please enter a valid LinkedIn URL.",
      peavea:"Please enter a valid email address.",
      peavgu:"Please enter a valid GitHub URL.",
      peavpnftsc:"Please enter a valid phone number for the selected country.",
      pn:"Phone Number",
      tfir:"This field is required",
      GitHub:"GitHub",
      LinkedIn:"LinkedIn",
      online:"Online",
      onsite:"On site",
      hybrid:"Hybrid",
      Experiences:"Experiences",
      jp:"Job Preferences",
      wiypaf:"What is your preferred attendance formula ?",
      pjl:"Preferred Job Location",
      ayalfaj:"Are you actively looking for a job ?",
      ayia:"Are you immediately available ?",
      Yes:"Yes",
      No:"No",
      
      Mastery:"Mastery",
      SoftSkills:"Soft Skills",
      sal:"Select a language",
      sl:"Selected Languages",
      X:"X",
      Junior:"Junior",
      Senior:"Senior",
      Mid:"Mid",
      ts:"Technical Skills",
      sas:"Select a skill",
      ss:"Selected Skills",
      Volunteerings:"Volunteerings",
      Organization:"Organization",
      td:"Task Description",
      generalInfo:"General informations",
      AssignmentScore: "Assignment Score",

      Preferences:"Preferences",

      scoreExcellentdDesc: "The candidate has the required skills for the position at a very high level. They have strong experience and skills in the field of the position.",
      scoreVeryGoodDesc: "The candidate has the required skills for the position at a high level. They have experience and skills in the field of the position.",
      scoreGoodDesc: "The candidate has the required skills for the position at a developing level. They have experience and skills in the field of the position but may need additional training or development to be fully qualified.",
      scoreInsufficientDesc: "The candidate does not have the required skills for the position. They have no experience or skills in the field of the position.",
      Undefined:"Undefined",
      selectAssignment: "Select An assignment.",
      noAssignment: "There is no assignment in this job role",
      selectCandJob: "Please select a candidate and a job role.",
      subDetails:"Subscription Details",
      usagePercentage: 'Usage Percentage',
      quotaUsed: 'Quota used',
      quotaMax: 'Quota max',
      acceptedCandidates: 'Accepted candidates',
      profileViewed: 'Profile Viewed',
      applicationSent: 'Application Sent',
      assessmentsSchedules: 'Assessments Schedules',
      cancel: 'Cancel',
      availableJobsForYou: 'Available Jobs For You',
      updateProfile: 'Update Profile',
      quotaExtraction: 'Quota Extraction',
      quotaJobProfile: 'Quota Job Profile',
      quotaParcoursJobTest: 'Quota Parcours job Test',
      quotaProfiles: 'Quota Profiles',
      quotaJobRole: 'Quota Job Role',

      supprimerError:"Unable to delete the item. Please try again later or contact support if the issue persists.",
      runTopic:"Run the code",
      exemple:"Examples of Input and Expected Output",

      selectSkillLevelCreation: "Please select the difficulty level of the skill.",
      AccessDenied: "Access Denied",
      AccessDeniedComment: "This user has already passed this test.",
      TestPolicy: "Test Policy",
      TestPolicyComment: "To evaluate the problem-solving skills, coding proficiency, and logical thinking of candidates through structured coding tests.",
      inputValue:"Input Value",
      testNumber:"Test Number",
      testCase:" Test Cases",
      Case:"Case",
      ExpectedValue:"Valeur attendue",
      run:"Run",
      processing:"Processing...",
      memory:"Memory",
      time:"Time",
      estimatedComp:"Estimated Complexity",
      Output:"Output",
      deleteText:"Once deleted, you won't be able to recover this Test!",
      deleteTitle:"Are you sure?",
      deleteTitleWrong:"Oops!",
      deleteWrong:"Something went wrong!",
      deleteSuccess:"Skill was deleted successfully!!",
      Both:"MCQ and Coding",
      loading:"Loading in progress...",
      testNoPassed: "The tests have not yet been passed",
      viewTestResult: "View test result",
      viewScreenCode:"View Screen Code",
      archived:"Archived",
      deleteTextAssignment:"Once deleted, you won't be able to recover this assignment!",
      deleteSuccessAssignment:"Assignment has been deleted!",
      deletedCnacledAssignment:"Assignment deletion canceled!",
      fullTime:"Full Time",
      partTime:"Part Time",
      FreeLance:"FreeLance",
      Applied:"Applied",
      notApplied:"Apply For Now Job",
      AllowCopyPaste: "Allow Copy Paste",
      takeSnapshots: "Take pictures",
      takeScreenshots: "Take Screenshots",
      coefCoding: "Coding coefficient",
      coefQCM: "MCQ coefficient",
      infoScore: "This score is used in the CV extraction process.",
      infoQualification:"  You can specify and add skills, which you can then select from the list of Needed qualifications.",
      
      
      inviteSuccess:'Invitation created successfully!',
      error:'Error',
      errorEmailText:'Failed to create invitation.',
      errorCreatCanForEmail:'Error creating invitation. Please try again later.',
      jobSkillERROR:'Error fetching job skills. Please try again later.',
      errorTempoUser:'Failed to create temporary user.',
      errorTempoUserText:'Error creating temporary user. Please try again later.',
      errorCheckingUserExisting:'Error checking user existence. Please try again later.',
      errorSendingEmail:'Error sending email. Please try again later.',
      Subject:"Subject",
      SendEmail:"Send Email",
      Accept:"Accept",
      Reject:"Reject",
      NoCandidate:"No candidates to show",
      CVPDFMessage:"The CV has been deleted after one week of being saved in our system.",
      exportToExcel:"Export to Excel",
      viewMore:"View more details",
      rejectMess:"Rejection processed successfully",
      AcceptMess:"Accept successfully processed",
      errorRejection:"Error processing rejection:",
    errorPreselection:"Error processing preselection:",
    errorUpdateStatus:"Error Updating status:",
      warningSelection: 'Please select a candidate and a jobrole before submitting.',
      errorRecuperation: 'Error retrieving data',
      rememberme:"Remember me",
      forgetPassword:"Forgot Password?",
      dontHaveAccount:"Don't have an account",
      Signup:"Sign up",
      SignIn:"Sign In",
      signAndJoin:"Sign Up & Join Us!",
      Caracteres8:"At least 8 characters long",
      upperCases:"At least one uppercase letter",
      lowerCases:"At least one lowercase letter",
      digital:"At least one digit",
      specialCaracteres:"At least one special character",
      Signmeup:"Sign me up",
      haveAccount:"Already have an account?",
      iJobProfile:"You can specify and add skills, which you can then select from the list of Needed qualifications.",
      expQCMDuration:"By default, this time is dedicated to each question of a MCQ test in your company. You can change this value later via your company dashboard.",
      expCodingDuration:"This time is dedicated to the Coding Game in your company by default. You can change it later via your company dashboard.",
      tempPerDefault:"Default time per question is ",
      enterF:"Enter the",
      enterM:"Enter the",

      DeleteAssignment: "Delete Assignment",
      viewAssignmentResult: "View Assignment Result",
      viewAssignment: "View Assignment",

      AssignmentCompressedFile: "Enable Candidate Upload Assignment via Compressed File",

      questionNbreCategoryExceeded: "Please reduce the number of questions",

      Alerts:"Alerts",
      showAll:"Show All",
      viewDetails:"View Details",
      AlertsNotAvailable:"No alerts available",
      swalAddQualification1:"You can use it, but if you need just",
      swalAddQualification2:" click OK to add it or Discard to cancel.",
      exist:"It already exists",
      updateMsgSucc:"Your Job Profile has been updated!",
      deleteMsgProfileJob:"Please delete the job role-related information first since this profile is in use!",

      AddCoefficients:"Adding coefficients",
      difficultyError:"Please select a difficulty level.",
      durationError:"Please reduce the duartion.",
      urlError:"Please enter a correct URL",
      assignmentSuccess:"Your assignment has been posted!",
      repoMsg: "The repository link must be a valid HTTPS link for the candidate to access this assignment",
      dureeMsg: "The duration of the assignment must be less than 20 days",
      createjobPMsgSucc:"Your Job Profile has been created!",
      loadingProfilejob:"Loading or no data available",
      plzCoef:"Please enter the coefficient",
      selectJob: "Please select a job role.",
      matchedSkills:"Matched Skills",
      notfoundTitle:"404 - Page Not Found",
      notfoundMsg:" Sorry, the page you are looking for does not exist.",
      backNotfound:"Go back to the homepage",
      updateMsgSuccSkill:"Your Skill has been updated!",
      duplicateNameError:"Duplicate name error",


      questionPerUpdated: "Poof! Your question has been updated!",

      Feature: "Feature",

      deleteSuccessCategory:"Category was deleted successfully!!",
      categoryDeleteCancel: "Category deletion cancelled!",
      deleteTextCategory: "Once deleted, you won't be able to recover this Category!",
      invalidEmail:'Invalid Email',
      invalidEmailMSG:'The provided email address is not valid.',
      updateMsgSucccat:"Your Category has been updated!",

      TestPerWIthAI:"Test result with AI" ,
      TestPerWIthNotAI:"Test result without AI",
      nbQuestionPerSkill:"NO. of questions per skill",
      requiredFields:"Please fill in all required fields.",
      testDeletMsgCancel:"Test deletion canceled!",
      deleteSuccessTest:"Test was deleted successfully!!",
      jobRoleMsgPostSuccess:"Your Job has been posted!",
      jobRoleMsgUpdateSuccess:"Your Job has been updated!",
      profilTitle:"Profile Title",
      profilDESC:"Profile Description",
      perTestdelete:"Delete personality test",
      persEdit:"Edit personality test",
      perTestView:"View personality test",
      perTestAdd:"Add personality test",
      Incomplete:"Incomplete",
      incompleteText:"Please answer all the questions before submitting.",
      passJobTest:"Passing recruitment tests",
      emailsentSucc:"Email sent successfully",
      emailsentfailed:'Failed to send email',
      scoreCorrect:'Give a correct score',
      assiUpdatea:"Your assignment has been updated!",
      testCreateSucc:"Test submitted successfully",
      testCreateFailed:'Failed to submit the test',
      filesize:`Each file must be less than 2.5 MB.`,
      filesuccess:'Files uploaded successfully!',
      controlTitle:"User Creation Management: ",
      controlCard:"Subscription-Based ",     
      controlAdmin:"Admin Approval",

      amount:"Subscription price",
      Offress: "Discover our offers",
      evaluateCandidate:"Candidate evaluation",
      preselecteOffre:"Pre-Selected Job Opportunities",
      techPer:"Technical Test Permissions",
      intern: "Intern",
      Junior: "Junior",
      y10Yearsormore: "10 years or more",
      y5Yearsormore: "5 years or more",
      y2Yearsormore: "2 years or more",
      totalCorrectAnsw:"Total Correct Answers",
      corrAnsw: "Correct answers",
      aipersoSeetings:"AI result settings for personality tests",
      accesCompTitle:"Access for Company Admin",
      accesCompDesc:"Allow company admins to see candidate personality test results with AI assistance.",
      accesRecTitle:"Access for Recruiter",
      accesRecDesc:"Allow recruiter to see candidate personality test results with AI assistance.",
      Openness:"Openness",
      Conscientiousness:"Conscientiousness", 
      Extraversion:"Extraversion", 
      Agreeableness:"Agreeableness", 
      Neuroticism:"Neuroticism", 
      Openness40: "You prefer routine and traditional ideas to new experiences",
      Openness50: "You have a good balance between tradition and openness to new ideas",
      Openness60: "You are curious, creative and open to new ideas and experiences",
      Conscientiousness40: "You are more flexible and spontaneous, and less strict in your organisation",
      Conscientiousness50: "You have a good balance between organisation and flexibility",
      Conscientiousness60: "You are highly organised, methodical and have a strong sense of duty",

      Extraversion40: "You prefer moments of solitude and enjoy more introspective activities",
      Extraversion50: "You are sociable, but also capable of enjoying moments of tranquillity",
      Extraversion60: "You are sociable, energetic and enjoy being around people",

      Agreeableness40: "You tend to be more direct and competitive in your social interactions.",
      Agreeableness50: "Your behaviour is balanced, with moments of friendliness and criticism.",
      Agreeableness60: "You are generally compassionate and willing to cooperate, avoiding conflict.",

      Neuroticism40: "You are emotionally stable and remain calm in stressful situations",
      Neuroticism50: "Your emotional stability is average, with occasional periods of stress or anxiety.",
      Neuroticism60: "You are more sensitive to the emotions of others.",
      persoType:"Personality Type",
      Weaknesses:"Weaknesses",
      Strengths:"Strengths",
      commentRecruter:"Recruiter feedback",
      todayTime:"Today at",
      like:"Like",
      Repondre:"Reply",
      answersubmited:"Answers Submitted!",
      answersubmitedDesc:"Your answers have been submitted successfully.",
      success:"success",
      presTitle:"Pre-selection application",
      alertJobReqDesc1: "You need to create a job profile before you can create an offer and assign it here. ",
      alertJobReqDesc2: "Click here to create a Job Profile",
      signUpFailed:"Sign Up Failed",
      emailVerif:"Please check your email to activate your account",
      fetchDataFailed:"Failed to fetch user data",
      invalidEmailOrPass:"Invalid Email Or Password",
      userDisabled:"User Disabled",
      tryAgain:"Try Again!",
      roleRegTitle:"Registration Pending Validation",
      PsychoTechnical:"PsychoTechnical",
      Reference:"Reference",
      globalMCQ:"Overall MCQ test score",
      globalCoding:"Overall coding test score",
      updatePermission:"You don´t have the permission to modify this test",
    }
  },
  fr: {
  language: 'Français',
  menu: {
    pageTitle: 'Liste des candidats',
    candidates:"Candidats",
    allApplicants: 'Tous les candidats',
    chooseStatus: 'Choisir un statut',
    accepted: 'Accepté',
    pending: 'En attente',
    refused: 'Refusé',
    jobRole: 'Poste',
    search: 'Rechercher un candidat ...',
    name: 'Nom',
    phone:"Téléphone",
    language:"Langue",
    country:"Pays",
    speciality:"Spécialité",
    skills:"Compétences",
    education:"Education",
    status:"Statut",
    certificats:"Certificats",
    experience:"Experience",
    next:"Suivant",
    previous:"Précédent",
    pt1:"Seuils de présélection 1",
    pt2:"Seuils de présélection 2",
    toc:"Seuil d'occurrence",
    upload : "Téléchargement",
    cvEx:"Les CV sont en cours d'extraction",
    plz:"Veuillez patienter...",
    cvDex:"Extraction des données des CVs",
    totCan:"Total Candidates",
    selCan:"Selected Candidates",
    rejCan:"Rejected Candidates",
    complete:"Complete",
    uploadCv: "Envoyez-nous votre CV",
    errorMess:"Une erreur s'est produite lors du téléchargement du fichier.",
    successMes:"Fichier téléchargé avec succès !",
    Search:"Rechercher ...",
    profile:"Profil",
    inbox:"Boîte de réception",
    dashboard:"Tableau de bord",
    dashbordL:"Tableau de bord Light",
    dashbordD:"Tableau de bord Foncé",
    candidateAssessment:"Evaluation des candidats",
    JobRolEval:"Evaluations",
    jobs:"Emplois",
    Mprofile:"Mon Profil",
    statistics:"Statistiques",
    companies:"Entreprises",
    task:"Tâche",
    candidateList:"Liste des candidats",
    applyJob:"Postuler à un emploi",
    jobList:"Liste d'emplois",
    upTest:"Télécharger le test",
    jobView:"Vue de l'emploi",
    jobApp:"Demande d'emploi",
    perTest:"Personnalité",
    newJob:"Nouvel emploi",
    userProfile:"Profil de l'utilisateur",
    postDetails:"Détails du poste",
    postJob:"Publier un nouvel emploi !",
    jobTitle:"Titre du poste",
    jobDesc:"Description de l'emploi",
    addJob:"Ajouter un nouvel emploi",
    applicant:"Candidat",
    postDate: "Date de création",
    closeDate:"Date de fermeture",
    pagin:"Affichage de 1 à 7 sur 7 entrées",
    overview:"Vue d'ensemble",
    vacancy:"Poste vacant",
    jobType:"Type d'emploi",
    lastDate:"Dernière date de dépôt",
    applyNow:"Postuler maintenant",
    contact:"Contactez nous",
    jobDetails:"Détails de l'emploi",
    role:"Rôle",
    minS:"Salaire minimum",
    maxS:"Salaire maximum",
    jobTags:"Tags de l'emploi",
    jobExp:"Expérience professionnelle",
    locality:"Localisation",
    eligibility:"Éligibilité",
    company:"Entreprise",
    jobID:"Emploi ID",
    postedBy:"Publié par",
    share:"Partager l'emploi",
    print:"Imprimer",
    companyName:"Nom de l'entreprise",
    jobCat:"Catégorie d'emploi",
    choose:"Choisissez...",
    nbrVac:"Nombre d'emplois",
    select:"Sélectionner",
    msgRecommandationSucc:"Demande de recommandation envoyée avec succès.",
    msgRecommandationfailed:"Échec de l'envoi de la demande de recommandationCandidates List",
    fullname:"Nom complet",
    ref:"Nom de la référence proposée",
    mailref:"Email du référent",
    filename:"Nom de fichier",
    comment:"Commentaire",
    comments:"Commentaires",

    statusmanaged:"État géré",
    statusunmanaged:"État non géré",
    welcomeBack: "Bienvenue de retour !",
    userExperience: "Expérience utilisateur et design d'interface",
    strategy: "Solutions SaaS de stratégie",
    signIn: "Connectez-vous à votre compte",
    email: "Email",
    password: "Mot de passe",
    typeEmail: "Saisissez votre adresse e-mail",
    rememberMe: "Se souvenir de moi",
    forgotPassword: "Mot de passe oublié ?",
    signInButton: "Se connecter",
    newAccount: "Vous n'avez pas de compte ?",
    signUp: "S'inscrire",
    Logout: "Se déconnecter",
    Question:"Question",
    BonneReponse:"Bonne réponse",
    imageRef:"Image de Reférence",
    Categorie:"Categorie",
    choix1:"Choix 1",
    choix2:"Choix 2",
    choix3:"Choix 3",
    choix4:"Choix 4",
    questions:"Questions",
    testTechnique : "Evaluation",
    NB:"Le nom de votre CV doit être de la forme suivante Prénom Nom.pdf",
    categorieAvailable:"Les Catégories disponibles",
    newCategorie:"Nouvelle catégorie",
    createNewCat:"Créer une nouvelle catégorie",
    createCat:"Créer une catégorie",
    questionAvailable:"Question disponible",
    typeQuestion:"Type de questions ",
    retour:"Retour",
    downloadTemplate:"Modèle de téléchargement",
    importQuestion:"Importer des questions",
    deleteQuestion:"Supprimer toutes les questions",
    listQuestion:"Liste des questions",
    selectCandidate:'Sélectionner un candidat',
    selectJobRole:'Sélectionner un JobRole',
    details:"Détails",
    delete:"supprimer",
    newTest:"Nouveau test",
    nbrQuestion:"Nombre de questions dans chaque catégorie",
    nbrQuestionS:"Nombre de questions dans chaque Compétence",
    nbCat:"NB : Nombre Maximum de questions par catégorie :",
    nbSkill:"NB : Nombre Maximum de questions par Compétence :",
    nbrQuestiontot : "Nombre de questions",
    submit:"Envoyer",
    expectedOutPut:"Résultats attendus",
    input:"Entrée",
    squelette:"Squelette",
    codeExten:"Extension du code",
    NewTopic:"Nouveau probléme",
    skillAvailable:"Les compétence disponibles",
    newSkill:"Nouvelle compétence",
    createNewSkill:"Créer une nouvelle compétence",
    msgImport:"Si vous avez des colonnes avec des images, assurez-vous de définir 'img' comme valeur dans le fichier Excel pour ces colonnes. Cela permettra d'ajouter les images après l'importation.",
    passTest:"Passer un Test",
    questionTable:"Tableau des questions",
    choix:"Choix",
    selectedAnswer:"  Réponse sélectionnée",
    createSkill:" Créer une compétence",
    skill:"Compétence",
    NewTest:"Nouveau Test!",
    TestName:"Nom de Test",
    TestDescription:"Description du test",
    PostNow:"Postuler!",
    TestList:"Liste des tests",
    AddNewTest:"Ajouter nouveau test",
    UpdateQuestion:"Adjuster la question",
    Add:"confirmer!",
    Discard:"Annuler",
    SelectCandidate:"Sélectionnez un candidat",
    Selectedcandidate:"candidtat selectionné:",
    TestResult:"Résultat du test:",
    Pleaseselectcandidate:"Veuillez sélectionner un candidat",
    UpdateJob:"Modifier l'emploi'",
    JobTitle:"Titre de l'emploi",
    JobDescription:"Description de l'emploi",
    Specialisms:"Spécialités",
    CloseDate:"Date de clôture",
    JobType:"Type d'emploi",
    OfferedSalary:"Salaire offert",
    Experience:"Expérience",
    Country:"Pays",
    Update:"Modifier",
    DeleteAll:"Supprimer Tous",
    Coefficient:"Coéfficient",
    personalityTestRes:" Résultat du test de personnalité",
    newPerTest:"Nouveau test de personnalité",
    listRef:"Références",
    listCat:"Liste des catégories",
    libraryPsychoMCQQuestions:"Bibliothèque de  Questions Psychotechnique QCM",
    libraryTechMCQQuestions:"Bibliothèque de Questions technique QCM",
    jobroleList:"Liste des rôles",
    testPsychoTechniqueList:"Liste des tests Psychotechnique ",
    listTopic:"Liste des problémes",
    listSkill:"Liste des compétences",
    testTechniqueList:"Liste des evaluations",
    testTechniqueMCQList:"Liste des tests Technique QCM",
    description: "Description",
    mySettings: "Mon Profil",
    UploadCover: "Télécharger la couverture",
    UploadAvatar: "Télécharger l'avatar",
    Profile: "Profil",
    FirstName: "Prénom",
    LastName: "Nom de famille",
    Email: "Email",
    Password: "Mot de passe",
    Jobtitle: "Titre du poste",
    Phonenumber: "Numéro de téléphone",
    Language: "Langue",
    Emailsignature: "Signature électronique",
    SaveChanges: "Enregistrer les modifications",
    Choose: "Choisir...",
    AdminDashboard: "Dashboard admin",
    Users:"Utilisateurs",
    Synchronize:"Synchroniser les utilisateurs",
    AvatarUpdate: "Avatar téléchargé avec succès!",
    AvatarUpdateError: "Une erreur s'est produite lors du téléchargement de l'avatar!",
    CoverUpdate: "Couverture téléchargée avec succès!",
    CoverUpdateError: "Une erreur s'est produite lors du téléchargement de la couverture!",
    ProfileUpdate: "Votre profil a été mis à jour avec succès!",
    ProfileUpdateError: "Une erreur s'est produite lors de la mise à jour du profil!",
    SynchronizeUsers: "Synchronisation des utilisateurs effectuée avec succès!",
    SearchT:"Rechercher : ",
    DeleteUserSuccess:"Utilisateur supprimé avec succès!",
    DeleteUserError:"Une erreur s'est produite lors de la suppression de l'utilisateur!",
    firstName: "Prénom",
    lastName: "Nom de famille",
    EmailVerification: "Vérification email",
    EmailSignature: "Signature email",
    MyJobtitle: "Titre du poste",
    PhoneNumber: "Numéro tél",
    Disabled: "Désactivé",
    Company: "Entreprise",
    Role: "Rôle",
    lastSignInTime: "Dernière connexion",
    creationTime: "Date de création",
    All:"Tout",
    RolesManagment:"Gestion des rôles",
    PersonalitymsgImport:"Veuillez suivre le modèle téléchargé et modifier les questions actuelles par vos questions. Vous pouvez modifier les caractéristiques, les notes et les options d'évaluation.",
    clickdownloadTemplate: "Cliquez pour télécharger le modèle",
    closeExcelFile:"Pls make sure your excel file is closed when you want to upload it.",
    AddRole:"Ajouter un rôle",
    Matrix:"Matrice",
    fillName:"Veuillez remplir le nom!",
    addRole:"Rôle ajouté avec succès!",
    goodJob:"Bon travail!",
    addRoleError:"Une erreur s'est produite lors de l'ajout du rôle!",
    roleExists:"Ce nom de rôle existe déjà",
    DeleteRoleSuccess:"Rôle supprimé avec succès!",
    DeleteRoleError:"Une erreur s'est produite lors de la suppression du rôle!",
    EditRole:"Modifier rôle",
    permissions:"Autorisations",
    Viewjobslist: "Voir la liste des emplois",
    Addajob: "Ajouter un emploi",
    Editajob: "Modifier un emploi",
    Deleteajob: "Supprimer un emploi",
    msgSel:"Choisissez un candidat et un jobrole, puis cliquez sur le bouton.",
    msgRes:"Attente des données radar..",
    value:"Valeur",
    selJobCandidat:"Sélectionnez un candidat et un jobrole :",
    JobProfileTitle:"Titre du Profile",
    sendPersonalityTest:" Envoyer un Test",
    EmailAddress:"Adresse e-mail",
    NeededQualifications:"Qualifications Requises",
    TypeOfQualification:"Type De Qualification",
    JobProfile:"Profil d'emploi",
    RequiredQualifications:"Qualifications requises",
    Responsibility:"Description",
    AddProfiles:"Ajouter un profile",
    RelatedSkills:"Compétences connexes(coefficient)",
    JobRoleName:"Nom du rôle du travail",
    PickRole: "Veuillez choisir un rôle",
    Solo:"Indépendant",
    Candidate:"Candidat",
    Recruiter:"Recruteur",
    ThankxForRegistration:"Merci de vous être inscrit sur notre application !",
    ThankxForRegistrationBody:"Votre inscription a été reçue et est actuellement en cours d'examen par nos administrateurs en vue de la validation. Ce processus peut prendre un certain temps.Vous recevrez une notification par e-mail dès que votre compte sera approuvé.",
    ThankxForRegistrationBody1:"Si vous avez des questions urgentes ou avez besoin d'une assistance supplémentaire, n'hésitez pas à contacter notre équipe de support à l'adresse",
    ThankxForRegistrationBody2:"Nous apprécions votre patience et nous sommes impatients de vous accueillir au sein de notre communauté.",
    Comments:"Description de l'entreprise",
    numberOfUsers:"Nombre d'Utilisateurs",
    companyNamereq:"Le nom d'entreprise est requis.",
    minnumberOfUsers:"Le nombre minimum d'utilisateurs est 1.",
    DurationQCMReq:"Durée Question QCM est requis.",
    DurationCodingReq:"Un temps de résolution des problèmes est requis",
    Pendingusers:"Utilisateurs en attente",
    Rejectedusers:"Utilisateurs rejetés",
    RejectedusersSuccess:"Utilisateur rejeté avec succès!",
    RejectedusersError:"Une erreur s'est produite lors de la rejection de l'utilisateur",
    AcceptedusersSuccess:"Utilisateur accepté avec succès!",
    AccepedusersError:"Une erreur s'est produite lors de la acceptation de l'utilisateur",
    CompanyDashboard:"Dashboard entreprise",
    founder:"Fondateur",
    DeleteCompanySuccess:"Entreprise supprimée avec succès!",
    DeleteCompanyError:"Une erreur s'est produite lors de l'entreprise",
    Createdusers:"Utilisateurs créés",
    tests:"Ajouter des tests",
    UpdateCoefficients:"Mettre à jour les coéfficients",
    TestsTypes:"Types de Tests",
    PersonalityTests:"Tests de personnalité",
    JobProfileList:"Liste Des Profiles",
    NoNotifications:"Pas de notifications",
    newUser:"Un nouvel utilisateur ",
    PendengApproval:" est en attente d'approbation.",
    CompanyUpdate:"Votre entreprise a été mis à jour avec succès!",
    CompanyExists:"Ce nom d'entreprise existe déjà",
    AddUser: "Ajouter un utilisateur",
    firstNameRequired: "Le prénom est requis",
    lastNameRequired: "Le nom de famille est requis",
    emailRequired: "L'adresse e-mail est requise",
    PasswordRequired: "Le mot de passe est requis",
    RoleRequired:"Le rôle est requis",
    InvalidEmailFormat:"Format d'e-mail invalide !",
    passWordComplexityReq: "Le mot de passe ne répond pas aux exigences de complexité !",
    isPasswordLengthValid:"Au moins 8 caractères",
    isPasswordContainsUppercase:"Au moins une lettre majuscule",
    isPasswordContainsLowercase:"Au moins une lettre minuscule",
    isPasswordContainsDigit:"Au moins un chiffre",
    isPasswordContainsSpecialChar:"Au moins un caractère spécial",
    emailExists:"Cet email existe déjà!!",
    userAddSucc:"Utilisateur ajouté avec succès!",
    linkedTests:"Tests liés",
    AddNeededQualification:"Ajouter qualification",
    YourPsychoTechnicaltests:"Vos tests Psycho-techniques",
    YourTechnicaltests:"Vos tests Techniques",
    testResult:"Le résultat de Test technique de  ",
    restReady:" est prêt",
    testFResult:"Le résultat des Test de ",
    restFReady:" Tests is ready",
    testPResult:"Le résultat de Test Psycho-technique de  ",
    restPReady:" est prêt",
    RequiredQualifications2:"Qualifications requises(Coéff)",
    CompShoudlBeEmpty:"L'entreprise ne peut pas être supprimée car elle contient des utilisateurs.",
    DeleteUserWithCompanyError:"L'utilisateur ne peut pas être supprimé s'il a une entreprise. Supprimez d'abord l'entreprise.",
    timePerQuestion:"Temps par question",
    cvUpload:"Les CV sont en cours de téléchargement",
    msgpt1:"Ce champ contient le seuil de présélection 1 , qui représente un pourcentage du score de présélection.\n Si le score du candidat est supérieur à ce pourcentage, il sera automatiquement présélectionné.",
    msgpt2:"Ce champ correspond au seuil de présélection 2, représentant un pourcentage du score. Si le score d'un candidat se situe entre le seuil de présélection 1 et le seuil de présélection 2, il sera mis en attente. En revanche, si le score est inférieur à ce pourcentage, le candidat sera automatiquement rejeté.",
    msgOc:"Le Seuil d'Occurrence détermine la fréquence maximale autorisée pour une compétence, et si cette fréquence est dépassée, la compétence sera fixée à la valeur maximale autorisée par défaut.",
    msgDays:"Indiquez pendant combien de jours vous souhaitez conserver les CV des candidats après leur ajout. Cela permet de gérer la période de rétention des CV sur notre plateforme.",
    SkillSelected:"Compétences pour le poste sélectionné :",
    step0:"Avant de commencer :",
    step1: "Sélectionnez les options Seuils de présélection 1 et Seuils de présélection 2 (PT1 > PT2).",
    step2: "Choisissez le poste correspondant.",
    step3: 'Cliquez sur "Sélectionner des fichiers" pour choisir les fichiers à extraire.',
    step4: 'Une fois les fichiers sélectionnés, cliquez sur "Télécharger des fichiers".',
    step5: 'Après avoir suivi ces étapes, vous pourrez cliquer sur "Extraire" pour lancer l\'extraction des CVs selon vos critères.',
    guide:"Guide d'utilisation",
    UploadFilesButton:"Télécharger des fichiers",
    ExtractButton:"Extraire",
    chooseFile:"Choisir les fichiers à extraire",
    NoCVs:"Aucun CV sélectionné",
    FileSeleted:"Fichier(s) sélectionné(s) :",
    Files:"Fichier(s)",
    jobAssessment:"Évaluation de poste",
    noJobRolesForCandidate:"Aucun poste n'est disponible pour ce candidat",
    noCandidate:"Aucun candidat n'est disponible",
    lastActivity:"Dernière activité",
    codTest:"Test de résolution de problèmes",
    note:"Score : ",
    pendingUser:"Invités aux tests",
    passUser:"Tests passés",
    appliedUser:"Demande déposée",
    candPerApp:" Candidats par liste d'application",
    viewCandidates : "Voir les candidats",
    viewPsycho : "Voir le test psychotechnique",
    viewTech : "Voir le test technique",
    viewCoding : "Afficher le test de codage",
    viewTechQCM : "Afficher le test technique QCM",
    Prequalified:"Score de présélection",
    semaine : "Semaine",
    jour : "Jour",
    mois:"Mois",
    noJobs:"Aucun emploi disponible pour le moment.",
    userLogs:"Journaux de l'utilisateur",
    nbreDays:"Nombre de jours ",
    hiringStage:"Phase de recrutement",
    rating:"Note globale",
    assessment:"Evaluation",
    edit:"Modifier",
    psychoResult:"Résultats du test Pschotechnique",
    techResult:"Résultats du test Technique",

    ratingRec:"Sur la base de vos impressions et de vos interactions avec ce candidat, donnez votre note globale personnelle.",
    urrating:"Votre évaluation",
    anticheetingMon:"Moniteur anti-triche",
    deviceused:"Appareil utilisé",
    location:"Localisation",
    ipAdress:"Remplie une seule fois à partir de l'adresse IP ?",
    webcamEnable:"Webcam activée ?",
    fullScreenEnable:"Mode plein écran toujours actif ?",
    mouseEnable:"Souris est toujours dans la fenêtre d'évaluation ?",

    NewTechnicalTest:"Nouveau test technique",
    Newassessment:"Nouveau evaluation",

    SelectTypeOfTest: "Choisir le type de test",
    QCM:"QCM",
    CreateTest: "Créer le test",
    numberOfScreenShots: "Nombres des captures écran",
    numberOfSnapShots: "Nombres des SnapShots",
    skillsForCandidates:"Compétences les plus disponibles pour les candidats",
    skillsForJobs:"Compétences les plus disponibles pour les Offres",

    testType: "Type de test",
    technicalQCM: "Bibliothèque de QCM techniques",
    CreatePersonalityTest: "Création un nouveau test de personnalité",
    CreateNewJobRole: "Création d'un nouveau rôle",
    UpdateJobProfile: "Mise à jour du profil d'emploi",
    UpdateJobRole: "Mise à jour du rôle de l'emploi",
    CreateJobProfile: "Création d'un nouveau profil d'emploi",
    testPsychoTechnique: "Test psycho-technique",
    CreateNewTopic: "Création d'un nouveau sujet",
    PsychoTechnicalQuestionTable: "Questions psychotechniques",
    QCMTechnical: "QCM techniques",
    UpdateTopic: "Mise à jour du Sujet",      
    TestPerRelatedToJob:"Ce test est associé à un profil de travail et ne peut pas être supprimé.",

    testTechniqueMCQ:"Test technique QCM",
    updateError : 'Le test psychotechnique est déjà lié à un candidat',
    serachJob:"Recherche par titre",
    serachQuestion:"Recherche par énoncé",

    listJob:"Liste des emplois",
    showTest : "Afficher le test",
    level: "Niveau",
    actions: "Actions",
    Remove: "Supprimer",
    selectLevel: "Sélectionner le niveau de test",
    PersonalityTest:"Test de personnalité",
    questionPerCat : "Question par catégorie",
    overallQuestion : "Nbr. Question globale",
    seconds:"Secondes",
    testPassed : "Test passé",
    coding:"Résolution de problème",
    questionPerSkill : "Question par compétence",

    noQCMavailable : "Aucun test QCM disponible",
    timer:"Minuteur",
    noCodingavailable : "Aucun test Codage disponible",
    previousInter : "Précédent défi",
    nextInter : "Suivant défi",
    accessDenied : "Accès refusé",
    YoudontHavePerm :" Vous n'avez pas l'autorisation de visualiser cette page",
    codingRes:"Résultats des tests de codage",
    OffreClosest:"Découvrez Notre Offre la Plus Proche",
    codingDuration : "Durée du test de codage (secondes)",
    QCMduration : "Durée du test de QCM (secondes)",
    findPlan:"Trouver un offre",
    purchaseNow:"S'abonner Maintenant",
    subSelection:"Choix de l'abonnement",
    companyInfo:"Informations sur l'entreprise",
    InvoicePaid:"Passez à l'étape suivante car vous avez déjà payé votre facture.",
    scoringMeth:"Méthode d'évaluation",
    Percentile:"Pourcentage, tous les candidats",
    Testscores:"TESTS INCLUS DANS LA NOTE",
    notyet : "Ce candidat n'a pas encore commencé son évaluation",
    noTestRes : "Il n'y a donc pas de résultats de tests à montrer",
    accessdisabled : "Nous n'avons pas accès à l'emplacement du candidat",
    resendTest:"Renvoyer le test",
    PreselectedOffers:"Offres présélectionnées",
    CompletedTestOffers:"Offres de test terminées",
    PendingTestOffers:"Offres de test en cours",
    overallscore:'Score global',
    techScore : "Score technique",
    ptechScore : " Score psychotechnique ",
    noScorePre:"Aucun score de présélection",
    Reasoning:"Raisonnement",
    Excellent:"Excellent",
    VeryGood:"Très bien",
    Good:"Bien ",
    Insufficient:"Faible",
    Undefinedscore:"Très faible",
    verypoordesc: "Le candidat n'a pas réussi à répondre correctement à la majorité des questions. Cela suggère qu'il peut ne pas avoir les compétences ou les connaissances nécessaires pour réussir dans ce rôle.",
    poordesc: "Le candidat a répondu correctement à 20% des questions ou exercices du test. Il manque d'expérience et de compétences techniques.",
    gooddesc: "Le candidat a répondu correctement à 60% des questions ou exercices du test. Il a une expérience et des compétences techniques en développement.",
    verygooddesc: "Le candidat a répondu correctement à 80% des questions ou exercices du test. Il a une forte expérience et des compétences techniques.",
    excellentdesc: "Le candidat a répondu correctement à toutes les questions ou exercices du test. Il a une très haute expérience et des compétences techniques.",
    Beginner:"Débutant",
    Intermediate:"Intermédiaire",
    Advanced:"Avancé",
    verypoordesc1: "Le candidat n'a pas réussi à répondre correctement à la majorité des questions. Cela suggère qu'il pourrait ne pas avoir les compétences ou les connaissances nécessaires pour réussir dans ce rôle.",
    poordesc1: "Le candidat ne possède pas les compétences requises pour le poste. Il n'a aucune expérience ou compétence dans le domaine du poste.",
    gooddesc1: "Le candidat possède les compétences requises pour le poste à un niveau en développement. Il a de l'expérience et des compétences dans le domaine du poste mais pourrait avoir besoin d'une formation ou d'un développement supplémentaire pour être pleinement qualifié.",
    verygooddesc1: "Le candidat possède les compétences requises pour le poste à un niveau élevé. Il a de l'expérience et des compétences dans le domaine du poste.",
    excellentdesc1: "Le candidat possède les compétences requises pour le poste à un niveau très élevé. Il a une solide expérience et des compétences dans le domaine du poste.",
    suppImpo:"Impossible de supprimer ce test car il a été utilisé",
    cancledApp:"Votre candidature a été annulée avec succès. Merci .",
    cancledAppFailed:"Un problème est survenu lors de l'annulation de votre candidature. Veuillez réessayer plus tard ou contacter le support.",

    confirmCancelTitle: "Êtes-vous sûr?",
    confirmCancelText: "Voulez-vous vraiment annuler votre candidature? Ce processus ne peut pas être annulé.",
    confirmButton: "Oui, annulez-la!",
    cancelButton: "Non, gardez-la",
    jobDeleteError:"Le poste ne peut pas être supprimé car il est utilisé",
    jobDeleteted:" L'offre a été supprimé avec succès.",
    jobArchivedSucc:"L'offre a été archivé avec succès.",
    CreateNewAssignment: "Création d'une nouvelle examen technique",
      UpdateAssignment: "Mise à jour de examen technique",
      AssignmentName: "Nom de examen technique",
      Repository: "Répertoire",
      Difficulty: "Niveau de Difficulté",
      AddAssignments: "Ajouter des examens techniques",
      AssignmentList: "Liste des examens techniques",
      Assignments: "Examens Techniques",
      Assignment: "Examen Technique",
      AssignmentResult: "Résultat de examen technique",
      assignmentDuration: "Durée de la examen technique",
      oucv:"Ou téléchargez votre CV:",
      nfl:"Noter pour plus tard",
      tyfys:"Merci pour votre soumission!",
      pfydbs:"Veuillez remplir vos données avant de soumettre.",
      pcydbs:"Veuillez vérifier vos données avant de soumettre.",
      aeowsyiptl:"Une erreur s'est produite lors de la soumission de vos informations, veuillez réessayer !",
      sownsyp:"Ignorer l’intégration ne sauvegardera pas votre progression.",
      aysywtl:"Êtes-vous sûr de vouloir vous déconnecter ?",
      Confirm:"Confirmer ",
      Cancel:"Annuler",
      aoe:"Domaine d'expertise",
      pp:"Postes préférés",
      wkoodyltsotp:"Quel type d’offres aimez-vous voir sur la plateforme ?",
      stpyp:"Sélectionnez les publications que vous préférez :",
      ConP:"Préférences de contrat",
      wctyp:"Quels types de contrats préférez-vous ?",
      wayse:"Quelle est votre attente salariale ($/an) ?",
      eyse:"Entrez vos attentes salariales",
      Education:"Éducation",
      EdIn:"Établissement d'enseignement",
      Skip:"Sauter",
      hukym:"Aidez-nous à mieux vous connaître",
      sac:"Choisissez un pays",
      Degree:"Diplôme",
      Started:"Commencé",
      Ended:"Terminé",
      ComN:"Nom de l'entreprise",
      ComL:"Emplacement de l'entreprise",
      ypd:"Description de votre poste",
      fins:": format non pris en charge.",
      ssblt:"La taille doit être inférieure à 500 Ko",
      wadwalotrnptal:"Nous sommes actuellement confrontés à beaucoup de trafic. Veuillez réessayer plus tard.",
      suibtrptal:"Quelque chose d'inattendu bloque la demande. Veuillez réessayer plus tard.",
      ftufpta:"Échec du téléchargement du fichier, veuillez réessayer.",
      nfs:"Aucun fichier sélectionné",
      dyfho:"Déposez votre fichier ici ou",
      Browse:"Parcourir",
      opfas:"Seuls les fichiers PDF sont pris en charge",
      eedptal:"Erreur lors de l'extraction des données. Veuillez réessayer plus tard.",
      ydhbes:"Vos données ont été extraites avec succès !",
      pribys:"Veuillez le revérifier avant de soumettre.",
      peavlu:"Veuillez saisir une URL LinkedIn valide.",
      peavea:"Veuillez saisir une adresse email valide.",
      peavgu:"Veuillez saisir une URL GitHub valide.",
      peavpnftsc:"'Veuillez entrer un numéro de téléphone valide pour le pays sélectionné.'",
      pn:"Numéro de téléphone",
      tfir:"Ce champ est obligatoire",
      LinkedIn:"LinkedIn",
      GitHub:"GitHub",
      online:"En ligne",
      onsite:"Sur place",
      hybrid:"Hybride",
      Experiences:"Expériences",
      jp:"Préférences d'emploi",
      wiypaf:"Quelle est votre formule de participation préférée ?",
      pjl:"Lieu de travail préféré",
      ayalfaj:"Vous recherchez activement un emploi ?",
      ayia:"Etes-vous immédiatement disponible ?",
      Yes:"Oui",
      No:"Non",
      Mastery:"Maîtrise",
      SoftSkills:"Compétences personnelles",
      sal:"Sélectionnez une langue",
      sl:"Langues sélectionnées",
      X:"X",
      Junior:"Junior",
      Senior:"Sénior ",
      Mid:"Milieu ",
      ts:"Compétences techniques",
      sas:"Sélectionnez une compétence",
      ss:"Compétences sélectionnées",
      Volunteerings:"Travail bénévole",
      Organization:"Organization",
      td:"Description de la tâche",
      generalInfo:"Informations générales",
      AssignmentScore: "Score d'examen technique",

      scoreExcellentdDesc: "Le candidat possède les compétences requises pour le poste à un niveau très élevé. Il possède une expérience et des compétences solides dans le domaine du poste.",
      scoreVeryGoodDesc: "Le candidat possède les compétences requises pour le poste à un niveau élevé. Il possède une expérience et des compétences dans le domaine du poste.",
      scoreGoodDesc: "Le candidat possède les compétences requises pour le poste à un niveau de développement. Il a de l'expérience et des compétences dans le domaine du poste mais peut avoir besoin d'une formation ou d'un développement supplémentaire pour être pleinement qualifié.",
      scoreInsufficientDesc: "Le candidat ne possède pas les compétences requises pour le poste. Il n'a pas d'expérience ou de compétences dans le domaine du poste.",
      Undefined:"Non défini",
      selectAssignment: "Sélectionnez un examen technique.",
      noAssignment: "Il n'y a pas du examen technique dans cet offre.",
      selectCandJob: "Veuillez sélectionner un candidat et un offre.",
      subDetails : " Détails de l'abonnement ",
      usagePercentage: "Pourcentage d'utilisation",
      quotaUsed: 'Quota utilisé',
      quotaMax: 'Quota maximal',
      acceptedCandidates: 'Candidats acceptés',
      profileViewed: 'Profil consulté',
      applicationSent: 'Candidature envoyée',
      assessmentsSchedules: "Programmes d'évaluation",
      cancel: 'Annuler',
      availableJobsForYou: "Offres d'emploi disponibles",
      updateProfile: 'Mettre à jour le profil',
      quotaExtraction: 'Extraction de quota',
      quotaJobProfile: 'Profil de poste de quota',
      quotaParcoursJobTest: 'Test de parcours de quota',
      quotaProfiles: 'Profils de quota',
      quotaJobRole: 'Rôle de poste de quota',
      supprimerError:"Impossible de supprimer l'élément. Veuillez réessayer plus tard ou contacter le support si le problème persiste.",
      runTopic:"Exécuter le code",
      exemple:"Exemples d'Entrées et de Résultats Attendus",


      selectSkillLevelCreation: "Veuillez sélectionner le niveau de difficulté de la compétence.",
      AccessDenied: "Accès Interdit",
      AccessDeniedComment: "Cet utilisateur a déjà passé ce test.",
      TestPolicy: "Politique de test",
      TestPolicyComment: "Évaluer les compétences en matière de résolution de problèmes, de codage et de raisonnement logique des candidats au moyen de tests de codage structurés.",

      inputValue:"Valeur d'entrée",
      testNumber:"Numéro du test",
      testCase:" Scénarios de test",
      Case:"Scénario",
      ExpectedValue:"Valeur attendue",
      run:"Exécuter",
      processing:"Traitement...",
      memory : "Mémoire",
      time : "Temps",
      estimatedComp : "Complexité estimée",
      Output:"Résultat",
      deleteText : "Une fois supprimé, vous ne pourrez plus récupérer ce test !",
      deleteTitle : "Êtes-vous sûr ?",
      deleteTitleWrong : "Oups !",
      deleteWrong : "Quelque chose n'a pas fonctionné !",
      deleteSuccess : "Pouf ! La compétence a été supprimée avec succès !",

      Both:"QCM et Résolution de problème",
      loading:"Chargement en cours...",
      testNoPassed:"Les tests ne sont pas encore passés",
      viewTestResult : "Voir le résultat du test",
      viewScreenCode:"Voir le code de l'écran",
      archived : "Archivé",
      deleteTextAssignment : "Une fois supprimé, vous ne pourrez plus récupérer cet examen technique !",
      deleteSuccessAssignment : "Pouf ! L'examen technique a été supprimé !",
      deletedCnacledAssignment : "Suppression d'examen techniques annulée !",
      fullTime : "Temps plein",
      partTime : "Temps partiel",
      FreeLance : "FreeLance",
      Applied : "Appliqué",
      notApplied : "Postuler à un emploi",
      AllowCopyPaste: "Autoriser copier coller",
      takeSnapshots: "Prise images",
      takeScreenshots: "Prise capture d'ecran",
      coefCoding: "Coefficient de codage ",
      coefQCM: "Coefficient QCM",
      infoScore : "Ce score est utilisé dans le processus d'extraction du CV",
      infoQualification :" Vous pouvez spécifier et ajouter des compétences, que vous pouvez ensuite sélectionner dans la liste des qualifications requises.",

      inviteSuccess : 'Invitation créée avec succès!',
      error : "Erreur",
      errorEmailText : "La création de l'invitation a échoué.",
      errorCreatCanForEmail : "Erreur lors de la création de l'invitation. Veuillez réessayer plus tard",
      jobSkillERROR:"Erreur dans la recherche des compétences. Veuillez réessayer plus tard",
      errorTempoUser : "Échec de la création d'un utilisateur temporaire",
      errorTempoUserText:"Erreur lors de la création d'un utilisateur temporaire. Veuillez réessayer plus tard.",
      errorCheckingUserExisting : "Erreur de vérification de l'existence de l'utilisateur. Veuillez réessayer plus tard",
      errorSendingEmail : "Erreur lors de l'envoi d'un courriel. Veuillez réessayer plus tard",
      Subject : "Sujet",
      SendEmail : "Envoyer un email",
      Accept : "Accepter",
      Reject : "Rejeter",
      NoCandidate : "Aucun candidat à présenter",
      CVPDFMessage : "Le CV a été supprimé après une semaine d'enregistrement dans notre système",
      exportToExcel : "Exporter vers Excel",
      viewMore : "Afficher plus de détails",
      rejectMess:"Rejet traité avec succès",
      AcceptMess:"Acceptation traitée avec succès",
      errorRejection : "Erreur de traitement du rejet :",
      errorPreselection : "Erreur dans le traitement de la présélection :",
      errorUpdateStatus : "Erreur de mise à jour du statut :",
      warningSelection:'Veuillez sélectionner un candidat et un jobrole avant de soumettre.',
      errorRecuperation:'Erreur lors de la récupération des données',
      rememberme : "Se souvenir de moi",
      forgetPassword : "Mot de passe oublié?",
      dontHaveAccount : "Je n'ai pas de compte",
      Signup : "S'inscrire",
      SignIn:"Se connecter",
signAndJoin : "Inscrivez-vous et rejoignez-nous !",
      Caracteres8 : "Au moins 8 caractères",
      upperCases : "Au moins une lettre majuscule",
      lowerCases : "Au moins une lettre minuscule",
      digital : "Au moins un chiffre",
      specialCaracteres : "Au moins un caractère spécial",
      Signmeup : "Inscrivez-moi",
      haveAccount : "Vous avez déjà un compte ?",
      iJobProfile : "Vous pouvez spécifier et ajouter des compétences, que vous pouvez ensuite sélectionner dans la liste des qualifications requises",
      expCodingDuration:"Cette durée est dédiée au Coding Game dans votre entreprise par défaut. Vous pouvez la modifier ultérieurement via le tableau de bord de votre entreprise.",
      expQCMDuration:"Cette durée est dédiée à chaque question d'un test QCM dans votre entreprise par défaut. Vous pouvez modifier cette valeur ultérieurement via le tableau de bord de votre entreprise.",
      tempPerDefault:"Le temps par défaut pour chaque question est de ",
      enterF:"Saisir la ",
      enterM:"Saisir le ",


      DeleteAssignment: "Supprimer l'examen technique",
      viewAssignmentResult: "Voir le résultat d'examen technique",
      viewAssignment: "Voir l'examen technique",

      AssignmentCompressedFile: "Permettre au candidat de télécharger l'examen technique via un fichier compressé",

      questionNbreCategoryExceeded:"Veuillez réduire le nombre de questions",
      Alerts:"Alertess",
      showAll : "Afficher tout",
      viewDetails : "Voir les détails",
      AlertsNotAvailable : "Aucune alerte disponible",
      swalAddQualification1:"Vous pouvez l'utiliser mais si vous avez besoin de seulement",
      swalAddQualification2:"  cliquez sur OK pour l'ajouter ou sur Annuler pour annuler.",
      exist:"Il existe déjà",
      updateMsgSucc : "Votre profil a été mis à jour !", 
      deleteMsgProfileJob : "Veuillez d'abord supprimer les informations relatives au poste puisque ce profil est en cours d'utilisation !",
      AddCoefficients:"Ajout de coefficients",
      difficultyError : "Veuillez sélectionner un niveau de difficulté",
      durationError : "Veuillez réduire la durée",
      urlError : "Veuillez saisir une URL correcte",
      assignmentSuccess : "Votre examen technique a été posté !",
      repoMsg:"Le lien du repository doit être un lien HTTPS valide pour que le candidat puisse accéder à cet examen technique.",
      dureeMsg:"L'examen technique doit durer moins de 20 jours.",
      createjobPMsgSucc : "Votre profil professionnel a été créé !",
      loadingProfilejob : "Chargement ou pas de données disponibles",
      plzCoef : "Veuillez saisir le coefficient",
      selectJob : "Veuillez sélectionner un emploi",
      matchedSkills : "Compétences concordantes",
      notfoundTitle : "404 - Page non trouvée",
      notfoundMsg :" Désolé, la page que vous cherchez n'existe pas ",
      backNotfound : "Retourner à la page d'accueil",
      updateMsgSuccSkill : "Votre compétence a été mise à jour !",
      duplicateNameError : "Erreur de doublon ",

      questionPerUpdated: "Pouf ! Votre question a été mise à jour !",
      Feature: "Trait",

      deleteSuccessCategory:"La catégorie a été supprimée avec succès !",
      categoryDeleteCancel: "Suppression de la catégorie annulée !",
      deleteTextCategory: "Une fois supprimé, vous ne pourrez plus récupérer cette catégorie !",
      invalidEmail : "Adresse e-mail non valide",
      invalidEmailMSG : "L'adresse e-mail fournie n'est pas valide",
      updateMsgSucccat : "Votre categorie a été mise à jour !",
      TestPerWIthAI:"Résultat du test avec IA" ,
      TestPerWIthNotAI:"Résultat du test sans IA",
      nbQuestionPerSkill:"NO. de questions par compétence",
      requiredFields : "Veuillez remplir tous les champs obligatoires ",
      testDeletMsgCancel : " Suppression du test annulée !",
      deleteSuccessTest:"Le Test a été supprimé avec succès !",
      jobRoleMsgPostSuccess:"Votre emploi a été publié !",
      jobRoleMsgUpdateSuccess:"Votre emploi a été modifié !",

      profilTitle:"Titre de profile",
      profilDESC:"Description de profile",
      perTestdelete : "Supprimer un test de personnalité ",
      persEdit : "Modifier un test de personnalité",
      perTestView : "Afficher le test de personnalité",
      perTestAdd : "Ajouter un test de personnalité",
      Incomplete : "Incomplet",
      incompleteText : "Veuillez répondre à toutes les questions avant de soumettre votre reponse",
      passJobTest:"Réussir les tests de recrutement",
      emailsentSucc : "Email envoyé avec succès",
      emailsentfailed : "Échec de l'envoi de l'email",
      scoreCorrect : "Donner un score correct",
      assiUpdatea : "Votre examen technique a été mise à jour",
      testCreateSucc : "Test soumis avec succès",
      testCreateFailed : "Échec de la soumission du test",
      filesize:`Chaque fichier doit être inférieur à 2.5 MB.`,
      filesuccess:'Fichiers téléchargés avec succès',
      controlTitle : "Gestion de la création d'utilisateurs : ",
      controlCard : "Abonnement",     
      controlAdmin : "Approbation de l'administrateur",
      amount:"Prix de l'abonnement",
       Offress:"Découvrir Nos Offres ",
       evaluateCandidate:"Évaluation des candidats",
       preselecteOffre : "Offres d'emploi présélectionnées",
       techPer : "Permissions de test technique",
       intern: "Stagiaire",
       Junior: "Junior",
       y10Yearsormore: "10 ans ou plus",
       y5Yearsormore: "5 ans ou plus",
       y2Yearsormore: "2 ans ou plus",
       totalCorrectAnsw : "Total des réponses correctes",
       corrAnsw:"Réponses correctes",
       aipersoSeetings:"Paramètres de résultats de l'IA pour les tests de personnalité",
       accesCompTitle :" Accès pour l'administrateur de l'entreprise ",
       accesCompDesc :" Permettre aux administrateurs de l'entreprise de voir les résultats des tests de personnalité des candidats avec l'aide de l'IA ",
       accesRecTitle :" Accès pour le recruteur ",
       accesRecDesc :" Permettre au recruteur de voir les résultats des tests de personnalité des candidats avec l'aide de l'IA ",
       Openness:"ouverture d'esprit",
       Conscientiousness:"Conscience ", 
       Extraversion:"Extraversion", 
       Agreeableness:"Agréabilité ", 
       Neuroticism:"Neuroticisme",
       Openness40:"Vous préférez la routine et les idées traditionnelles plutôt que les nouvelles expériences.",
       Openness50:"Vous avez un bon équilibre entre tradition et ouverture à de nouvelles idées.",
       Openness60:"Vous êtes curieux, créatif, et ouvert à de nouvelles idées et expériences.",
       Conscientiousness40:"Vous êtes plus flexible et spontané, moins strict dans votre organisation.",
       Conscientiousness50:"Vous avez un bon équilibre entre organisation et flexibilité.",
       Conscientiousness60:"Vous êtes très organisé, méthodique et avez un fort sens du devoir.",
 
       Extraversion40:"Vous préférez des moments de solitude et appréciez des activités plus introspectives.",
       Extraversion50:"Vous êtes sociable, mais aussi capable d'apprécier des moments de tranquillité.",
       Extraversion60:"Vous êtes sociable, énergique et aimez être entouré de gens.",
 
       Agreeableness40:"Vous avez tendance à être plus direct et compétitif dans vos interactions sociales.",
       Agreeableness50:"Votre comportement est équilibré, avec des moments d'amabilité et de critique.",
       Agreeableness60:"Vous êtes généralement compatissant et prêt à coopérer, en évitant les conflits.",
 
       Neuroticism40:"Vous êtes émotionnellement stable et gardez votre calme dans des situations stressantes.",
       Neuroticism50:"Votre stabilité émotionnelle est moyenne, avec des périodes occasionnelles de stress ou d'anxiété.",
       Neuroticism60:"Vous êtes plus sensible aux émotions négatives comme le stress et l'anxiété.",
       persoType : " Type de personnalité ",
       Weaknesses : " Faiblesses ",
       Strengths : " Forces ",
       commentRecruter:"Commentaires des Recruteurs",
      todayTime : "Aujourd'hui à ",
       like:"J'aime",
       Repondre:"Répondre",
       answersubmited : " Réponses soumises ! ",
      answersubmitedDesc : " Vos réponses ont été soumises avec succès. ",
      success : " succès ",
      presTitle:"Application de présélection",
      alertJobReqDesc1:" Vous devez créer un profil d'emploi avant de pouvoir créer un offre et l'assigner ici. ",
      alertJobReqDesc2:"Cliquez ici pour créer un Profil d'emploi.",
      signUpFailed : " Échec de l'inscription ",
      emailVerif : " Veuillez vérifier votre email pour activer votre compte ",
      fetchDataFailed : " Échec de la récupération des données de l'utilisateur ",
      invalidEmailOrPass : " Email ou mot de passe non valide ",
      userDisabled : " Utilisateur désactivé ",
      tryAgain : "Réessayez ! ",
      roleRegTitle : "Enregistrement en attente de validation ",
      PsychoTechnical:"Psycho-technique",
      Reference:"Référence",
      globalMCQ:"Score global du test QCM",
    globalCoding:"Score global du test de codage",
    updatePermission:"Vous n'avez pas le droit de modifier ce test",

    }
  }
};
export default translations;
